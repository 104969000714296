/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ReactComponent as ChannelsIcon } from 'assets/icons/icon-box-filter-channels.svg';
import { ReactComponent as ChannelsIconPrimary } from 'assets/icons/icon-box-filter-channels-primary.svg';
import { ReactComponent as CaretDownIcon } from 'assets/icons/caret-down-grey.svg';
import { ReactComponent as CaretDownIconPrimary } from 'assets/icons/caret-down-primary.svg';
import { ReactComponent as CaretUpIconPrimary } from 'assets/icons/caret-up-primary.svg';
import { ReactComponent as CloseIconPrimary } from 'assets/icons/icon-close-primary.svg';
import InputWithIcon from 'components/inputs/inputWithIcon';
import search from 'assets/icons/icon-search.svg';
import CheckBox from 'components/inputs/checkbox';
import useClickOutside from 'hooks/useOutsideClick';
import CustomColorButtonWithLogo from 'components/buttons/customColorButtonWithLogo';
import { isEmpty } from 'utils/utils';
import { setChannelsSelected } from 'slices/boxes/slice';
import { useParams } from 'react-router-dom';

const Channels = ({
  openChannels,
  setOpenChannels,
  selectedChannels,
  setSelectedChannels
}) => {
  const channelsRef = useRef();
  const dispatch = useDispatch();
  const { box_id } = useParams();
  const [searchValue, setSearchValue] = useState('');
  const { allConnectedChannels } = useSelector((state) => state.channels);
  const [allChannels, setAllChannels] = useState(allConnectedChannels);
  const { channelsSelected } = useSelector((state) => state.boxes);

  useClickOutside(() => setOpenChannels(false), channelsRef.current);

  useEffect(() => {
    setSelectedChannels([]);
    dispatch(setChannelsSelected([]));
  }, [box_id]);

  const getCheckedStatus = (id) => {
    if (!isEmpty(selectedChannels)) {
      const isChannelChecked = selectedChannels?.some((item) => item.id === id);
      return isChannelChecked;
    } else {
      return false;
    }
  };

  const handleChange = (e, channel) => {
    if (e.target.checked) {
      setSelectedChannels([...selectedChannels, channel]);
      dispatch(setChannelsSelected([...channelsSelected, channel.id]));
    } else {
      const FilteredChannels = selectedChannels.filter(
        (item) => item.id !== channel.id
      );
      setSelectedChannels(FilteredChannels);
      const remainedChannels = [];
      FilteredChannels.forEach((item) => {
        return remainedChannels.push(item.id);
      });
      dispatch(setChannelsSelected(remainedChannels));
    }
  };

  const handleRemove = (id) => {
    const FilteredChannels = selectedChannels.filter((item) => item.id !== id);
    setSelectedChannels(FilteredChannels);
    const remainedChannels = [];
    FilteredChannels.forEach((item) => {
      return remainedChannels.push(item.id);
    });
    dispatch(setChannelsSelected(remainedChannels));
  };

  const handleSearch = (e) => {
    setSearchValue(e.target.value);
    if (e.target.value !== '') {
      const filtered = allConnectedChannels?.filter((item) =>
        item.name.toLowerCase().includes(e.target.value.toLowerCase())
      );
      setAllChannels(filtered);
    } else {
      setAllChannels(allConnectedChannels);
    }
  };

  return (
    <>
      <CustomColorButtonWithLogo
        className={`border btn--xs-28 ${
          openChannels
            ? 'bg-grey-90 border-primary-border '
            : 'border-grey-400 btn--xs-28'
        } ${
          selectedChannels.length > 0 && !openChannels
            ? 'bg-grey-90 border-primary-border btn--xs-28-p-1'
            : ''
        }`}
        style={{ borderRadius: 20 }}
        render={() => {
          return (
            <>
              <div
                className="flex items-center mr-2"
                onClick={() => {
                  setOpenChannels(!openChannels);
                }}
              >
                <figure className="mr-2">
                  {!openChannels ? (
                    selectedChannels.length > 0 ? (
                      <ChannelsIconPrimary />
                    ) : (
                      <ChannelsIcon />
                    )
                  ) : (
                    <ChannelsIconPrimary />
                  )}
                </figure>
                {selectedChannels.length > 0 ? (
                  <></>
                ) : (
                  <span
                    className={`text-13 font-medium mr-2 ${
                      openChannels ? 'text-primary' : ''
                    }`}
                  >
                    Channel
                  </span>
                )}
                <figure>
                  {!openChannels ? (
                    selectedChannels.length > 0 ? (
                      <CaretDownIconPrimary />
                    ) : (
                      <CaretDownIcon />
                    )
                  ) : (
                    <CaretUpIconPrimary />
                  )}
                </figure>
              </div>
              {selectedChannels.length > 0 &&
                selectedChannels.map((item, index) => {
                  return (
                    <div className="box-filter-assignTo" key={index}>
                      <img
                        className="mr-1"
                        src={item.channel.avatar_link}
                        style={{ width: 16, height: 16, borderRadius: '50%' }}
                        alt=""
                      />
                      <p className="text-xs font-medium mr-2 truncate">
                        {item.name}
                      </p>
                      <figure onClick={() => handleRemove(item.id)}>
                        <CloseIconPrimary />
                      </figure>
                    </div>
                  );
                })}
            </>
          );
        }}
      />
      <div
        className={`dropdown-menu dropdown-menu--with-border mt-3 ${
          openChannels ? 'open' : ''
        } p-2`}
        style={{
          right: '0px',
          width: '316px',
          top: '20px',
          transition: 'all 300ms ease-in'
        }}
        ref={channelsRef}
      >
        <div style={{ margin: '10px 10px 0px' }}>
          <InputWithIcon
            icon={search}
            placeholder="Search"
            value={searchValue}
            onChange={(e) => handleSearch(e)}
            iconClassName="mr-2"
            medium
          />
        </div>
        {/* <p
          className="text-grey-700 text-xs font-medium text-left mt-4"
          style={{ marginLeft: 10 }}
        >
          Channels
        </p> */}
        <div className={`no-hover dropdown-menu__overflow-y-scroll mt-1`}>
          {isEmpty(allChannels) ? (
            <div className="flex justify-center text-sm font-semibold h-full items-center">
              Channel not found
            </div>
          ) : (
            <>
              {allChannels.map((channel, index) => {
                return (
                  <label
                    key={index}
                    className="dropdown-menu__list font-medium"
                    style={{ color: '#CE4961' }}
                    htmlFor={`${channel.id}`}
                  >
                    <CheckBox
                      id={`${channel.id}`}
                      name={`${channel.id}`}
                      checked={getCheckedStatus(channel.id)}
                      onChange={(e) => handleChange(e, channel)}
                      style={{ marginRight: 12 }}
                      small
                    />
                    <img
                      className="mr-3"
                      src={channel.channel.avatar_link}
                      style={{ width: 26, height: 26, borderRadius: '50%' }}
                      alt=""
                    />
                    {channel.name}
                  </label>
                );
              })}
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default Channels;
