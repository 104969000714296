import React from 'react';

const BoxStageEmptyState = () => {
  return (
    <>
      <div className="conditional-popup conditional-popup--bg-light">
        <h4 className="text-lg font-semibold letterspacing24em mt-4">
          Add enquiries to this box to turn on this report
        </h4>
        <p className="text-13 letterspacing24em mt-1">
          <a
            className="text-primary font-semibold cursor-pointer"
            href="https://help.reckonsys.io/en/articles/7229104-box-reports"
            target="_blank"
            rel="noreferrer"
          >
            Learn more
          </a>{' '}
          about how this report is calculated
        </p>
      </div>
      <tbody>
        <tr>
          <td className="flex-1">
            <div className="flex items-center">
              <figure className="avatar-overlay"></figure>
              <div className="data-overlay ml-2"></div>
            </div>
          </td>
          <td>
            <p className="data-overlay data-overlay--sm ml-auto"></p>
          </td>
        </tr>
        <tr>
          <td className="flex-1">
            <div className="flex items-center">
              <figure className="avatar-overlay"></figure>
              <div className="data-overlay ml-2"></div>
            </div>
          </td>
          <td>
            <p className="data-overlay data-overlay--sm ml-auto"></p>
          </td>
        </tr>
        <tr>
          <td className="flex-1">
            <div className="flex items-center">
              <figure className="avatar-overlay"></figure>
              <div className="data-overlay ml-2"></div>
            </div>
          </td>
          <td>
            <p className="data-overlay data-overlay--sm ml-auto"></p>
          </td>
        </tr>
        <tr>
          <td className="flex-1">
            <div className="flex items-center">
              <figure className="avatar-overlay"></figure>
              <div className="data-overlay ml-2"></div>
            </div>
          </td>
          <td>
            <p className="data-overlay data-overlay--sm ml-auto"></p>
          </td>
        </tr>
        {/* <tr>
          <td className="flex-1">
            <div className="flex items-center">
              <figure className="avatar-overlay"></figure>
              <div className="data-overlay ml-2"></div>
            </div>
          </td>
          <td>
            <p className="data-overlay data-overlay--sm ml-auto"></p>
          </td>
        </tr> */}
      </tbody>
    </>
  );
};

export default BoxStageEmptyState;
