import React from 'react';
import Modal from 'components/modal';
import CustomColorButton from 'components/buttons/customColorButton';

const OnDownloadModal = ({ open, setOpen }) => {
  return (
    <Modal.Main open={open}>
      <Modal.Body className="overflow-y-auto">
        <React.Fragment>
          <div className="items-center">
            <div className="justify-center text-xl flex mt-5 font-semibold">
              <p>Check your email for your contact download file</p>
            </div>
            <p className="text-sm text-grey-900 mt-3 justify-center items-center text-center flex">
              We’ve sent an email to the email address you use to login to your
              reckonsys account with a file containing your downloaded contacts.
              If it is not in your email give it a few minutes or check spam.
            </p>
            <div className="relative mt-25 justify-center items-center flex">
              <CustomColorButton
                label="Close"
                className="btn btn--primary btn--sm min-w-120 "
                onClick={() => setOpen(false)}
              />
            </div>
          </div>
        </React.Fragment>
      </Modal.Body>
    </Modal.Main>
  );
};

export default OnDownloadModal;
