/* eslint-disable no-unused-vars */
import React from 'react';
import PropTypes from 'prop-types';
import styles from './index.module.scss';
import { isEmpty } from 'utils/utils';
import DropDownWrapper from 'components/dropdown/wrapper';
import { ReactComponent as IconBackArrow } from 'assets/icons/icon-back-arrow.svg';
import DropDownBody from 'components/dropdown/body';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

const RenderOption = ({ option, stageId }) => {
  const { activeLead } = useSelector((state) => state.leadConversation);
  return (
    <>
      <p style={{ fontSize: 14, marginRight: 4 }}>{option.stage_name}</p>
      <span className="text-13 text-grey-600 font-medium">
        {option.id === (stageId || activeLead?.box_stage)
          ? '(current stage)'
          : ''}
      </span>
    </>
  );
};

const SelectedActionStage = (props) => {
  const { activeLead } = useSelector((state) => state.leadConversation);
  const { open, selected, renderAvatar, onBack, onSelect } = props;
  const { stageId } = useParams();
  const RenderAvatar = renderAvatar;

  return (
    <DropDownWrapper
      open={open}
      className="dropdown-menu--with-border mt-3"
      style={{ padding: '11px 0px 18px' }}
    >
      <section className={`${styles.unassigned}`}>
        <section
          className={`${styles.back} text-13 text-primary font-medium cursor-pointer`}
          onClick={onBack}
        >
          <IconBackArrow
            width="10px"
            height="10px"
            style={{ marginRight: '5px' }}
          />
          <span className="text-13 text-primary font-medium cursor-pointer">
            Back
          </span>
        </section>
      </section>
      <section className={`${styles.unassigned} ${styles.selected}`}>
        <div>
          <RenderAvatar />
        </div>
      </section>
      <DropDownBody
        open={open}
        className="dropdown-menu__overflow-y-scroll mt-4"
      >
        <>
          <p className="text-grey-700 px-3 text-xs" style={{ marginBottom: 6 }}>
            Select the stage you want to move it to
          </p>
          {!isEmpty(selected) &&
            selected.boxstage_set.map((option, index) => {
              return (
                <div
                  key={index}
                  className={`dropdown-menu__list font-medium`}
                  onClick={
                    option.id == (stageId || activeLead?.box_stage)
                      ? () => {}
                      : () => onSelect(option)
                  }
                  style={{
                    color: '#CE4961',
                    cursor:
                      option.id == (stageId || activeLead?.box_stage)
                        ? 'no-drop'
                        : ''
                  }}
                >
                  <RenderOption option={option} stageId={stageId} />
                </div>
              );
            })}
        </>
      </DropDownBody>
    </DropDownWrapper>
  );
};

SelectedActionStage.propTypes = {
  open: PropTypes.bool.isRequired,
  renderAvatar: PropTypes.func.isRequired,
  onBack: PropTypes.func.isRequired
};

SelectedActionStage.defaultProps = {
  submitBtnText: 'Submit'
};

export default SelectedActionStage;
