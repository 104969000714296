import React from 'react';
import PropTypes from 'prop-types';
// import Avatar from 'components/avatar';
import { useDispatch } from 'react-redux';
import { ReactComponent as EyeIcon } from 'assets/icons/icon-preview-eye.svg';
import { ReactComponent as ResendEmailIcon } from 'assets/icons/icon-resend-email.svg';
import { useNavigate } from 'react-router-dom';
import { route } from 'utils/route';
import { getLeadUrlForNotification } from 'slices/userNotificationSlice';
// import { markNotificationAsRead } from 'slices/allCount/slice';

const Layout = (props) => {
  const {
    // icon,
    title,
    time,
    desc,
    className,
    // isAutomationIcon,
    showViewEnquiryBtn,
    showResendInviteBtn,
    to
    // id
  } = props;
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onClick = () => {
    if (!to) {
      return;
    }
    const isUrl = to.startsWith('http');
    if (!isUrl) {
      dispatch(
        getLeadUrlForNotification({ leadId: to }, (data) => {
          // if (id) {
          //   dispatch(markNotificationAsRead({ body: { notification: id } }));
          // }
          const temp = data.lead_url.split('/');
          const url = temp.slice(3).join('/');
          navigate(`/${url}`);
        })
      );
      return;
    }
    if (to.includes('boxes')) {
      // if (id) {
      //   dispatch(markNotificationAsRead({ body: { notification: id } }));
      // }
      const data = to.split('/');
      const boxRoutePath = data.slice(5).join('/');
      navigate(`${route.dashboard}${route.boxes}/${boxRoutePath}`);
    } else {
      // if (id) {
      //   dispatch(markNotificationAsRead({ body: { notification: id } }));
      // }
      const data = to.split('/');
      const leadId = data[data.length - 1];
      const leadType = data[data.length - 2];
      navigate(
        `${route.dashboard}${route.enquirybox}${route.box}/lead/${leadType}/${leadId}`
      );
    }
  };

  return (
    <div className="flex">
      {/* <div>
        {!isAutomationIcon ? (
          <Avatar src={icon} smaller />
        ) : (
          <Avatar
            src={icon}
            smaller
            style={{ background: '#CE4961' }}
            imgStyle={{ width: '12px', height: '17px' }}
          />
        )}
      </div> */}
      <div className="team-chat-feed__right-side ml-2">
        <div className={`flex justify-between ${className}`}>
          <div className="text-sm font-semibold text-grey-800">{title}</div>
          <div className="text-grey-700 text-xs">{time}</div>
        </div>
        <div className="py-3 px-3">
          <p className="text-sm">{desc}</p>
          <div className="flex gap-1">
            {showResendInviteBtn && (
              <button
                className="btn btn--default mt-3 gap-1"
                style={{
                  border: '1px solid rgba(0, 0, 0, 0.05)',
                  fontWeight: 500
                }}
              >
                <ResendEmailIcon />
                Resend invite
              </button>
            )}
            {showViewEnquiryBtn && (
              <div
                onClick={onClick}
                className="btn btn--default mt-3 gap-1 cursor-pointer"
                style={{
                  border: '1px solid rgba(0, 0, 0, 0.05)',
                  fontWeight: 500
                }}
              >
                <EyeIcon />
                View enquiry
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

Layout.propTypes = {
  title: PropTypes.string,
  time: PropTypes.string,
  desc: PropTypes.string,
  icon: PropTypes.string,
  className: PropTypes.string,
  showViewEnquiryBtn: PropTypes.bool,
  isAutomationIcon: PropTypes.bool,
  showResendInviteBtn: PropTypes.bool
};

export default Layout;
