/* eslint-disable no-unused-vars */
import React from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import logo from 'assets/images/eb-logo.svg';
// import { ReactComponent as Enquirybox } from 'assets/icons/icon-dashboard-sidebar1.svg';
import { ReactComponent as Report } from 'assets/icons/icon-report.svg';
import { ReactComponent as AIAssistIcon } from 'assets/icons/AI-Assist-unfilled-icon.svg';
// import { ReactComponent as IconUser } from 'assets/icons/icon-user.svg';
// import { ReactComponent as EnquiryBot } from 'assets/icons/icon-dashboard-sidebar3.svg';
// import { ReactComponent as Menu } from 'assets/icons/icon-dashboard-sidebar4.svg';
import { ReactComponent as IconTrash } from 'assets/icons/icon-trash.svg';
import { ReactComponent as IconHelp } from 'assets/icons/icon-navbar-help.svg';
import { ReactComponent as IconEnquryBox } from 'assets/icons/icon-dashboard-nav-link.svg';
// import { ReactComponent as IconToolBox } from 'assets/icons/icon-toolbox-grey.svg';
// import { ReactComponent as IconToolBox } from 'assets/icons/toolbox-icon-gray.svg';
import { ReactComponent as IconSettings } from 'assets/icons/settings-new.svg';
import { ReactComponent as IconChannels } from 'assets/icons/icon-navbar-channels.svg';
import { route } from 'utils/route';
import Account from 'modules/settings/modals/logout';
import Tooltip from 'components/tooltip';
import {
  setPreviousRoute,
  setPreviousSettingsRoute
} from 'slices/channelsSlice';
import { useDispatch } from 'react-redux';

const NavBar = () => {
  const onRedirectToHelp = () => {
    window.open('https://help.reckonsys.io/en/', '_blank');
  };

  const location = useLocation();
  const dispatch = useDispatch();

  return (
    <div className="dashboard__sidebar__links">
      <div className="dashboard__sidebar__logo flex justify-center">
        <img
          src={logo}
          alt="eb-logo"
          className="mx-auto"
          style={{ height: 31 }}
        />
      </div>
      <section className="flex items-center justify-center flex-col mt-8">
        <Tooltip position="right" tooltipText="Boxes">
          <NavLink
            to={route.dashboard}
            className="dashboard__sidebar__links__list"
          >
            {/* <Enquirybox /> */}
            <IconEnquryBox />
          </NavLink>
        </Tooltip>
        <Tooltip position="right" tooltipText="Reports">
          <NavLink
            to={`${route.report}`}
            className="dashboard__sidebar__links__list"
          >
            <Report />
          </NavLink>
        </Tooltip>
        <Tooltip position="right" tooltipText="Channels">
          <NavLink
            to={`${route.channels}`}
            className="dashboard__sidebar__links__list"
            // onClick={() => {
            //   if (!location.pathname.includes(route.channels)) {
            //     dispatch(setPreviousRoute(location?.pathname));
            //   }
            // }}
          >
            <IconChannels height={22} width={22} className="toolbox-icon" />
          </NavLink>
        </Tooltip>
        <Tooltip position="right" tooltipText="Chatbot AI">
          <NavLink
            to={`${route.chatbotAi}`}
            className="dashboard__sidebar__links__list"
          >
            <AIAssistIcon />
          </NavLink>
        </Tooltip>
        {/* <div className="dashboard__sidebar__links__list">
          <IconUser height={20} width={20} />
        </div> */}
      </section>
      {/* <section className="flex items-center justify-center flex-col">
        <div className="dashboard__sidebar__links__list dashboard__sidebar__links__list--icon-leaf">
          <EnquiryBot height={20} width={20} fill="none" />
        </div>
      </section> */}
      <section className="flex items-center justify-center flex-col mt-auto">
        {/* <Tooltip position="right" tooltipText="Toolbox">
          <NavLink
            to={`${route.toolBox}`}
            className="dashboard__sidebar__links__list"
          >
            <IconToolBox height={22} width={22} className="toolbox-icon" />
          </NavLink>
        </Tooltip> */}
        <Tooltip position="right" tooltipText="Settings">
          <NavLink
            to={`${route.settings}`}
            className="dashboard__sidebar__links__list dashboard__sidebar__links__list_settings"
            onClick={() => {
              if (!location.pathname.includes(route.settings)) {
                dispatch(setPreviousSettingsRoute(location?.pathname));
              }
            }}
          >
            <IconSettings height={20} width={19} />
          </NavLink>
        </Tooltip>
        <Tooltip position="right" tooltipText={`Help & support`}>
          <div
            className="dashboard__sidebar__links__list circle"
            onClick={onRedirectToHelp}
          >
            <IconHelp height={22} width={22} />
          </div>
        </Tooltip>
        <Tooltip position="right" tooltipText="Bin">
          <NavLink
            to={`${route.enquiryBin}`}
            className="dashboard__sidebar__links__list"
          >
            <IconTrash height={20} width={19} />
          </NavLink>
        </Tooltip>
        <Account />
      </section>
    </div>
  );
};

export default React.memo(NavBar);
