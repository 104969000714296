import React from 'react'; // , { useState }
import { Controller } from 'react-hook-form';
import CountryIcon from 'assets/icons/icon-country.svg';
import Select, { components } from 'react-select';
const { Option } = components;

const Country = (props) => {
  const { placeholder, options, control, defaultIconStyle, iconStyle, error } =
    props;
  const Control = ({ children, ...props }) => {
    const {
      selectProps: {
        value = {
          label: 'select country',
          value: '',
          icon: 'https://icons8.com/icon/3685/globe'
        }
      }
    } = props;
    return (
      <components.Control {...props}>
        <span className="pl-[19px] w-[41px] relative top-[2px]">
          <img
            src={value ? value?.icon : CountryIcon}
            style={value ? iconStyle : defaultIconStyle}
            alt={value?.label}
          />
        </span>
        {children}
      </components.Control>
    );
  };

  const IconOption = (props) => {
    return (
      <Option {...props}>
        <img src={props.data.icon} style={iconStyle} alt={props.data.label} />
        {props.data.label}
      </Option>
    );
  };

  return (
    <Controller
      control={control}
      render={({ field: { onChange, value, onBlur, name, ref } }) => {
        return (
          <Select
            inputRef={ref}
            placeholder={placeholder}
            isClearable
            options={options}
            value={value}
            onBlur={onBlur}
            onChange={onChange} // send value to hook form
            components={{ Control, Option: IconOption }}
            className="indicatorContainer"
            classNamePrefix="updateStyles"
            blurInputOnSelect
            styles={{
              control: (baseStyles, state) => ({
                ...baseStyles,
                border: error
                  ? '1px solid #eb5757'
                  : state.isFocused
                  ? '1px solid #CE4961'
                  : '1px solid #e6e4e1',
                boxShadow: state.isFocused
                  ? '0px 0px 0px 1.5px rgba(0, 92, 111, 0.25)'
                  : '',
                '&:hover': {
                  border: error
                    ? '1px solid #eb5757'
                    : state.isFocused
                    ? '1px solid #CE4961'
                    : '1px solid #e6e4e1',
                  boxShadow: state.isFocused
                    ? '0px 0px 0px 1.5px rgba(0, 92, 111, 0.25)'
                    : '1px solid #e6e4e1'
                }
              })
            }}
          />
        );
      }}
      name="countryName"
      rules={{
        required: true
      }}
    />
  );
};

export default Country;
