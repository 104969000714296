/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import HeaderItem from 'modules/channel/main/form/helper/headerItem';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import {
  getFbLeadAdsConnectedPages,
  setFbLeadAdsConnectedPages,
  updateFbleadAdsConnectedPageStatus
} from 'slices/channelsSlice';
import { useParams } from 'react-router-dom';
import { isEmpty } from 'utils/utils';
import Toggle from 'components/inputs/toggle';
import { useForm } from 'react-hook-form';
import { useSnackbar } from 'components/Snackbar';

const FbLeadAdsConnectedPages = () => {
  const { channelId } = useParams();
  const { openSuccessSnackBar } = useSnackbar();
  const dispatch = useDispatch();
  const { fbLeadAdsConnectedPages } = useSelector((state) => state.channels);
  const { register } = useForm({
    mode: 'onChange'
  });

  const handleConnectedPageStatus = (evt, selectedItem) => {
    const updatedAvaliableConnectedPages = fbLeadAdsConnectedPages.map(
      (item, index) => {
        if (item?.id === selectedItem.id) {
          return { ...item, is_connected: evt.target.checked };
        }
        return item;
      }
    );
    dispatch(setFbLeadAdsConnectedPages([...updatedAvaliableConnectedPages]));
    const payload = {
      channelId: channelId,
      formId: selectedItem?.id,
      body: {
        is_connected: evt.target.checked
      }
    };
    dispatch(updateFbleadAdsConnectedPageStatus(payload, onSuccess));
  };

  useEffect(() => {
    const payload = {
      channelId: channelId
    };
    dispatch(getFbLeadAdsConnectedPages(payload));
  }, [channelId]);

  const onSuccess = () => {
    openSuccessSnackBar('Success');
  };

  return (
    <div className="card-box px-9 py-6 mt-10 ">
      <div>
        <HeaderItem title="Forms connected to this page" />
        <div className="box-settings__automation-template">
          <p className="box-settings__automation-template__text text-sm mt-4 mb-4">
            Lead ad submissions from these pages will come into your reckonsys
          </p>

          {!isEmpty(fbLeadAdsConnectedPages) &&
            fbLeadAdsConnectedPages.map((item) => (
              <div
                className={`box-settings__automation-template__automation-stuff mt-2 py-3 px-3 flex justify-between items-center`}
                key={item?.id}
              >
                <div className="font-semibold text-base">{item.name}</div>
                <div className="flex items-center">
                  <Toggle
                    id={item.id}
                    register={register}
                    key={item?.id}
                    onChange={(evt) => handleConnectedPageStatus(evt, item)}
                    checked={item.is_connected}
                  />
                </div>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

export default FbLeadAdsConnectedPages;
