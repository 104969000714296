/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import Header from '../../../../components/layout/header';
import { ReactComponent as FbLeadsIcon } from 'assets/icons/icon-fb-lead-channels-new.svg';
import ListHeader from '../../../../components/layout/listHeader';
import ListItem from '../../../../components/layout/listItem';
import CustomColorButtonWithLogo from 'components/buttons/customColorButtonWithLogo';
// import SetUpVideo from 'components/setUpVideo';
import { useNavigate, useParams } from 'react-router-dom';
import { route } from 'utils/route';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'components/Snackbar';
import _get from 'lodash/get';
import useFacebook from 'hooks/useFacebook';
import { getFbLeadsCreds } from 'slices/facebook/slice';
import { exchangeFaceBookLeadsOauthCode } from 'slices/facebook/slice';
import {
  getAllPendingChannels,
  getAllConnectedChannels
} from 'slices/channelsSlice';
import { useAnalytics } from 'use-analytics';
import GuideLink from 'components/guideLink';
import ChannelBack from '../back';
import CHOICES from 'choices';
import { ReactComponent as NewTabIcon } from 'assets/icons/icon-new-tab-white.svg';

const { FACEBOOK_LEAD_ADS } = CHOICES.ChannelChoices;

const FacebookLeadsHome = () => {
  const navigate = useNavigate();
  // const url = window.location.href;

  const dispatch = useDispatch();
  const { channelId } = useParams();
  const { fbLeadConfigurations } = useSelector((state) => state.fb);
  const { openSuccessSnackBar, openErrorSnackBar } = useSnackbar();
  // const { analytics } = useSelector((state) => state.segments);
  const { track } = useAnalytics();

  const appId = _get(fbLeadConfigurations, 'app_id', undefined);
  const [isLoading, setIsLoading] = useState(false);
  const [FB, isReady] = useFacebook(appId);
  const { subOrganizationId } = useSelector((state) => state.myInfo);

  useEffect(() => {
    dispatch(getFbLeadsCreds());
  }, []);

  const onSuccessLogin = (res) => {
    if (res?.data?.connected_channel_id) {
      dispatch(getAllPendingChannels());
      dispatch(getAllConnectedChannels());
      openSuccessSnackBar('Connected');
      track(
        'channel_created',
        {
          channel_name: 'Facebook lead ads'
        },
        {
          groupId: subOrganizationId
        }
      );
      navigate(
        `${route.channels}/facebook-leads/${FACEBOOK_LEAD_ADS}/connected/${res.data.connected_channel_id}/automations`
      );
      setIsLoading(false);
    } else if (
      res?.data?.message.includes('no link, so stay on the same page.')
    ) {
      setIsLoading(false);
      openErrorSnackBar('invalid token');
    }
  };

  const onConnect = () => {
    if (FB && isReady && fbLeadConfigurations && fbLeadConfigurations.scope) {
      setIsLoading(true);
      try {
        FB.login(
          (resp) => {
            const signedRequest = _get(
              resp,
              'authResponse.signedRequest',
              false
            );
            if (signedRequest)
              dispatch(
                exchangeFaceBookLeadsOauthCode(
                  {
                    channelId: channelId,
                    data: {
                      signed_request: signedRequest
                    }
                  },
                  onSuccessLogin
                )
              );
            else {
              setIsLoading(false);
            }
          },
          {
            scope: fbLeadConfigurations.scope
          }
        );
      } catch (e) {
        setIsLoading(false);
        console.log(e);
      }
    }
  };

  return (
    <div className="toolbox__main overflow-overlay channels">
      <div className="card-container--new connect-new">
        <ChannelBack />
        <Header
          title="Connect your Facebook Lead Ads"
          description="By connecting your Facebook Lead Ads you will be able to receive and reply to new leads ,
        right from your reckonsys."
        />
        <div className="mt-8">
          <ListHeader
            title="To connect your account, you need these 3 things"
            className="font-semibold"
          />
          <ul className="mt-3 ml-1">
            <ListItem
              title="A Facebook Ads account"
              className="text-sm font-medium"
            />
            <ListItem
              title="Admin level access to that account"
              className="text-sm font-medium mt-2"
            />
            <ListItem
              title="page running Facebook Leads Ads"
              className="text-sm font-medium mt-2"
            />
          </ul>
        </div>
        <div className="bg-grey-200 rounded-xl py-3 px-4 mt-8">
          <p className="text-grey-800 text-13">
            When you connect your account, we only access your lead ads. Your
            personal messages will never be accessed by reckonsys.
          </p>
        </div>
        <div className="mt-5">
          <CustomColorButtonWithLogo
            label="Connect my Facebook Lead Ads"
            className="btn--sm btn--facebook gap-2 text-15"
            renderSvg={() => <FbLeadsIcon />}
            renderRightSvg={() => <NewTabIcon />}
            onClick={onConnect}
            isLoading={isLoading}
            enableLoader={true}
            loaderInline={true}
            isLoaderWhite={true}
          />
        </div>
        <p className="mt-4 text-grey-800 text-sm">
          A new tab will open and ask you to login with Facebook lead ads
        </p>
        <div className="mt-5">
          {/* <SetUpVideo
          src="https://player.vimeo.com/video/815688083?h=4ca5e65a0b&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
          articleLink="https://help.reckonsys.io/en/articles/7228334-connect-facebook-lead-ads"
        /> */}
          <GuideLink url="https://help.reckonsys.io/en/articles/7228334-connect-facebook-lead-ads" />
        </div>
      </div>
    </div>
  );
};

export default FacebookLeadsHome;
