/* eslint-disable react-hooks/exhaustive-deps */
import { Loader } from 'components/loader';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams, useSearchParams } from 'react-router-dom';
import {
  getAllBoxReport,
  getAllIncomingEnquiryReport,
  getAllSalesReport,
  getAllSourceReport,
  getAllTeamReport
} from 'slices/reports/slice';
import IncomingEnquiryReportPublic from './incomingEnquiryReportPublic';
import { ReactComponent as EbLogo } from 'assets/icons/icon-enquiry-eb-box-logo.svg';
import SalesReportPublic from './salesReportPublic';
import SourceReportPublic from './sourceReportPublic';
import BoxReportPublic from './boxReportPublic';
import TeamReportPublic from './teamReportPublic';

const ReportPublic = () => {
  const { reportType } = useParams();
  const dispatch = useDispatch();
  const [queryParams] = useSearchParams();
  const startDate = queryParams.get('startDate');
  const endDate = queryParams.get('endDate');
  const date_format = queryParams.get('date_format');
  const subOrgId = queryParams.get('subOrgId');
  const boxId = queryParams.get('boxId');
  const [isLoading, setIsLoading] = useState(false);

  const callback = () => {
    setIsLoading(false);
  };

  const loadReportData = () => {
    const formData = {
      startDate: startDate,
      endDate: endDate
    };
    if (reportType === 'incoming_report') {
      setIsLoading(true);
      const data = {
        ...formData,
        date_format: date_format,
        subOrgId: subOrgId
      };
      dispatch(getAllIncomingEnquiryReport(data, callback));
    }
    if (reportType === 'sales_report') {
      setIsLoading(true);
      const data = {
        ...formData,
        date_format: date_format,
        subOrgId: subOrgId
      };
      dispatch(getAllSalesReport(data, callback));
    }
    if (reportType === 'source_report') {
      setIsLoading(true);
      const data = {
        ...formData,
        subOrgId: subOrgId
      };
      dispatch(getAllSourceReport(data, callback));
    }
    if (reportType === 'box_report') {
      setIsLoading(true);
      const data = {
        ...formData,
        date_format: date_format,
        boxId: boxId
      };
      dispatch(getAllBoxReport(data, callback));
    }
    if (reportType === 'team_report') {
      setIsLoading(true);
      const data = {
        ...formData,
        date_format: date_format,
        subOrgId: subOrgId
      };
      dispatch(getAllTeamReport(data, callback));
    }
  };

  useEffect(() => {
    loadReportData();
  }, []);

  if (isLoading) return <Loader />;

  return (
    <div
      className="bg-primary w-full overflow-y-auto"
      style={{ height: '100vh' }}
    >
      {reportType === 'incoming_report' && (
        <IncomingEnquiryReportPublic
          startDate={startDate}
          endDate={endDate}
          reportType={reportType}
          date_format={date_format}
        />
      )}
      {reportType === 'sales_report' && (
        <SalesReportPublic
          startDate={startDate}
          endDate={endDate}
          reportType={reportType}
          date_format={date_format}
        />
      )}
      {reportType === 'source_report' && (
        <SourceReportPublic
          startDate={startDate}
          endDate={endDate}
          reportType={reportType}
          date_format={date_format}
        />
      )}
      {reportType === 'box_report' && (
        <BoxReportPublic
          startDate={startDate}
          endDate={endDate}
          reportType={reportType}
          date_format={date_format}
        />
      )}
      {reportType === 'team_report' && (
        <TeamReportPublic
          startDate={startDate}
          endDate={endDate}
          reportType={reportType}
          date_format={date_format}
        />
      )}
      <div
        className="flex bg-white justify-center rounded-md py-1 px-2 m-auto mt-8 mb-5 cursor-pointer"
        style={{ maxWidth: 170 }}
        onClick={() => window.open('http://enquirybox.io/', '_blank')}
      >
        <span className="text-13">Built with</span>
        <EbLogo className="mx-1" />
        <span className="text-13">Reckonsys</span>
      </div>
    </div>
  );
};

export default ReportPublic;
