import React, { useEffect, useRef, useState } from 'react';
import InputField from 'components/inputs/textField';
import Button from 'components/buttons/primary';
import { SketchPicker } from 'react-color';
import useClickOutside from 'hooks/useOutsideClick';
import { useDispatch, useSelector } from 'react-redux';
import { updateBusinessInfoData } from 'slices/businessInfoSlice';
import { useSnackbar } from 'components/Snackbar';
import PrimaryButton from 'components/buttons/primary';
import { isEmpty } from 'utils/utils';
import ErrorMessage from 'components/errorMessage';

const ColourPickup = (props) => {
  const { brandColor } = props;

  const [background, setBackground] = useState('#CE4961');
  const [showColourPicker, setShowColorPicker] = useState(false);
  const nodeRef = useRef();
  const { openSuccessSnackBar, openErrorSnackBar } = useSnackbar();
  const [colorCodeerror, setColorCodeError] = useState();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!isEmpty(brandColor)) {
      setBackground(brandColor);
    }
  }, [brandColor]);

  useClickOutside(() => setShowColorPicker(false), nodeRef.current);
  const { subOrganizationId } = useSelector((state) => state.myInfo);

  const dispatch = useDispatch();

  const chekIsValideColorCode = (bgColor) => {
    let isValid = true;
    // let isHex = new RegExp(/^#[0-9A-F]{6}$/i);
    if (isEmpty(bgColor)) {
      setColorCodeError('please fill the color code ');
    } else {
      setColorCodeError('');
    }
    if (bgColor?.length > 7 || bgColor?.length <= 3) {
      setColorCodeError('please enter a valid color code');
      isValid = false;
    } else {
      setColorCodeError('');
    }
    return isValid;
  };

  const handleColourChange = (color) => {
    chekIsValideColorCode(color);
    setBackground(color.hex);
  };

  const handleInputColorChange = (e) => {
    const { value } = e.target;
    chekIsValideColorCode(value);
    if (value.length === 1 && !value.includes('#')) {
      setBackground(`#${value}`);
      return;
    }
    if (value.length > 7) {
      setBackground(background);
      return;
    }
    if (value.length > 1 && !value.includes('#')) {
      setBackground(`#${value}`);
      return;
    }
    setBackground(value);
    // chekIsValideColorCode(evt.target.value)
    // if(evt.target.value.length === 1 && !evt.target.value.includes('#')){
    //     setBackground(evt.target.value);
    // }else{
    //     setBackground(`#${evt.target.value}`)
    // }
  };
  const updateColourChange = () => {
    setIsLoading(true);
    if (isEmpty(colorCodeerror)) {
      const payload = {
        subOrgId: subOrganizationId,
        data: {
          brand_color: background
        }
      };
      dispatch(updateBusinessInfoData(payload, onSuccess, onError));
    }
  };
  const onSuccess = () => {
    setIsLoading(false);
    openSuccessSnackBar('Success');
  };
  const onError = () => {
    setIsLoading(false);
    openErrorSnackBar('Error');
  };

  return (
    <div className="card-box mt-6 px-6 py-5 relative">
      <h3 className="text-lg font-semibold">Your brand theme colours</h3>
      <p className="text-base-1 text-grey-800 mt-2">
        Your brand theme colour will be used as a background colour or shared
        pages
      </p>
      <div className="flex justify-between mt-10 items-center">
        <label className="text-md font-semibold">Primary colour</label>
        <div className="relative" ref={nodeRef}>
          <div
            className="cursor-pointer"
            onClick={() => setShowColorPicker(!showColourPicker)}
          >
            <InputField
              className="input-field--md"
              name="email"
              placeholder="#CE4961"
              value={background}
              onChange={handleInputColorChange}
            />
            {colorCodeerror && <ErrorMessage message={colorCodeerror} />}
            <Button
              className="text-sm text-white ml-auto absolute"
              label=""
              enableLoader
              loaderInline
              style={{
                padding: '4px 10px',
                borderRadius: 6,
                position: 'absolute',
                top: '8px',
                right: '9px',
                width: '40px',
                height: '26px',
                alignItems: 'center',
                background: background
              }}
            />
          </div>
          <div className="absolute right-1 " style={{ zIndex: 2 }}>
            {showColourPicker && (
              <SketchPicker
                color={background}
                onChange={handleColourChange}
                presetColors={[]}
                disableAlpha={true}
              />
            )}
          </div>
        </div>
      </div>
      <PrimaryButton
        className="btn--xs ml-auto mt-3"
        label="save"
        isLoading={isLoading}
        enableLoader={true}
        loaderInline={true}
        onClick={() => updateColourChange()}
      />
    </div>
  );
};

export default ColourPickup;
