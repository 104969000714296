/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import InputWithIcon from 'components/inputs/inputWithIcon';
import PropTypes from 'prop-types';
import search from 'assets/icons/icon-search.svg';
import DropDownWrapper from 'components/dropdown/wrapper';
import DropDownBody from 'components/dropdown/body';
import { isEmpty } from 'utils/utils';
import Avatar from 'components/avatar';
// import closeIcon from 'assets/icons/icon-close-black.svg';
import DeleteConfirmation from 'components/alerts/deleteConfirmation';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

const RenderList = (props) => {
  const { open, title, options, onSelect, onSubmit, wrapperStyle } = props;

  // const { myInformation } = useSelector((state) => state.myInfo);
  const { box_id } = useParams();
  const [boxes, setBoxes] = useState(options);
  const [searchValue, setSearchValue] = useState('');
  const [showDelete, setShowDelete] = useState(false);
  const { activeLead } = useSelector((state) => state.leadConversation);

  useEffect(() => {
    setBoxes(options);
  }, [options]);

  const handleSearch = (e) => {
    setSearchValue(e.target.value);
    if (e.target.value !== '') {
      const filtered = options.filter((item) =>
        item.box_name.toLowerCase().includes(e.target.value.toLowerCase())
      );
      setBoxes(filtered);
    } else {
      setBoxes(options);
    }
  };

  const RenderOption = ({ option }) => {
    return (
      <>
        <Avatar
          src={option.box_avatar}
          border={false}
          smallest
          className="mr-3"
          contain
          style={{
            width: 20,
            height: 20
          }}
        />
        <p style={{ fontSize: 14, marginRight: 4 }}>{option.box_name}</p>
        <span className="text-13 text-grey-600 font-medium">
          {option.id === (box_id || activeLead?.box) ? '(current box)' : ''}
        </span>
      </>
    );
  };

  const onDeleteChannel = () => {
    onSubmit(true);
    setShowDelete(false);
  };

  return (
    <>
      <DeleteConfirmation
        showConfirmation={showDelete}
        onCancel={() => setShowDelete(false)}
        onConfirm={onDeleteChannel}
        title="Are you sure you want unassign this user?"
        confirmButtonText="Unassign"
        description=""
      />
      <DropDownWrapper
        open={open}
        className="dropdown-menu--with-border mt-3"
        style={{ padding: '18px 8px 8px', ...wrapperStyle }}
      >
        <div style={{ marginLeft: 10, marginRight: 10 }}>
          <InputWithIcon
            icon={search}
            placeholder="Search"
            value={searchValue}
            onChange={handleSearch}
            iconClassName="mr-2"
            medium
          />
        </div>
        <DropDownBody
          open={open}
          className="dropdown-menu__overflow-y-scroll mt-4"
        >
          <>
            {title && (
              <p
                className="text-grey-700 px-3 text-xs"
                style={{ marginBottom: 6 }}
              >
                {title}
              </p>
            )}
            {isEmpty(boxes) && (
              <div className="flex justify-center text-sm font-semibold">
                Box not found
              </div>
            )}
            {!isEmpty(boxes) &&
              boxes.map((option, index) => {
                return (
                  <div
                    key={index}
                    className={`dropdown-menu__list font-medium`}
                    onClick={() => onSelect(option)}
                    style={{ color: '#CE4961' }}
                  >
                    <RenderOption option={option} />
                  </div>
                );
              })}
          </>
        </DropDownBody>
      </DropDownWrapper>
    </>
  );
};

RenderList.propTypes = {
  wrapperStyle: PropTypes.object,
  open: PropTypes.bool.isRequired,
  title: PropTypes.string,
  options: PropTypes.array,
  onSelect: PropTypes.func.isRequired,
  onSubmit: PropTypes.func,
  active: PropTypes.object,
  deleteVisible: PropTypes.bool
};

RenderList.defaultProps = {
  wrapperStyle: {},
  open: false,
  deleteVisible: true,
  onSubmit: () => {}
};

export default RenderList;
