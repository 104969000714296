import React, { useState } from 'react';
import iconArrowDown from 'assets/icons/icon-chevron-arrow-down.svg';

const FAQ = () => {
  const [isActive, setIsActive] = useState(null);
  const faq = [
    {
      question: 'Can I use my own number',
      answer:
        'No. Each account has a custom number generated by Reckonsys. Existing numbers will be unable to be used to send or receive SMS'
    },
    {
      question: 'What happens when I use all my SMS?',
      answer:
        'When all your SMS are used up they will automatically be topped up by the same amount again. You will receive an email when your balance is low to notify you. If you do not want to auto top up your SMS bundle you will need to cancel the auto top up and select another bundle. '
    },
    {
      question: 'If I cancel my SMS plan, what happens to my number?',
      answer:
        'If you cancel your SMS plan, your SMS number will be deleted and you cannot get it back. If you cancel and want to sign up again for SMS you will receive a new SMS number.'
    }
  ];

  return (
    <div className="mt-6">
      <h4 className="font-medium">Frequently asked questions</h4>
      <ul className="accordion mt-5">
        {faq.map((item, index) => {
          return (
            <li
              key={index}
              className={`accordion__item ${
                isActive === index ? 'active' : ''
              }`}
              onClick={() => setIsActive(isActive === index ? null : index)}
            >
              <div className="accordion__header">
                <h4 className="accordion__title">{item.question}</h4>
                <img
                  src={iconArrowDown}
                  alt="icon-arrow-down"
                  className="accordion__arrow-icon"
                />
              </div>
              <div className="accordion__body">
                <p className="accordion__content">{item.answer}</p>
              </div>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default FAQ;
