/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useState } from 'react';
import Modal from 'components/modal';
import { ReactComponent as IconClouldUpload } from 'assets/icons/icon-upload-cloud.svg';
import { ReactComponent as IconCheck } from 'assets/icons/icon-check.svg';
import { isValidFileFormat, isValidFileSize } from 'utils/file';
import Uploading from 'modules/modals/attachFiles/upload/uploadingContainer';
import { isEmpty } from 'utils/utils';
import CustomColorButton from 'components/buttons/customColorButton';
import { useDispatch, useSelector } from 'react-redux';
import {
  getMappingFieldsForCsvMapping,
  getMappingFieldsFromCsv
} from 'slices/boxSettings/uploadContactsSlice';
import UnsupportedFile from 'modules/modals/attachFiles/upload/unsupportedFile';
import FileTooLarge from 'modules/modals/attachFiles/upload/fileTooLarge';
import ErrorMessage from 'components/errorMessage';
import { revertLead } from 'slices/boxSettings/uploadContactsSlice';
import usePlanAccess from 'hooks/usePlanAccess';
import swal from 'sweetalert';
import { getMyInfo } from 'slices/myInfoSlice';
import { getFreeEnquiriesCount } from 'slices/subscription/slice';

const ContactModalUpload = ({
  open,
  setOpen,
  setShowMapFields,
  uploadedFiles,
  setUploadedFiles,
  setUploadedCount
}) => {
  const inputRef = useRef();
  const { is_free_plan } = usePlanAccess();
  const { currentPlan, freeEnquiriesCount } = useSelector(
    (state) => state.subscription
  );
  const [isLargeFile, setIsLargeFile] = useState(false);
  const [unsupportedFile, setUnsupportedFile] = useState(false);
  const [showDropDown, setShowdropDown] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [uploadedFileTotalRows, setUploadfileTotalRows] = useState();
  const [isEmptyUploadedFile, setIsEmptyUploadedFile] = useState(false);
  const dispatch = useDispatch();
  // const { uploadedFileId } = useSelector((state) => state.uploadContactsSettings);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!open) {
      setIsLoading(false);
    }
  }, [open]);

  // const checkIsValidCharacter = (name) => {
  //   const isValid = name.length <= 30;
  //   if (!isValid) {
  //     setMaxCharacterFile(true);
  //   }
  //   return isValid;
  // };

  const getRemainingFreeEnquiriesCount = () => {
    return (
      parseInt(currentPlan.plan.enquiries_limit) - parseInt(freeEnquiriesCount)
    );
  };

  const checkIsValidFile = (type) => {
    const isValid = isValidFileFormat(type);
    if (!isValid) {
      setUnsupportedFile(true);
    }
    return isValid;
  };

  const checkIsValidFileSize = (size) => {
    const isValid = isValidFileSize(size);
    if (!isValid) {
      setIsLargeFile(true);
    }
    return isValid;
  };

  const handleDrop = async (event) => {
    event.preventDefault();
    if (event.dataTransfer.files && event.dataTransfer.files[0]) {
      // at least one file has been dropped so do something
      // handleFiles(e.dataTransfer.files);
      // console.log('at least one file has been dropped so do something');
      const file = event.dataTransfer.files[0];
      // const isValidCharacter = checkIsValidCharacter(file.name);
      // if (!isValidCharacter) return;
      const isValidFile = checkIsValidFile(file.name);
      if (!isValidFile) return;
      const isFileSizeValid = checkIsValidFileSize(file.size);
      if (!isFileSizeValid) return;
      const totalRows = await countRowsInCSV(file);
      if (is_free_plan && totalRows - 1 > getRemainingFreeEnquiriesCount()) {
        swal({
          title: '',
          text: `You can upload only ${getRemainingFreeEnquiriesCount()} contacts`,
          icon: 'info',
          button: {
            text: 'Ok',
            value: true,
            visible: true,
            className: '',
            closeModal: true
          }
        });
        return;
      }
      setUploadfileTotalRows(totalRows);
      setUploadedCount(totalRows);
      const fileData = {
        file: file,
        isUploading: true,
        file_name: file.name,
        type: file.type
      };
      setUploadedFiles([fileData]);
    }
  };

  const countRowsInCSV = async (csvFile) => {
    return new Promise((resolve, reject) => {
      try {
        const reader = new FileReader();
        reader.onload = (event) => {
          const cvsData = event.target.result;
          const rowData = cvsData.split('\n');
          const nonEmptyCsvData = rowData.filter(
            (line) => line.trim() !== '' && !/^,+$/.test(line.trim())
          );
          resolve(nonEmptyCsvData.length);
        };
        reader.readAsText(csvFile);
      } catch (error) {
        reject(error);
      }
    });
  };

  const handleInputChange = async (event) => {
    setIsEmptyUploadedFile(false);
    const file = event.target.files[0];
    event.target.value = '';
    const totalRows = await countRowsInCSV(file);
    if (totalRows - 1 <= 0) {
      setIsEmptyUploadedFile(true);
      return;
    }
    if (is_free_plan && totalRows - 1 > getRemainingFreeEnquiriesCount()) {
      swal({
        title: '',
        text: `You can upload only ${getRemainingFreeEnquiriesCount()} contacts`,
        icon: 'info',
        button: {
          text: 'Ok',
          value: true,
          visible: true,
          className: '',
          closeModal: true
        }
      });
      return;
    }
    setUploadfileTotalRows(totalRows);
    setUploadedCount(totalRows);
    // const isValidCharacter = checkIsValidCharacter(file.name);
    // if (!isValidCharacter) return;
    const isValidFile = checkIsValidFile(file.name);
    if (!isValidFile) return;
    const isFileSizeValid = checkIsValidFileSize(file.size);
    if (!isFileSizeValid) return;
    const fileData = {
      file: file,
      isUploading: true,
      file_name: file.name,
      type: file.type
    };
    setUploadedFiles([fileData]);
  };

  const onSuccess = () => {
    setUploadfileTotalRows(0);
    setShowMapFields(true);
    setIsLoading(false);
    setIsUploading(false);
    setOpen(false);
  };

  const handleNextbtn = async () => {
    setIsLoading(true);
    try {
      Promise.all([
        dispatch(getMappingFieldsForCsvMapping()),
        dispatch(getMappingFieldsFromCsv())
      ]).then(() => {
        onSuccess();
      });
    } catch (e) {
      console.log(e);
    }
    // setOpen(false);
  };

  // const handleDownloadTemplate = () => {
  //   window.open(
  //     'https://ebox-static-files.s3.eu-west-1.amazonaws.com/logos/sample_import_contacts.csv'
  //   );
  // };
  const onSuccessRevert = () => {
    if (is_free_plan) {
      dispatch(getFreeEnquiriesCount());
    }
    setUploadedCount(0);
    setUploadedFiles([]);
    setIsUploading(false);
    setIsEmptyUploadedFile(false);
    setOpen(false);
  };

  const onErrorRevert = () => {
    if (is_free_plan) {
      dispatch(getFreeEnquiriesCount());
    }
    setUploadedFiles([]);
    setIsUploading(false);
    setIsEmptyUploadedFile(false);
    setOpen(false);
  };

  const handleCancel = () => {
    setIsLoading(true);
    if (!isEmpty(uploadedFiles)) {
      dispatch(
        revertLead({ id: uploadedFiles[0]?.id }, onSuccessRevert, onErrorRevert)
      );
    } else {
      setUploadedFiles([]);
      setIsUploading(false);
      setIsEmptyUploadedFile(false);
      setOpen(false);
    }
  };

  const handleReadTip = () => {
    window.open(
      'https://help.reckonsys.io/en/articles/7228593-box-contacts',
      '_blank'
    );
  };
  2;

  return (
    <>
      <Modal.Main open={open} className="modal--channels-request">
        <Modal.Header title="Upload contacts" onClose={() => handleCancel()} />
        <Modal.Body className="pb-5 py-4 overflow-y-auto">
          <React.Fragment>
            <div className="modal__body ">
              <div className="mt-8 mb-8 p-5 border border-dashed border-grey-400 rounded-xl ">
                <div
                  className="file-upload-wrapper"
                  style={{ border: '0px', padding: '0px' }}
                  onClick={() => inputRef?.current?.click()}
                  onDrop={(e) => handleDrop(e)}
                  onDragOver={(e) => handleDrop(e)}
                  onChange={handleInputChange}
                >
                  <div className="text-center">
                    <div
                      className="inline-flex items-center justify-center bg-grey-90 rounded-full"
                      style={{ width: 70, height: 70 }}
                    >
                      {isUploading && !isEmpty(uploadedFiles) ? (
                        <IconCheck width={20} height={20} />
                      ) : (
                        <IconClouldUpload />
                      )}
                    </div>
                  </div>
                  {isEmpty(uploadedFiles) && (
                    <div className="text-center mt-4">
                      <input
                        ref={inputRef}
                        type="file"
                        name="file-browser-input"
                        accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                      />
                      <h3 className="text-lg font-semibold">
                        Upload your CSV file
                      </h3>
                      <p className="font-normal text-grey-800 text-sm mt-2 mb-5">
                        Drag and drop here or
                        <span
                          className="text-primary font-normal cursor-pointer"
                          onClick={(event) => {
                            event.stopPropagation();
                            inputRef.current.click();
                          }}
                        >
                          {' '}
                          browse
                        </span>
                      </p>
                    </div>
                  )}
                  <Uploading
                    uploadedFiles={uploadedFiles}
                    setUploadedFiles={setUploadedFiles}
                    setShowdropDown={setShowdropDown}
                    showDropDown={showDropDown}
                    isContactUploadModal={true}
                    setIsContactModalUploading={setIsUploading}
                    isContactModalUploaded={isUploading}
                    uploadedFileTotalRows={uploadedFileTotalRows}
                  />
                  {isLargeFile && (
                    <FileTooLarge onTryAgain={() => setIsLargeFile(false)} />
                  )}
                  {unsupportedFile && (
                    <UnsupportedFile
                      onTryAgain={() => setUnsupportedFile(false)}
                    />
                  )}
                  {isEmptyUploadedFile && (
                    <ErrorMessage
                      className="mb-1"
                      message="Please upload a file with atleast one contact lead"
                    />
                  )}
                </div>
                {isEmpty(uploadedFiles) && (
                  <>
                    <hr className="text-grey-400 mx-8" />
                    <div className="text-center px-10 mt-5">
                      <p className="text-center font-normal text-grey-800 text-base-1">
                        Ensure accurate contact matches to box questions by
                        following our straightforward tips.
                        <span
                          className="text-primary font-normal cursor-pointer"
                          onClick={handleReadTip}
                        >
                          {' '}
                          Read our tips
                        </span>
                      </p>
                      {/* <span
                        className="inline-block text-sm text-primary font-semibold mt-2 cursor-pointer"
                        onClick={(evt) => {
                          evt.stopPropagation();
                          handleDownloadTemplate();
                        }}
                      >
                        Download template
                      </span> */}
                    </div>
                  </>
                )}
              </div>
              {isUploading && !isEmpty(uploadedFiles) && (
                <div className="flex justify-end mb-4">
                  <CustomColorButton
                    label="Cancel"
                    className="btn btn--grey btn--sm min-w-120 mr-2"
                    onClick={() => handleCancel()}
                  />
                  <CustomColorButton
                    label="Next"
                    className="btn btn--primary btn--sm min-w-120 "
                    onClick={handleNextbtn}
                    isLoading={isLoading}
                    enableLoader
                    loaderInline
                  />
                </div>
              )}
            </div>
          </React.Fragment>
        </Modal.Body>
      </Modal.Main>
    </>
  );
};

export default ContactModalUpload;
