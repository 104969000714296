/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useRef, useEffect } from 'react';
import { object } from 'yup';
import PropTypes from 'prop-types';
import Modal from 'components/modal';
import { useForm } from 'react-hook-form';
import Select from 'components/inputs/select';
import useClickOutside from 'hooks/useOutsideClick';
import { yupResolver } from '@hookform/resolvers/yup';
import CustomColorButton from 'components/buttons/customColorButton';
import { handleScrollIntoview, isEmpty } from 'utils/utils';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'components/Snackbar';
import { requiredSelectMsg } from 'utils/messages';
import { getLeadConversation, setActiveLead } from 'slices/conversationSlice';
import { useParams } from 'react-router-dom';
import ErrorMessage from 'components/errorMessage';
import ListHeader from '../webformMapFields/components/listHeader';
import FieldListItem from '../webformMapFields/components/fieldListItem';
import {
  getAdsFormFields,
  getAdsFormQuestionFields,
  sendLeadAdsMapFields
} from 'slices/facebook/slice';
import {
  ErrorMessagePopUp,
  ErrorPopUpBackdrop
} from '../webformMapFields/components/errorPopup';

const FbLeadAdsMapFields = (props) => {
  const { showMapFields, onClose, activeLead } = props;
  const nodeRef = useRef();
  const parentRef = useRef();
  const { cardId } = useParams();
  const dispatch = useDispatch();

  const { openSuccessSnackBar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const [, setOpenDropdown] = useState(false);
  const [errorPopUp, setErrorPopUp] = useState(false);
  const [callbackError, setCallbackError] = useState([]);
  const [resetValue, setResetValue] = useState(false);
  const { adsFormFields } = useSelector((state) => state.fb);
  const { adsFormQuestionFields } = useSelector((state) => state.fb);

  const getFormFields = !isEmpty(adsFormQuestionFields)
    ? adsFormQuestionFields?.map((e) => e.key)
    : [];

  const selectFormSchema = getFormFields.reduce((acc, field) => {
    return {
      ...acc,
      [field]: object().nullable().required(requiredSelectMsg(field))
    };
  }, {});

  const formSchema = {
    ...selectFormSchema
  };
  const {
    handleSubmit,
    control,
    setValue,
    getValues,
    clearErrors,
    reset,
    formState: { errors, touchedFields }
  } = useForm({
    resolver: yupResolver(object().shape(formSchema)),
    mode: 'all'
  });

  useClickOutside(() => setOpenDropdown(false), nodeRef.current);

  useEffect(() => {
    if (showMapFields) {
      dispatch(getAdsFormFields(cardId));
      dispatch(getAdsFormQuestionFields());
    }
  }, [showMapFields]);

  useEffect(() => {
    const keys = Object.keys(selectFormSchema);
    clearErrors(keys);
    reset();
    if (showMapFields) {
      setResetValue(true);
      setErrorPopUp(false);
    }
  }, [showMapFields]);

  const loadLeadConversation = () => {
    dispatch(getLeadConversation({ leadId: cardId }));
  };

  const onSubmit = async (data) => {
    setIsLoading(true);
    setCallbackError([]);
    const form_fields = Object.keys(data);
    const mappedFields = form_fields.map((e) => ({
      question_form_value: e,
      form_key: data[e].key
    }));

    // if (mappedFields.some((item) => item.field_name === 'donotmap')) {
    //   const removeDonotMap = mappedFields.filter(
    //     (e) => e.field_name !== 'donotmap'
    //   );
    //   const formData = {
    //     body: {
    //       mapping: removeDonotMap,
    //       name: data?.webformName
    //     },
    //     leadId: leadId
    //   };
    //   dispatch(sendLeadAdsMapFields(formData, onSuccess, onError));
    // } else {

    const formData = {
      data: {
        mapping_format: mappedFields
      },
      leadId: cardId
    };
    dispatch(sendLeadAdsMapFields(formData, onSuccess, onError));
    // }
  };

  const onError = (err) => {
    setIsLoading(false);
    setCallbackError(err.mapping);
  };

  const onSuccess = () => {
    setIsLoading(false);
    onClose();
    openSuccessSnackBar('Mapping success');
    loadLeadConversation();
    dispatch(setActiveLead({ ...activeLead, is_mapping_necessary: false }));
  };

  useEffect(() => {
    if (!isEmpty(errors)) {
      setErrorPopUp(true);
    }
  }, [errors, touchedFields]);

  return (
    <Modal.Main open={showMapFields} className="modal--match-fields">
      <Modal.Header title="Map your fields" onClose={onClose} />
      <ErrorMessagePopUp
        show={errorPopUp}
        onClose={() => setErrorPopUp(false)}
        message="Complete all the red fields to save & finish mapping"
      />
      <Modal.Body className="px-0 pt-0 pb-8 relative">
        {errorPopUp && <ErrorPopUpBackdrop />}
        <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
          <div
            className="overflow-y-auto scroll-smooth px-12 pt-3"
            ref={parentRef}
          >
            <div style={{ maxHeight: 300 }}>
              <div className="flex">
                <ListHeader title="Reckonsys questions" className="w-3/6" />
                <ListHeader
                  title="Facebook Lead Ads fields"
                  className="w-3/6 pl-3"
                />
              </div>
              {!isEmpty(adsFormFields) &&
                !isEmpty(adsFormQuestionFields) &&
                adsFormQuestionFields?.map((item, index) => {
                  const { label, key } = item;
                  return (
                    <div className="flex mt-4 gap-5" key={index}>
                      <FieldListItem name={label} />
                      <div
                        className="relative w-3/6"
                        ref={nodeRef}
                        onClick={() =>
                          handleScrollIntoview(`dropDownId-${index}`, 'start')
                        }
                      >
                        <Select
                          id={`dropDownId-${index}`}
                          inputClassName={
                            isEmpty(errors[key]) && !isEmpty(getValues(key))
                              ? 'input-field--selected'
                              : 'input-field--md'
                          }
                          placeholder="Select a field"
                          options={adsFormFields || []}
                          name={key}
                          control={control}
                          setValue={setValue}
                          //   selectedValue={getValues(item)}
                          error={!isEmpty(errors[key])}
                          showGreenBorder={
                            isEmpty(errors[key]) && !isEmpty(getValues(key))
                          }
                          required
                          dropDownClassName="dropdown-menu__map-fields"
                          resetValue={resetValue}
                          isModalOpen={showMapFields}
                        />
                      </div>
                    </div>
                  );
                })}
            </div>
            <div className="mt-5">
              {!isEmpty(callbackError) &&
                callbackError.map((err) => (
                  <ErrorMessage className="mb-5" message={err} key={err} />
                ))}
            </div>
          </div>
          <div className="flex items-center justify-end gap-3 px-12 pt-6">
            <CustomColorButton
              label="Cancel"
              className="btn--secondary btn--md min-w-120"
              onClick={onClose}
            />
            <CustomColorButton
              type="submit"
              label="Save & finish"
              className="btn--green btn--md btn--wide-20 min-w-120"
              isLoading={isLoading}
              enableLoader={isLoading}
              loaderInline={true}
            />
          </div>
        </form>
      </Modal.Body>
    </Modal.Main>
  );
};

FbLeadAdsMapFields.propTypes = {
  showMapFields: PropTypes.bool,
  onClose: PropTypes.func
};

export default FbLeadAdsMapFields;
