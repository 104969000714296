/* eslint-disable react/no-unescaped-entities */
import React, { useState, useMemo } from 'react';
import Modal from 'components/modal';
import CustomColorButton from 'components/buttons/customColorButton';
import CustomColorButtonWithLogo from 'components/buttons/customColorButtonWithLogo';
import choices from 'choices';
import { getCurrencyText } from 'utils/currency';
import { getMyInfo } from 'slices/myInfoSlice';
import { deleteSubscriptionAccount } from 'slices/subscription/slice';
import { useDispatch, useSelector } from 'react-redux';
import PaymentModalLogout from 'components/paymentModalLogOut';

const { STARTER } = choices.PlanType;

const ExportData = ({ open, title, onShowPlan }) => {
  const [isLoading, setIsLoading] = useState(false);
  const { subscriptionPlans, currentPlan } = useSelector(
    (state) => state.subscription
  );
  const dispatch = useDispatch();
  const { myInformation } = useSelector((state) => state.myInfo);
  const starter_plan = useMemo(() => {
    const plan = subscriptionPlans.find((plan) => plan.plan_type == STARTER);
    return plan;
  }, [subscriptionPlans]);

  const handleCallback = () => {
    dispatch(getMyInfo());
    setIsLoading(false);
  };
  const handleOnCancel = () => {
    setIsLoading(true);
    let payload = {
      delete_now: true
    };
    dispatch(deleteSubscriptionAccount(payload, handleCallback));
  };

  return (
    <Modal.Main className="modal--upgrade-notification" open={open}>
      <div className="modal__body px-11 py-10 relative">
        <PaymentModalLogout />
        <h1 className="text-25 font-bold text-center">{title}</h1>
        <div className="mt-5 text-center px-6">
          <p className="text-lg leading-6">
            Thank you for giving reckonsys a try.
          </p>
          <p className="text-lg leading-6">
            We hope to see you again in the future
          </p>
          <p className="text-lg leading-6 mt-6">
            We have sent you an email with a link to download all the data from
            your account.
          </p>
          {/* <p className="text-lg leading-6 mt-6">
            Please ensure you export your data below. <br></br>
            <span className="font-semibold">
              It will not be available again.
            </span>
          </p> */}
        </div>
        {myInformation?.is_account_delete_initiated && (
          <div className="bg-grey-90 border-radius-14 py-4 pb-5 px-6 mt-6">
            <p className="text-lg leading-6" style={{ textAlign: 'center' }}>
              Your Account deletion in progress,
              <br /> it may take some time.
            </p>
          </div>
        )}
        {myInformation?.is_account_delete_initiated === false && (
          <div className="bg-grey-90 border-radius-14 py-4 pb-5 px-6 mt-6">
            <p className="text-lg font-normal text-center">
              Your account will be automatically deleted in 30 days, to delete
              now click the button below
            </p>
            <div className="mt-4">
              <CustomColorButton
                label="Delete my account"
                className="bg-white font-bold text-lg w-full cursor-pointer relative"
                style={{
                  color: '#F03636',
                  fontWeight: '400',
                  border: '1px solid #F03636',
                  padding: '10px 18px'
                }}
                isLoading={isLoading}
                enableLoader
                loaderStyle={{ marginLeft: 'none' }}
                onClick={handleOnCancel}
              />
              {/* <button
                className="btn text-white text-lg font-bold bg-green-dark w-full"
                style={{ fontWeight: '700' }}
              >
                <div
                  className="mr-3"
                  style={{
                    border: '1px dotted #CE4961',
                    padding: '2px',
                    marginTop: 2
                  }}
                >
                  <svg width="16" height="16" viewBox="0 0 16 16" fill="none">
                    <path
                      d="M8 11.1111V1"
                      stroke="#fff"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M14.5 11.1113C14.5 12.7067 13.2065 14.0002 11.6111 14.0002H4.38889C2.7935 14.0002 1.5 12.7067 1.5 11.1113"
                      stroke="#fff"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M11.6116 7.5L7.99978 11.1118L4.38867 7.5"
                      fill="#fff"
                    />
                    <path
                      d="M11.6116 7.5L7.99978 11.1118L4.38867 7.5H11.6116Z"
                      stroke="#fff"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </div>
                Click here to export
              </button> */}
            </div>
            <CustomColorButtonWithLogo
              label={`See our plans, starting from ${getCurrencyText(
                currentPlan?.plan?.currency
              )}${starter_plan?.amount}`}
              className="w-full text-white text-lg font-bold bg-green-dark mt-4 cursor-pointer"
              style={{ fontWeight: '700' }}
              onClick={onShowPlan}
            />
          </div>
        )}
      </div>
    </Modal.Main>
  );
};

export default ExportData;
