/* eslint-disable no-unused-vars */
import React from 'react';
import CustomColorButton from 'components/buttons/customColorButton';
import SetUpAside from 'modules/toolbox/component/setupAside';
import DescriptionAside from 'modules/toolbox/component/descriptionAside';
import ToolboxVideoContent from 'modules/toolbox/component/videoContent';
import EnquiryBotSettings from 'modules/toolbox/component/EnquiryBotSettings';
import { useSelector } from 'react-redux';
import EnquiryBotDescription from './description';

const EnquiryBot = ({
  setShowCancelModal,
  setIsConnected,
  isConnected,
  activeTab,
  setActiveTab
}) => {
  const { myInformation } = useSelector((state) => state.myInfo);

  const handleTabClick = (index) => {
    setActiveTab(index);
  };
  const pricingFeatures = [
    'Custom built intelligent bot',
    'Advanced question types',
    'Advanced enquiry routing',
    'Fully managed service',
    '48 hour turn around time',
    'Seamless Reckonsys integration'
  ];

  return (
    <>
      <div className="toolbox__main overflow-overlay">
        {/* {isConnected && (
          <div className="tabs-wrapper">
            <div
              className={`tabs-header ${
                activeTab === 0 ? 'tabs-header__active' : ''
              }`}
              onClick={() => handleTabClick(0)}
            >
              Description
            </div>
            <div
              className={`tabs-header ${
                activeTab === 1 ? 'tabs-header__active' : ''
              }`}
              onClick={() => handleTabClick(1)}
            >
              Setup &amp; settings
            </div>
          </div>
        )} */}
        {/* {activeTab === 0 && <ToolboxVideoContent />} */}
        {/* <ToolboxVideoContent src="https://player.vimeo.com/video/863512554?h=a3935566d3" /> */}
        <EnquiryBotDescription />
        {/* {activeTab === 1 && isConnected && <EnquiryBotSettings />} */}
      </div>
      <div className="toolbox__aside overflow-overlay">
        {/* {activeTab === 0 && ( */}
        <>
          <div>
            <h3 className="text-xl font-bold">Contact sales for pricing</h3>
            <p className="text-13 leading-5 mt-3">
              Prices are based on the bot complexity and the volume of website
              traffic received.
            </p>
            <ul className="mt-3">
              {pricingFeatures.map((item, index) => {
                return (
                  <li
                    className="flex items-center text-13 letterspacing24em mt-1"
                    key={index}
                  >
                    <code
                      className="inline-block bg-grey-900 rounded-full mr-2"
                      style={{ width: 3, height: 2 }}
                    ></code>
                    {item}
                  </li>
                );
              })}
            </ul>
            {!myInformation?.is_any_ebot_connected && (
              <CustomColorButton
                label="Schedule a demo"
                className="bg-blue-dark text-white text-sm mt-6 w-full"
                onClick={() => {
                  // setIsConnected(true);
                  // setActiveTab(1);
                }}
                isDisable={true}
              />
            )}
          </div>
          <div className="mt-8">
            <DescriptionAside helpGuideUrl="https://help.reckonsys.io/en/articles/7868107-turn-website-visitors-into-customers-with-enquirybot" />
          </div>
        </>
        {/* )} */}
        {/* {activeTab === 1 && (
          <SetUpAside setShowCancelModal={setShowCancelModal} />
        )} */}
      </div>
    </>
  );
};

export default EnquiryBot;
