/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useState } from 'react';
import InputField from 'components/inputs/textField';
import Select from 'components/inputs/select';
import PrimaryButton from 'components/buttons/primary';
import CustomColorButton from 'components/buttons/customColorButton';
import Error from 'components/errorMessage';
import { useForm } from 'react-hook-form';
// import iconEdit from 'assets/icons/icon-edit-primary-color.svg';
import iconEmail from 'assets/icons/icon-email-grey-700.svg';
import iconPhone from 'assets/icons/icon-phone.svg';
import iconSmiles from 'assets/icons/icon-fresh-smiles.svg';
import iconGlobe from 'assets/icons/icon-globe.svg';
import iconMap from 'assets/icons/icon-map.svg';
import iconUpload from 'assets/icons/icon-upload-image.svg';
import iconClose from 'assets/icons/Group35951.svg';
import fileIcon from 'assets/icons/fileIcon.svg';
import { invalidMsg, requiredMsg } from 'utils/messages';
import { object, string } from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import CustomToolTip from 'components/CustomTooltip';
import APIRequest from 'apiRequest';
import {
  FILE_UPLOAD,
  GET_ACTIVE_SIGNATURE,
  GET_DEFAULT_SIGNATURE,
  GET_CUSTOM_SIGNATURE,
  UPDATE_SIGNATURE
} from 'urls';
import { replaceUrl } from 'utils/urlReplace';
import CHOICES from 'choices';
import { Editor } from '@tinymce/tinymce-react';
import { useSnackbar } from 'components/Snackbar';
import { getFormSchema } from 'components/fieldsAndValidation';
import { isEmpty, isPhoneValid } from 'utils/utils';
import OverLayLoader from 'components/loader/overLayLoader';
import ImageResizer from 'components/imageResizer';
import { Loader } from 'components/loader';

const { FileUploadingChoices, EmailSignatureChoice } = CHOICES;

const MAX_HEIGHT = 1000;
const MAX_WIDTH = 450;

const getMeta = (url, cb) => {
  const img = new Image();
  img.onload = () => cb(null, img);
  img.onerror = (err) => cb(err);
  img.src = url;
};

const EmailSignature = () => {
  const [activeTab, setActiveTab] = useState(EmailSignatureChoice.DEFAULT);
  const [isHover, setIsHover] = useState({});
  const [attachmentsId, setAttachmentsId] = useState([]);
  const [imageUrls, setImageUrls] = useState([]);
  const [attachments, setAttachments] = useState([]);
  const [imageError, setImageError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [font, setFont] = useState('');
  const [defaultSignature, setDefaultSignature] = useState(null);
  const [customSignature, setCustomSignature] = useState(null);
  const [, setEditorRef] = useState(null);
  const [message, setMessage] = useState(null);
  const fileUploadRef = useRef();
  const { openSuccessSnackBar, openErrorSnackBar } = useSnackbar();
  const [phone, setPhone] = useState('');
  const [isDataSubmitLoading, setIsDataSubmitedLoading] = useState(false);
  const [imageSizes, setImageSizes] = useState({});

  useEffect(() => {
    getDefaultSignature();
    getCustomSignature();
  }, []);

  const formSchema = {
    ...getFormSchema(['fullName']),
    email: string().nullable().email(invalidMsg('Email')),
    // phone: string().nullable(),
    businessName: string().nullable(),
    websiteUrl: string().nullable(),
    address: string().nullable(),
    companyName: string().nullable(),
    signOffMessage: string().required(requiredMsg('Sign off message'))
  };

  const {
    register,
    handleSubmit,
    reset,
    watch,
    getValues,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(object().shape(formSchema)),
    mode: 'all'
  });

  const fontStyleOptions = [
    { id: 0, label: 'Times', value: 'times' },
    { id: 1, label: 'Courier', value: 'courier' },
    { id: 2, label: 'Arial', value: 'arial' }
  ];

  const onSubmit = (data) => {
    setIsDataSubmitedLoading(true);
    const payload = {
      is_active: true,
      message: message,
      sign_off_message: data.signOffMessage,
      font: font,
      full_name: data.fullName,
      job_title: data.businessName,
      email: data.email,
      phone_number: phone,
      company: data.companyName,
      website: data.websiteUrl,
      address: data.address,
      signature_type: activeTab,
      attachments: data?.attachmentsIds || [...attachmentsId],
      image_details: data?.image_details || JSON.stringify(imageSizes)
      // image_details: `{}`
    };
    updateDefaultSignature(payload, defaultSignature.id);
  };

  const onSubmitCustom = () => {
    const payload = {
      is_active: true,
      message: message,
      signature_type: activeTab
    };
    updateDefaultSignature(payload, customSignature.id);
  };

  // const getActiveSignature = async () => {
  //   return new APIRequest()
  //     .get(GET_ACTIVE_SIGNATURE)
  //     .then((res) => {
  //       const temp = res.data;
  //       setActiveTab(temp.signature_type);
  //       // if (EmailSignatureChoice.DEFAULT === temp.signature_type) {
  //       //   reset({
  //       //     signOffMessage: temp.sign_off_message,
  //       //     fullName: temp.full_name,
  //       //     message: temp.message,
  //       //     businessName: temp.job_title,
  //       //     email: temp.email,
  //       //     phone: temp.phone_number,
  //       //     companyName: temp.company,
  //       //     websiteUrl: temp.website,
  //       //     address: temp.address,
  //       //   });
  //       //   if (Array.isArray(temp.attachments)) {
  //       //     setAttachmentsId(temp.attachments.map(item => item.id));
  //       //     setAttachments(temp.attachments);
  //       //   }
  //       //   setFont(temp.font)
  //       // }
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // };

  const getDefaultSignature = async () => {
    return new APIRequest()
      .get(GET_DEFAULT_SIGNATURE)
      .then((res) => {
        setDefaultSignature(res.data);
        const temp = res.data;
        if (EmailSignatureChoice.DEFAULT === temp.signature_type) {
          reset({
            signOffMessage: temp.sign_off_message,
            fullName: temp.full_name,
            message: temp.message,
            businessName: temp.job_title,
            email: temp.email,
            // phone: temp.phone_number,
            companyName: temp.company,
            websiteUrl: temp.website,
            address: temp.address
          });
          setPhone(temp.phone_number);
          if (Array.isArray(temp.attachments)) {
            setImageSizes(
              typeof temp.image_details == 'string'
                ? JSON.parse(temp.image_details)
                : {}
            );
            setAttachmentsId(temp.attachments.map((item) => item.id));
            setAttachments(temp.attachments);
          }
          setFont(temp.font);
        }
        if (temp.is_active) {
          setActiveTab(EmailSignatureChoice.DEFAULT);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getCustomSignature = async () => {
    return new APIRequest()
      .get(GET_CUSTOM_SIGNATURE)
      .then((res) => {
        setCustomSignature(res.data);
        setMessage(res.data.message);
        if (res.data.is_active) {
          setActiveTab(EmailSignatureChoice.CUSTOM);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const updateDefaultSignature = async (payload, signatureId) => {
    return new APIRequest()
      .patch(replaceUrl(UPDATE_SIGNATURE, 'signId', signatureId), payload)
      .then(() => {
        openSuccessSnackBar(' Signature saved', 'large');
        setIsDataSubmitedLoading(false);
        setIsLoading(false);
      })
      .catch((err) => {
        openErrorSnackBar('Somthing went wrong', 'large');
        setIsLoading(false);
        setIsDataSubmitedLoading(false);
        console.log(err);
      });
  };

  const onFileSelect = (file) => {
    setIsLoading(true);
    const formData = new FormData();
    formData.append('file', file);
    formData.append('uploading_type', FileUploadingChoices.EMAIL_OUTGOING);
    const tempFile = file;
    const forScope = fileUploadRef.current;
    new APIRequest()
      .post(FILE_UPLOAD, formData, {
        'Content-Type': 'multipart/form-data'
      })
      .then((res) => {
        setIsLoading(false);
        setAttachmentsId((state) => [...state, res.data.attachment_id]);
        setImageUrls((state) => [
          ...state,
          { id: res.data.attachment_id, imageUrl: res.data.imageUrl }
        ]);
        // setAttachmentsId((state) => [...state, res.data.attachment_id]);
        setAttachments((state) => [...state, tempFile]);
        const formData = getValues();
        let imgWidth = 100;
        let imgHeight = 100;
        getMeta(res.data.imageUrl, (err, img) => {
          if (img) {
            imgWidth = img.naturalWidth;
            imgHeight = img.naturalHeight;

            if (imgWidth > MAX_WIDTH) {
              imgWidth = MAX_WIDTH;
            }
            if (imgHeight > MAX_HEIGHT) {
              imgHeight = MAX_HEIGHT;
            }
          }
          if (!isEmpty(formData)) {
            onSubmit({
              formData,
              attachmentsIds: [...attachmentsId, res.data.attachment_id],
              image_details: JSON.stringify({
                ...imageSizes,
                [res.data.attachment_id]: { width: imgWidth, height: imgHeight }
              })
            });
          }
          forScope.value = null;
        });
      })
      .catch(() => {
        setIsLoading(false);
        forScope.value = null;
      });
  };

  const sanitizeFile = (event) => {
    if (isLoading) return;
    const { target } = event;
    fileUploadRef.current = target;
    const file = target.files[0];
    if (!file) return;
    const fileSize = file.size / 1024;
    if (fileSize > 500) {
      setImageError(true);
      return;
    }
    setImageError(false);
    onFileSelect(file);
  };

  const onRemoveAttachment = (index, fileId) => {
    setIsLoading(true);
    const tempIndex = index;
    setAttachments((state) => {
      const temp = [...state];
      temp.splice(tempIndex, 1);
      return temp;
    });
    const sliceAttachmentsIds = () => {
      const temp = [...attachmentsId];
      temp.splice(tempIndex, 1);
      return temp;
    };
    const imageStyles = { ...imageSizes };
    delete imageStyles[fileId];
    setImageSizes(imageStyles);
    setAttachmentsId(sliceAttachmentsIds());
    const formData = getValues();

    if (!isEmpty(formData)) {
      onSubmit({ formData, attachmentsIds: sliceAttachmentsIds() });
    }
  };

  const handleChange = (value) => {
    setMessage(value);
  };

  const handlePhoneNum = (value) => {
    if (isPhoneValid(value) == true) {
      setPhone(value);
    }
  };

  function onImageSizeChange(id, style) {
    setImageSizes((prevState) => ({ ...prevState, [id]: style }));
  }

  return (
    <>
      <div className="text-center">
        <h2 className="text-2xl font-semibold">Email signature</h2>
        <p className="text-base-1 text-grey-800 mt-2">
          This signature will be added when replying by email
        </p>
      </div>
      <div className="card-box p-6 mt-5 relative">
        {/* <div className="flex items-center justify-between">
          <h3 className="text-lg font-semibold">Email signature</h3>
          <button
            className="btn btn--outline btn--sm text-13"
            style={{ padding: '5px 10px' }}setImageUrls
          >
            <img src={iconEdit} alt="icon-edit" className="mr-1" />
            Edit
          </button>
        </div> */}
        <div className="tab-wrapper mt-5">
          <div className="tab__header">
            <div
              className={`tabs-header ${
                activeTab === EmailSignatureChoice.DEFAULT
                  ? 'tabs-header__active'
                  : ''
              }`}
              onClick={() => setActiveTab(EmailSignatureChoice.DEFAULT)}
            >
              Default
            </div>
            <div
              className={`tabs-header ${
                activeTab === EmailSignatureChoice.CUSTOM
                  ? 'tabs-header__active'
                  : ''
              }`}
              onClick={() => setActiveTab(EmailSignatureChoice.CUSTOM)}
            >
              Custom
            </div>
          </div>
          <div className="tab__body mt-8">
            {activeTab === EmailSignatureChoice.DEFAULT && (
              <div className="tab__body__content">
                <div className="relative card-box p-6">
                  {watch('signOffMessage') && (
                    <span
                      className="text-13 font-medium text-grey-800"
                      style={{ fontFamily: font ? font : undefined }}
                    >
                      {watch('signOffMessage')}
                    </span>
                  )}
                  <div
                    className="mt-2"
                    style={{ fontFamily: font ? font : undefined }}
                  >
                    {watch('fullName') ? (
                      <h4 className="font-semibold">{watch('fullName')}</h4>
                    ) : (
                      ''
                    )}
                    {watch('businessName') ? (
                      <span className="text-13 text-grey-800 mt-1">
                        {watch('businessName')}
                      </span>
                    ) : (
                      ''
                    )}
                  </div>
                  <ul style={{ fontFamily: font ? font : undefined }}>
                    {watch('email') ? (
                      <li className="flex items-center mt-2">
                        <div style={{ width: 25 }}>
                          <img
                            src={iconEmail}
                            alt="icon-email"
                            style={{ width: 13, height: 11 }}
                          />
                        </div>

                        <span className="text-sm">{watch('email')}</span>
                      </li>
                    ) : (
                      ''
                    )}
                    {phone ? (
                      <li className="flex items-center mt-2">
                        <div style={{ width: 25 }}>
                          <img src={iconPhone} alt="icon-email" />
                        </div>
                        <span className="text-sm">{phone}</span>
                      </li>
                    ) : (
                      ''
                    )}
                    {watch('companyName') ? (
                      <li className="flex items-center mt-2">
                        <div style={{ width: 25 }}>
                          <img src={iconSmiles} alt="icon-email" />
                        </div>
                        <span className="text-sm">{watch('companyName')}</span>
                      </li>
                    ) : (
                      ''
                    )}
                    {watch('websiteUrl') ? (
                      <li className="flex items-center mt-2">
                        <div style={{ width: 25 }}>
                          <img src={iconGlobe} alt="icon-email" />
                        </div>
                        <span className="text-sm">{watch('websiteUrl')}</span>
                      </li>
                    ) : (
                      ''
                    )}
                    {watch('address') ? (
                      <li className="flex items-center mt-2">
                        <div style={{ width: 25 }}>
                          <img src={iconMap} alt="icon-email" />
                        </div>
                        <span className="text-sm">{watch('address')}</span>
                      </li>
                    ) : (
                      ''
                    )}
                    {attachments.map((item, index) => {
                      let url = '';
                      if (item.file_url) {
                        url = item.file_url;
                      } else {
                        const tempId = attachmentsId[index];
                        const image = imageUrls.find(
                          (element) => element.id === tempId
                        );
                        url = image.imageUrl;
                      }
                      return (
                        <li key={index} className="mt-3">
                          <ImageResizer
                            id={item.id || attachmentsId[index]}
                            src={url}
                            onSizeChange={onImageSizeChange}
                            imageSizes={imageSizes}
                          />
                          {/* <img
                            src={url}
                            height="150"
                            className="object-contain mb-3 h-40"
                          /> */}
                        </li>
                      );
                    })}
                  </ul>
                  <div
                    className="flex items-center justify-center absolute -top-4 left-0 right-0 mx-auto text-grey-800 text-11 font-semibold uppercase bg-grey-400"
                    style={{
                      width: 84,
                      padding: '5px 8px',
                      borderRadius: 20,
                      boxShadow: ' inset 0px 0px 0px 1px #FFFFFF'
                    }}
                  >
                    Preview
                  </div>
                </div>
                <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
                  <div className="mt-6">
                    <div className="flex items-center justify-between flex-wrap -mx-3">
                      <div className="w-6/12 px-3 mb-4">
                        <label className="input-field-label">
                          Sign off message
                        </label>
                        <InputField
                          type="text"
                          className="input-field input-field--md-38"
                          name="signOffMessage"
                          placeholder=""
                          register={register}
                        />
                        <div className="mt-1 ml-1">
                          {errors.signOffMessage && (
                            <Error
                              className="flex"
                              message={errors.signOffMessage.message}
                            />
                          )}
                        </div>
                      </div>
                      <div className="w-6/12 px-3 mb-4">
                        <label className="input-field-label">Font</label>
                        <Select
                          name="font"
                          className="input-field input-field--select input-field--md-38"
                          options={fontStyleOptions}
                          inputClassName="input-field--xs-34"
                          dropDownClassName="p-2"
                          placeholder="select"
                          isSearchInsideDropDown={true}
                          selectedAnswer={font}
                          onChange={({ label }) => {
                            setFont(label);
                          }}
                        />
                        <div className="mt-1 ml-1">
                          {errors.fontStyle && (
                            <Error
                              className="flex"
                              message={errors.fontStyle.message}
                            />
                          )}
                        </div>
                      </div>
                      <div className="w-6/12 px-3 mb-4">
                        <label className="input-field-label">Full name</label>
                        <InputField
                          type="text"
                          className="input-field input-field--md-38"
                          name="fullName"
                          placeholder=""
                          register={register}
                        />
                        <div className="mt-1 ml-1">
                          {errors.fullName && (
                            <Error
                              className="flex"
                              message={errors.fullName.message}
                            />
                          )}
                        </div>
                      </div>
                      <div className="w-6/12 px-3 mb-4">
                        <label className="input-field-label">Job title</label>
                        <InputField
                          type="text"
                          className="input-field input-field--md-38"
                          name="businessName"
                          placeholder=""
                          register={register}
                        />
                        <div className="mt-1 ml-1">
                          {errors.businessName && (
                            <Error
                              className="flex"
                              message={errors.businessName.message}
                            />
                          )}
                        </div>
                      </div>
                      <div className="w-6/12 px-3 mb-4">
                        <label className="input-field-label">Email</label>
                        <InputField
                          type="email"
                          className="input-field input-field--md-38"
                          name="email"
                          placeholder=""
                          register={register}
                        />
                        <div className="mt-1 ml-1">
                          {errors.email && (
                            <Error
                              className="flex"
                              message={errors.email.message}
                            />
                          )}
                        </div>
                      </div>
                      <div className="w-6/12 px-3 mb-4">
                        <label className="input-field-label">Phone</label>
                        <InputField
                          type="text"
                          name="phone"
                          placeholder=""
                          className="input-field input-field--md-38"
                          // register={register}
                          value={phone}
                          onChange={(e) => handlePhoneNum(e.target.value)}
                        />
                        {/* <div className="mt-1 ml-1">
                          {errors.phone && (
                            <Error
                              className="flex"
                              message={errors.phone.message}
                            />
                          )}
                        </div> */}
                      </div>
                      <div className="w-6/12 px-3 mb-4">
                        <label className="input-field-label">Company</label>
                        <InputField
                          type="text"
                          className="input-field input-field--md-38"
                          name="companyName"
                          placeholder=""
                          register={register}
                        />
                        <div className="mt-1 ml-1">
                          {errors.companyName && (
                            <Error
                              className="flex"
                              message={errors.companyName.message}
                            />
                          )}
                        </div>
                      </div>
                      <div className="w-6/12 px-3 mb-4">
                        <label className="input-field-label">Website</label>
                        <InputField
                          type="text"
                          className="input-field input-field--md-38"
                          name="websiteUrl"
                          placeholder=""
                          register={register}
                        />
                        <div className="mt-1 ml-1">
                          {errors.websiteUrl && (
                            <Error
                              className="flex"
                              message={errors.websiteUrl.message}
                            />
                          )}
                        </div>
                      </div>
                      <div className="w-full px-3 mb-4">
                        <label className="input-field-label">Address</label>
                        <InputField
                          type="text"
                          className="input-field input-field--md-38"
                          name="address"
                          placeholder=""
                          register={register}
                        />
                        <div className="mt-1 ml-1">
                          {errors.address && (
                            <Error
                              className="flex"
                              message={errors.address.message}
                            />
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="">
                      <label className="text-lg font-semibold">Logo</label>
                      <div className="flex items-center gap-4 mt-3 relative">
                        <div className="file-upload-wrapper">
                          <InputField
                            type="file"
                            ref={fileUploadRef}
                            accept="image/apng, image/avif, image/jpeg, image/png, image/webp"
                            name="picture"
                            className="file-upload__input disabled"
                            placeholder=""
                            onChange={(e) => {
                              sanitizeFile(e);
                            }}
                            id="uploadSignature"
                            disabled={isLoading}
                          />
                          <label
                            htmlFor="uploadSignature"
                            className="file-upload__signature py-1 px-3"
                          >
                            <img
                              src={iconUpload}
                              alt="file-upload"
                              className="mr-2"
                            />
                            <span className="text-primary text-13 font-medium">
                              Upload image
                            </span>
                          </label>
                        </div>
                        {isLoading && (
                          <div
                            style={{ top: -7, position: 'absolute', left: 123 }}
                          >
                            <Loader loaderInline={true} smaller />
                          </div>
                        )}
                        {!isLoading && (
                          <span className="text-grey-600 text-13 font-medium">
                            Max - 500kb
                          </span>
                        )}
                      </div>
                      {imageError && (
                        <Error
                          className="flex"
                          message="Image is too large. Max file size 500kb"
                        />
                      )}
                      {/* {upladedImage && false && (
                        <div
                          className={` ${isHover ? 'bg-grey-90' : ''
                            } relative  border border-grey-400 border-radius-10 p-1 mt-3 cursor-pointer`}
                          style={{ width: 278, height: 62 }}
                          onMouseEnter={() => setIsHover(true)}
                          onMouseLeave={() => setIsHover(false)}
                        >
                          <div className="flex items-center h-full">
                            <figure
                              className="h-full flex items-center justify-center flex-shrink-0 p-1 bg-grey-200 rounded-md"
                              style={{ width: 54 }}
                            >
                              <img src={fileIcon} alt="signature pic" />
                            </figure>
                            <div className="flex flex-col ml-3 pr-3 truncate">
                              <h5 className="text-sm font-medium truncate">
                                Sensu logo1.png
                              </h5>
                              <p className="text-xs text-grey-700 truncate">
                                PNG
                              </p>
                            </div>
                            {isHover && (
                              <div
                                className="absolute"
                                style={{ right: '-10px', top: '-10px' }}
                              >
                                <CustomToolTip
                                  position="top"
                                  tooltipText="Remove"
                                >
                                  <img
                                    src={iconClose}
                                    alt="icon-delete"
                                    className="cursor-pointer flex justify-end"
                                  />
                                </CustomToolTip>
                              </div>
                            )}
                          </div>
                        </div>
                      )} */}
                      {attachments.map((item, index) => {
                        let fileExtension = '';

                        if (item && (item.name || item.file_name)) {
                          let temp = item.name
                            ? item.name.split('.')
                            : item.file_name.split('.');
                          temp = temp[temp.length - 1];
                          fileExtension = temp ? temp : '';
                        }

                        return (
                          <div
                            key={index}
                            className={` ${
                              isHover[index] ? 'bg-grey-90' : ''
                            } relative  border border-grey-400 border-radius-10 p-1 mt-3 cursor-pointer`}
                            style={{ width: 278, height: 62 }}
                            onMouseEnter={() =>
                              setIsHover((state) => ({
                                ...state,
                                [index]: true
                              }))
                            }
                            onMouseLeave={() =>
                              setIsHover((state) => ({
                                ...state,
                                [index]: false
                              }))
                            }
                          >
                            <div className="flex items-center h-full">
                              <figure
                                className="h-full flex items-center justify-center flex-shrink-0 p-1 bg-grey-200 rounded-md"
                                style={{ width: 54 }}
                              >
                                <img src={fileIcon} alt="signature pic" />
                              </figure>
                              <div className="flex flex-col ml-3 pr-3 truncate">
                                <h5 className="text-sm font-medium truncate">
                                  {typeof item === 'string'
                                    ? item
                                    : item.name
                                    ? item.name
                                    : item.file_name}
                                </h5>
                                <p className="text-xs text-grey-700 truncate uppercase">
                                  {fileExtension}
                                </p>
                              </div>
                              {isHover[index] && (
                                <div
                                  className="absolute"
                                  style={{ right: '-10px', top: '-10px' }}
                                  onClick={() =>
                                    onRemoveAttachment(index, item.id)
                                  }
                                >
                                  <CustomToolTip
                                    position="top"
                                    tooltipText="Remove"
                                  >
                                    <img
                                      src={iconClose}
                                      alt="icon-delete"
                                      className="cursor-pointer flex justify-end"
                                    />
                                  </CustomToolTip>
                                </div>
                              )}
                            </div>
                          </div>
                        );
                      })}
                    </div>
                    <div className="mt-1 ml-1">
                      {errors.picture && (
                        <Error
                          className="flex"
                          message={errors.picture.message}
                        />
                      )}
                    </div>
                    <div className="flex items-center justify-end gap-3 mt-8">
                      {/* <CustomColorButton
                        label="Cancel"
                        className="btn--grey btn--md min-w-120"
                        onClick={(e) => {
                          e.preventDefault();
                          reset();
                        }}
                      /> */}
                      <PrimaryButton
                        label="Save"
                        className="btn btn--primary btn--md min-w-120"
                        isLoading={isDataSubmitLoading}
                        disabled={isDataSubmitLoading}
                        enableLoader={isDataSubmitLoading}
                      />
                    </div>
                  </div>
                </form>
              </div>
            )}
            {activeTab === EmailSignatureChoice.CUSTOM && (
              <div className="tab__body__content">
                <Editor
                tinymceScriptSrc="https://tinymce.enquirybox.io/tinymce/tinymce.min.js"
                  onInit={(evt, editor) => {
                    setEditorRef(editor);
                  }}
                  value={message}
                  init={{
                    license_key: 'gpl',
                    toolbar: false,
                    placeholder:
                      'Copy your signature from an email or your email provider and paste it here',
                    branding: false,
                    plugins: ['lists', 'emoticons', 'image'],
                    statusbar: false,
                    images_file_types: 'jpg,jpeg,png',
                    paste_data_images: false,
                    imagetools_cors_hosts: [
                      'localhost',
                      'reckonsys.io',
                      'amazonaws.com'
                    ],
                    imagetools_credentials_hosts: [
                      'localhost',
                      'reckonsys.io',
                      'amazonaws.com'
                    ],
                    menubar: false,
                    content_style: `
              body { font-size:12px; font-weight:300 }
              body.mce-content-body { overflow-y: auto !important }
              table { border: none !important }
              td, tbody, tr { border: none !important }
              p { margin-block-start: 0em;
                margin-block-end: 0em;
                margin-inline-start: 0px;
                margin-inline-end: 0px;}`,
                    autoresize_on_init: true,
                    height: 280
                  }}
                  onEditorChange={(newText) => {
                    handleChange(newText);
                  }}
                  apiKey={process.env.REACT_APP_TINY_EDITOR_KEY}
                />
                <div className="flex items-center justify-end gap-3 mt-8">
                  {/* <CustomColorButton
                    label="Cancel"
                    className="btn--grey btn--md min-w-120"
                    onClick={(e) => {
                      e.preventDefault();
                      reset();
                    }}
                  /> */}
                  <PrimaryButton
                    label="Save"
                    className="btn btn--primary btn--md min-w-120"
                    onClick={() => onSubmitCustom()}
                  />
                </div>
                {/* <div
                  className="relative card-box p-5"
                  style={{ borderRadius: 8 }}
                >
                  <div className="flex flex-col items-center justify-center text-center p-16">
                    <p className="text-grey-900-secondary text-sm font-medium">
                      Copy your signature from an email or your email provider
                      and paste it here
                    </p>
                    <button className="btn btn--grey btn--sm min-w-120 text-sm mt-5">
                      Paste here
                    </button>
                  </div>
                  <figure
                    className="flex items-center justify-center"
                    style={{ width: 190, height: 190 }}
                  >
                    <img
                      src={emailSignaturePng}
                      alt="email-signature"
                      className="object-contain"
                    />
                  </figure>
                  <div
                    className="flex items-center justify-center absolute -top-4 left-0 right-0 mx-auto text-grey-800 text-11 font-semibold uppercase bg-grey-400"
                    style={{
                      width: 84,
                      padding: '5px 8px',
                      borderRadius: 20,
                      boxShadow: ' inset 0px 0px 0px 1px #FFFFFF'
                    }}
                  >
                    Preview
                  </div>
                </div> */}
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default EmailSignature;
