/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import Header from '../../../../components/layout/header';
import ListHeader from '../../../../components/layout/listHeader';
import { useParams, useNavigate } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { updateWebFormData } from 'slices/webformSlice';
import {
  getAllPendingChannels,
  getAllConnectedChannels
} from 'slices/channelsSlice';
import { getMyInfo } from 'slices/myInfoSlice';
import WebFormScript from './webFormScript';
// import SetUpVideo from 'components/setUpVideo';
// import { SucessSnackBar } from 'components/Snackbar/Snackbar';
import { useSnackbar } from 'components/Snackbar';
import { useAnalytics } from 'use-analytics';
import GuideLink from 'components/guideLink';
import ChannelBack from '../back';
const PostDraft = () => {
  const { channelId } = useParams();
  const { subOrganizationId } = useSelector((state) => state.myInfo);
  const { allPendingChannels } = useSelector((state) => state.channels);
  // const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { openSuccessSnackBar } = useSnackbar();
  // const { analytics } = useSelector((state) => state.segments);
  const { track } = useAnalytics();
  const channel = allPendingChannels?.find((data) => data.id === channelId);

  const refetchChannels = () => {
    dispatch(getAllPendingChannels({ subOrgId: subOrganizationId }));
    dispatch(getAllConnectedChannels({ subOrgId: subOrganizationId }));
    dispatch(getMyInfo());
  };

  const onSuccess = (data) => {
    if (data?.connected_channel?.is_connected) {
      track(
        'channel_created',
        {
          channel_name: data?.connected_channel.name
        },
        {
          groupId: subOrganizationId
        }
      );
      openSuccessSnackBar('Channel Connected');
      refetchChannels();
      navigate(
        `/channels/webforms/4/connected/${data?.connected_channel?.id}/settings`
      );
    }
  };

  const handleChange = async (e) => {
    setIsChecked(e.target.checked);
    const formData = {
      body: {
        name: channel?.channel_name || 'Website Forms',
        is_connected: e.target.checked,
        subOrgId: subOrganizationId,
        channelId: channelId
      },
      subOrgId: subOrganizationId,
      channelId: channelId
    };
    dispatch(updateWebFormData(formData, onSuccess));
  };

  return (
    <div className="toolbox__main overflow-overlay channels">
      {/* <SuccessSnackBar isActive={isActive} message={message} /> */}
      {/* {showSuccessMessage && <SucessSnackBar message="Channel connected" />} */}
      <div className="card-container--new connect-new">
        {/* {isUpdateLoading && (
        <div className="absolute z-50 left-1/2 top-40">
          <Loader />
        </div>
      )} */}
        <ChannelBack />
        <Header
          title="Connect your Website forms"
          description="By connecting your website forms you will be able to receive and reply to form submissions,
        right here on this channel."
        />
        <div className="mt-9 pt-5 px-6 pb-6 bg-grey-300 rounded-lg border border-grey-400">
          <div className="flex justify-between border-b border-grey-400 pb-3">
            <ListHeader
              title="To connect this channel, turn on the switch 👉"
              className="font-semibold"
            />
            <div className="toggle-slider">
              <input
                type="checkbox"
                id="webformConnect"
                className="toggle-slider__checkbox"
                checked={isChecked}
                onChange={(event) => handleChange(event)}
              />
              <label htmlFor="webformConnect" className="toggle-slider__label">
                <code className="toggle-slider__ball toggle-slider__ball__small" />
              </label>
            </div>
          </div>
          <p className="mt-5 text-sm text-grey-800">
            Once turned on, copy this code and place it on the pages that you
            want to receive webform submissions from.
          </p>
          <WebFormScript />
        </div>
        <div className="mt-10">
          <ListHeader
            title="Where do I add the code?"
            className="font-semibold"
          />
          <p className="text-sm text-grey-800 mt-2">
            {
              'The easiest way is to add the code to all pages just above the </body> tag. Once added you can setup rules to only receive enquiries from certain pages on your website.'
            }
          </p>
        </div>
        <div className="mt-8">
          {/* <SetUpVideo
            src="https://player.vimeo.com/video/815688316?h=a5ac65884e&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
            articleLink="https://help.reckonsys.io/en/articles/7228336-connect-your-webforms"
          /> */}
          <GuideLink url="https://help.reckonsys.io/en/articles/7228336-connect-your-webforms" />
        </div>
      </div>
    </div>
  );
};

export default React.memo(PostDraft);
