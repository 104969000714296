import React from 'react';
import ChannelContactLink from '../../components/contactLink';
import EmptyState from 'modules/enquirybox/components/emptyState';

const ChannelContacts = () => {
  return (
    <>
      <div className="dashboard__main__body__section-left overflow-overlay">
        <ChannelContactLink title="Add a contact" plus={true} />
      </div>
      <EmptyState
        title="Reckonsys contacts"
        description="One central place to view and find all the contacts in
									reckonsys account. 😎"
      />
    </>
  );
};

export default ChannelContacts;
