/* eslint-disable no-control-regex */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/no-unknown-property */
/* eslint-disable no-unused-vars */
/* eslint-disable react/display-name */
import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import 'react-quill/dist/quill.snow.css';
import './styles.scss';
import { useSelector } from 'react-redux';
import Button from 'components/buttons/primary';
import iconClose from 'assets/icons/icon-inline-editor-close.svg';
import bold from 'assets/icons/bold.svg';
import bullet from 'assets/icons/bullet.svg';
import italic from 'assets/icons/italic.svg';
import left from 'assets/icons/left.svg';
import center from 'assets/icons/center.svg';
import justify from 'assets/icons/justify.svg';
import right from 'assets/icons/right.svg';
import ordered from 'assets/icons/ordered.svg';
import Aa from 'assets/icons/Aa.svg';
import AddContainer from 'modules/enquirybox/ebox/module/box/conversation/footer/chatFooter/template/reply/container';
import Attachment from './attachment';
import ImageUpload from './imageUpload';
// import Emoji from './emoji';
import iconSmiley from 'assets/icons/icon-smiley.svg';

import RenderAttachments from './renderAttachments';
import AttachFiles from 'modules/modals/attachFiles';

import Variables from './variables';
import { useSnackbar } from 'components/Snackbar';
import { Editor } from '@tinymce/tinymce-react';
import iconHyperLink from 'assets/icons/icon-toolbar-hyperlink.svg';
import CustomToolTip from 'components/CustomTooltip';

const CHAR_LIMIT = 160;

function inlineClose(hideReplyTemplate) {
  const subToolbar = document.getElementById('subToolbar');
  const replyTemplate = document.getElementById('replyTemplate');
  const mainToolbar = document.getElementById('main-toolbar');
  if (subToolbar && subToolbar.classList.contains('hide-toolbar')) {
    subToolbar.classList.remove('hide-toolbar');
    subToolbar.classList.add('show-toolbar');
    if (!hideReplyTemplate) {
      replyTemplate.style.display = 'none';
    }
    mainToolbar.style.display = 'none';
  } else {
    subToolbar.classList.remove('show-toolbar');
    subToolbar.classList.add('hide-toolbar');
    if (!hideReplyTemplate) {
      replyTemplate.style.display = '';
    }
    mainToolbar.style.display = '';
  }
}

/*
 * Custom toolbar component including the custom heart button and dropdowns
 */
const CustomToolbar = ({
  showFontStyle,
  setShowAttachFiles,
  onImageUpload,
  onVariableSelect,
  hideImage,
  hideAttachment,
  hideVarible,
  // quillRef,
  channelType,
  editorRef,
  hideHyperlink,
  hideReplyTemplate
}) => {
  const handleClick = () => {
    inlineClose(hideReplyTemplate);
  };

  const handleToolbar = (eventType) => {
    editorRef;
    switch (eventType) {
      case 'bold':
        editorRef.execCommand('Bold');
        break;
      case 'italic':
        editorRef.execCommand('Italic');
        break;
      case 'ordered':
        editorRef.execCommand('InsertOrderedList');
        break;
      case 'bullet':
        editorRef.execCommand('InsertUnorderedList');
        break;
      case 'left':
        editorRef.execCommand('JustifyLeft');
        break;
      case 'center':
        editorRef.execCommand('JustifyCenter');
        break;
      case 'right':
        editorRef.execCommand('JustifyRight');
        break;
      case 'justify':
        editorRef.execCommand('JustifyFull');
        break;
      default:
        break;
    }
  };

  const onEmojiClick = () => {
    editorRef.execCommand('mceEmoticons');
  };

  const onHyperLinkClick = () => {
    editorRef.execCommand('mceLink');
  };

  return (
    <div id="toolbar">
      <div
        id="main-toolbar"
        className={`flex main-toolbar justify-center items-center ${
          hideReplyTemplate ? 'ml-3' : ''
        }`}
      >
        {/* <Emoji onEmojiClick={onEmojiSelect} /> */}
        <button className="toolbar-button emoji-btn flex">
          <img
            src={iconSmiley}
            alt="icon-smiley"
            onClick={() => onEmojiClick()}
          />
        </button>
        {/* <button className="toolbar-button ql-insertImage">
          <img src={iconUploadPic} alt="icon-upload-pic" />
        </button> */}
        {!hideImage && <ImageUpload onImageUpload={onImageUpload} />}
        {!hideAttachment && <Attachment onClick={() => setShowAttachFiles()} />}
        {!hideHyperlink && (
          <button className="toolbar-button flex" style={{ marginRight: 16 }}>
            <img
              src={iconHyperLink}
              alt="icon-hyperlink"
              onClick={() => onHyperLinkClick()}
            />
          </button>
        )}
        {showFontStyle && (
          <div className="flex">
            <button onClick={handleClick} className="ql-insertStyles">
              <img src={Aa} alt="icon-attachment" />
            </button>
          </div>
        )}
        {!hideVarible && (
          <CustomToolTip
            position="top"
            tooltipText="Insert contact details or question "
          >
            <Variables
              channelType={channelType}
              // quillRef={quillRef}
              onVariableClick={onVariableSelect}
            />
          </CustomToolTip>
        )}
      </div>
      <div
        id="subToolbar"
        className="flex justify-center items-center sub-toolbar hide-toolbar"
      >
        <div>
          <span className="ql-formats sub-toolbar">
            <button
              onClick={() => handleToolbar('bold')}
              className="toolbar-btn"
            >
              <figure>
                <img src={bold} alt="icon-upload-pic" />
              </figure>
            </button>
            <button
              onClick={() => handleToolbar('italic')}
              className="toolbar-btn"
            >
              <figure>
                <img src={italic} alt="icon-upload-pic" />
              </figure>
            </button>
            <button
              onClick={() => handleToolbar('ordered')}
              className="toolbar-btn"
              value="ordered"
            >
              <figure>
                <img src={ordered} alt="icon-upload-pic" />
              </figure>
            </button>
            <button
              onClick={() => handleToolbar('bullet')}
              className="toolbar-btn"
              value="bullet"
            >
              <figure>
                <img src={bullet} alt="icon-upload-pic" />
              </figure>
            </button>
            <button
              onClick={() => handleToolbar('left')}
              className="toolbar-btn"
              value="bullet"
            >
              <figure>
                <img src={left} alt="icon-upload-pic" />
              </figure>
            </button>
            <button
              onClick={() => handleToolbar('center')}
              className="toolbar-btn"
              value="bullet"
            >
              <figure>
                <img src={center} alt="icon-upload-pic" />
              </figure>
            </button>
            <button
              onClick={() => handleToolbar('right')}
              className="toolbar-btn"
              value="bullet"
            >
              <figure>
                <img src={right} alt="icon-upload-pic" />
              </figure>
            </button>
            <button
              onClick={() => handleToolbar('justify')}
              className="toolbar-btn"
              value="bullet"
            >
              <figure>
                <img src={justify} alt="icon-upload-pic" />
              </figure>
            </button>
          </span>
        </div>
        <button onClick={handleClick} className="toolbar-button ql-inlineClose">
          <figure>
            <img src={iconClose} alt="icon-upload-pic" />
          </figure>
        </button>
      </div>
    </div>
  );
};

const QuillEditor = (props) => {
  const {
    showFontStyle,
    onChange,
    onSend,
    setAttachments,
    isLoading,
    placeholder,
    isExpanded,
    showSendBtn,
    value,
    hideImage,
    hideAttachment,
    hideVarible,
    attachments,
    isFbOrInsta,
    channelType,
    onAttachmentChanges,
    errorMessage,
    isAutomation,
    isBoxAutomation,
    isSms,
    showBottomBorder,
    hideHyperlink,
    hideReplyTemplate,
    showCustomToolbar,
    readOnly,
    isRefetch,
    enableCharacterLimit,
    showCharLimitTop,
    maxCharacters,
    setLength,
    length,
    isChannelSettings,
    onUseTemplate,
    isAutomateStuff,
    isSmsConversation,
    setSmsCount,
    setEditorRef: parentSetEditorRef,
    isAutoFocus
  } = props;
  const layoutRef = useRef();
  const attachmentRef = useRef();
  const { openSuccessSnackBar } = useSnackbar();
  const [editorHtml, setEditorHtml] = useState(value);
  const [editorRef, setEditorRef] = useState(null);
  const [showAttachFiles, setShowAttachFiles] = useState(false);
  const [uploadedFiles, setUploadedFiles] = useState(attachments);
  const [dynamicHeight, setDynamicHeight] = useState(false);
  const [quillHeight, setQuillHeight] = useState();
  const [smsCharLimit, setSmsCharLimit] = useState(0);
  const { editorChannel } = useSelector((state) => state.editor);

  useEffect(() => {
    if (onAttachmentChanges) onAttachmentChanges(uploadedFiles);
  }, [uploadedFiles]);

  useEffect(() => {
    if (editorRef && isAutoFocus) {
      editorRef.execCommand('mceFocus');
    }
  }, [editorRef, isAutoFocus]);

  const sanatizeMessageBody = (content) => {
    var temp = document.createElement('div');
    temp.innerHTML = content;
    var sanitized = temp.textContent || temp.innerText;
    temp.remove();
    return sanitized;
  };

  const getCharLimit = (content) => {
    const value = sanatizeMessageBody(content);
    setSmsCharLimit(value.length);
  };

  useEffect(() => {
    if (isSms) getCharLimit(editorHtml);
  }, [editorHtml]);

  useEffect(() => {
    if (readOnly || isRefetch) {
      setEditorHtml(value);
      setUploadedFiles(attachments);
    }
  }, [value, readOnly, isRefetch]);

  useEffect(() => {
    if (readOnly) {
      setUploadedFiles(attachments);
    }
  }, [readOnly, attachments]);

  useEffect(() => {
    // when expanded or collapse set the quill layout height acording to the attachments div
    if (attachmentRef.current && layoutRef.current && !isAutomation) {
      const layout = layoutRef.current.clientHeight;
      const attachmentHeight = attachmentRef.current.clientHeight;
      if (attachmentHeight > 0) {
        if (!isExpanded) {
          setDynamicHeight(false);
        } else {
          setDynamicHeight(true);
          setQuillHeight(layout - attachmentHeight);
        }
      }
      if (attachmentHeight <= 0) {
        setDynamicHeight(true);
        setQuillHeight(layout);
      }
    }
  }, [attachmentRef.current, layoutRef.current, uploadedFiles, isExpanded]);

  useEffect(() => {
    // setting the editor height dynamically
    if (editorRef) {
      if (dynamicHeight) {
        editorRef.container.style.height = `${quillHeight}px`;
      } else {
        if (!isBoxAutomation) {
          if (!isAutomation) {
            if (editorChannel === 'Email') {
              editorRef.container.style.height = `117px`;
            } else {
              editorRef.container.style.height = `63px`;
            }
          } else {
            editorRef.container.style.height = `300px`;
            editorRef.container.style.maxHeight = `300px`;
            editorRef.iframeElement.contentDocument.body.style.height = '236px';
          }
        }
        if (isBoxAutomation) {
          editorRef.container.style.height = `120px`;
          editorRef.container.style.maxHeight = `120px`;
          editorRef.iframeElement.contentDocument.body.style.height = '75px';
        }
      }
    }
    // const qillEditor = document.getElementsByClassName('ql-editor')[0];
    // if (qillEditor) {
    //   if (dynamicHeight) {
    //     qillEditor.style.minHeight = `${quillHeight}px`;
    //   } else {
    //     qillEditor.style.minHeight = '120px';
    //   }
    // }
  }, [quillHeight, dynamicHeight, editorRef]);

  const handleChange = (html) => {
    setEditorHtml(html);
    onChange(html);
  };

  const onVariableSelect = (variable) => {
    if (editorRef && variable) {
      editorRef.insertContent(`&nbsp;<span>{${variable.text}}</span>`);
    }
  };

  const onFileUpload = (isFileLibrary, file) => {
    if (isFileLibrary) {
      openSuccessSnackBar('File uploaded successfully', 'large');
      setUploadedFiles(file);
      setShowAttachFiles(!showAttachFiles);
      setAttachments(file);
    } else {
      openSuccessSnackBar('File uploaded successfully', 'large');
      setUploadedFiles([...uploadedFiles, ...file]);
      setShowAttachFiles(!showAttachFiles);
      setAttachments([...uploadedFiles, ...file]);
    }
  };

  const onImageUpload = (url) => {
    if (editorRef && url) {
      editorRef.insertContent(`<img src="${url}"/>`);
    }
  };

  function calculateSmsLength(message) {
    const GSM_7BIT_MAX_LENGTH = 160;
    const UNICODE_MAX_LENGTH = 70;

    // Determine the maximum length based on the character set
    function isUnicode(inputStr) {
      // Regular expression that matches non-Unicode characters and white spaces
      var pattern = /[^\u0000-\u007F\s]+/;
      // Check if the input string matches the pattern
      return pattern.test(inputStr);
    }

    const maxLength = !isUnicode(message)
      ? GSM_7BIT_MAX_LENGTH
      : UNICODE_MAX_LENGTH;

    // Calculate the length of the message and the number of SMS segments required
    const messageLength = message.length;
    const smsSegments = Math.ceil(messageLength / maxLength);

    // Return an object with the message length and number of SMS segments
    return { length: messageLength, segments: smsSegments };
  }

  const getSmsLength = (msg) => {
    const sanatizedMsg = sanatizeMessageBody(msg);
    const v = calculateSmsLength(sanatizedMsg);
    return v.length;
  };

  const getSmsCount = (msg) => {
    const sanatizedMsg = sanatizeMessageBody(msg);
    const v = calculateSmsLength(sanatizedMsg);
    if (setSmsCount) {
      setSmsCount(v.segments);
    }
    return v.segments;
  };

  return (
    <>
      <div className="text-editor relative">
        {errorMessage && <div className="tiny-error">{errorMessage}</div>}
        <div
          className={`${
            showBottomBorder
              ? `border-b-2 border-grey-400 ${
                  enableCharacterLimit ? 'mb-2' : ''
                }`
              : ''
          }`}
          style={
            readOnly
              ? { height: 'calc(100% - 42px)' }
              : isSmsConversation
              ? { height: 'calc(100% - 56px)', overflowY: 'auto' }
              : { height: 'calc(100% - 42px)', overflowY: 'auto' }
          }
          ref={layoutRef}
        >
          <Editor
            tinymceScriptSrc="https://tinymce.enquirybox.io/tinymce/tinymce.min.js"
            disabled={readOnly}
            onInit={(evt, editor) => {
              setEditorRef(editor);
              if (parentSetEditorRef) parentSetEditorRef(editor);
            }}
            value={editorHtml}
            init={{
              license_key: 'gpl',
              toolbar: false,
              placeholder: placeholder ? placeholder : '',
              branding: false,
              plugins: [
                'lists',
                'emoticons',
                'autoresize',
                'image',
                'paste',
                'link'
              ],
              statusbar: false,
              images_file_types: 'jpg,jpeg,png',
              paste_data_images: false,
              noneditable_class: 'ebox-noneditable',
              imagetools_cors_hosts: [
                'localhost',
                'enquirybox.io',
                'amazonaws.com'
              ],
              imagetools_credentials_hosts: [
                'localhost',
                'enquirybox.io',
                'amazonaws.com'
              ],
              menubar: false,
              content_style: `
              body { font-size:13px; font-weight:300, font-family: inherit }
              body.mce-content-body { overflow-y: auto !important; margin-left: 9px; margin-right: 9px; }
              p { margin-block-start: 0em;
                margin-block-end: 0em;
                margin-inline-start: 0px;
                margin-inline-end: 0px;}
              .ng-scope, .mce-item-table { border: none !important }
              .ng-scope tbody, tr, td { border: none !important }`,
              selector: 'textarea',
              autoresize_on_init: true,
              contextmenu_never_use_native: false,
              contextmenu: 'image inserttable | cell row column deletetable'
              // autoresize_bottom_margin: 1,
              // min_height:
            }}
            onEditorChange={(newText) => {
              if (enableCharacterLimit) {
                /**
                 checking for the max character limit if the
                 enableCharacterLimit is set to true
                 */
                let temp = document.createElement('div');
                temp.innerHTML = newText;
                let sanitized = temp.textContent || temp.innerText;
                // let finalText = sanitized.replace(/\s/g, '');
                handleChange(newText);
                setLength(sanitized.length);
              } else if (!enableCharacterLimit) {
                handleChange(newText);
              }
            }}
            onBeforeAddUndo={(evt, editor) => {
              if (enableCharacterLimit) {
                let temp = document.createElement('div');
                temp.innerHTML = editorHtml;
                let sanitized = temp.textContent || temp.innerText;
                // let finalText = sanitized.replace(/\s/g, '');
                setLength(sanitized.length);
                if (sanitized.length > maxCharacters) {
                  evt.preventDefault();
                  evt.stopPropagation();
                  return false;
                } else {
                  return true;
                }
              }
            }}
            apiKey={process.env.REACT_APP_TINY_EDITOR_KEY}
          />
          {!hideAttachment && (
            <div ref={attachmentRef}>
              <RenderAttachments
                setUploadedFiles={setUploadedFiles}
                files={uploadedFiles}
                isChannelSettings={isChannelSettings}
                isAutomateStuff={isAutomateStuff}
                readOnly={readOnly}
              />
            </div>
          )}
        </div>
        {showCharLimitTop && (
          <div className="editor-sms-character">
            <CustomToolTip
              position="top"
              tooltipText="One SMS is charged based on a limit of 160 Characters"
              tooltipStyle={{ width: '200px' }}
            >
              <div className="flex items-center gap-2 ">
                <p>Characters: {getSmsLength(editorHtml)}</p>
                <code
                  className="inline-block"
                  style={{
                    width: 1,
                    height: 9,
                    backgroundColor: '#E5E5E5'
                  }}
                />
                <p>SMS Count {getSmsCount(editorHtml)}</p>
              </div>
            </CustomToolTip>
          </div>
        )}
        <div
          className="toolbar-wrapper relative justify-start"
          style={{ justifyContent: 'flex-start' }}
        >
          {!hideReplyTemplate && (
            <div id="replyTemplate" className="flex ml-3">
              <AddContainer
                onUseTemplate={onUseTemplate}
                isFbOrInsta={isFbOrInsta}
                isSms={isSms}
              />
              <code
                className="inline-block ml-5 mr-3"
                style={{
                  width: 1,
                  height: 22,
                  backgroundColor: '#E5E5E5'
                }}
              />
            </div>
          )}
          {showCustomToolbar && (
            <CustomToolbar
              showFontStyle={showFontStyle}
              setShowAttachFiles={() => setShowAttachFiles(true)}
              onImageUpload={onImageUpload}
              onVariableSelect={onVariableSelect}
              hideImage={hideImage}
              hideAttachment={hideAttachment}
              hideVarible={hideVarible}
              // quillRef={quillRef}
              channelType={channelType}
              editorRef={editorRef}
              hideHyperlink={hideHyperlink}
              hideReplyTemplate={hideReplyTemplate}
            />
          )}
          {enableCharacterLimit && !showCharLimitTop && (
            <>
              {maxCharacters ? (
                maxCharacters - length >= 0 ? (
                  <p className="ml-auto text-13 text-grey-600">
                    {maxCharacters - length} Characters remaining
                  </p>
                ) : (
                  <p className="ml-auto text-red-900 text-13">
                    Characters limit exceeded
                  </p>
                )
              ) : (
                ''
              )}
            </>
          )}
          {showSendBtn && (
            <Button
              className="text-sm text-white ml-auto"
              style={{ padding: '4px 10px', borderRadius: 6 }}
              label="Send"
              onClick={onSend}
              isLoading={isLoading}
              enableLoader
              loaderInline
            />
          )}
        </div>
      </div>
      {!isFbOrInsta && (
        <AttachFiles
          onClose={() => setShowAttachFiles(!showAttachFiles)}
          open={showAttachFiles}
          onUpload={onFileUpload}
          uploadedFiles={uploadedFiles}
          setUploadedFiles={setUploadedFiles}
        />
      )}
    </>
  );
};

QuillEditor.propTypes = {
  showFontStyle: PropTypes.bool,
  showSendBtn: PropTypes.bool,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  hideImage: PropTypes.bool,
  hideAttachment: PropTypes.bool,
  onAttachmentChanges: PropTypes.func,
  hideHyperlink: PropTypes.bool,
  hideReplyTemplate: PropTypes.bool,
  showCustomToolbar: PropTypes.bool,
  readOnly: PropTypes.bool,
  isRefetch: PropTypes.bool,
  enableCharacterLimit: PropTypes.bool,
  showCharLimitTop: PropTypes.bool,
  isSmsConversation: PropTypes.bool,
  setSmsCount: PropTypes.func
};

QuillEditor.defaultProps = {
  showFontStyle: true,
  showSendBtn: true,
  placeholder: 'Reply',
  value: '',
  hideImage: false,
  hideAttachment: false,
  hideVarible: true,
  onAttachmentChanges: () => {},
  isAutomation: false,
  isBoxAutomation: false,
  isSms: false,
  hideHyperlink: true,
  hideReplyTemplate: false,
  showCustomToolbar: true,
  readOnly: false,
  isRefetch: false,
  enableCharacterLimit: false,
  showCharLimitTop: false,
  isSmsConversation: false
};

export default React.memo(QuillEditor);
