/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import useClickOutside from 'hooks/useOutsideClick';
import ButtonItem from './button';
// import { CallList } from './helper';
import { setTaskType, setTaskTypeTitle } from 'slices/task/slice';
import callIconGreen from 'assets/icons/call-green-icon.svg';
import followUpIcon from 'assets/icons/follow-up.svg';
import otherIcon from 'assets/icons/other-icon.svg';
import CustomToolTip from 'components/CustomTooltip';
import CHOICES from 'choices';

const CallList = CHOICES.TasksTypeChoices.CHOICE_LIST;
const { CALL, FOLLOW_UP, OTHERS } = CHOICES.TasksTypeChoices;

const TaskType = () => {
  const { taskTypeTitle, taskType } = useSelector((state) => state.tasks);
  const [showTaskType, setShowTaskType] = useState(false); // state for dropdown open or close

  const dispatch = useDispatch();
  const callDom = useRef();

  // to close the dropdown when user click outside
  useClickOutside(() => setShowTaskType(false), callDom.current);

  const onCallSelect = (title, value) => {
    // function when select the task type
    setShowTaskType(false);
    dispatch(setTaskType(value)); // state to store the task type eg:1,2,3
    dispatch(setTaskTypeTitle(title)); // state to store the task type title eg:call,other,follow up
  };

  const renderIcon = (type) => {
    // function to render task type icon
    switch (type) {
      case CALL:
        return callIconGreen;
      case FOLLOW_UP:
        return followUpIcon;
      case OTHERS:
        return otherIcon;
      default:
        return callIconGreen;
    }
  };

  return (
    <div ref={callDom}>
      <div
        className={`dropdown-menu dropdown-menu--sm ${showTaskType && 'open'}`}
        style={{ width: '176px', left: 'auto', bottom: '42px' }}
      >
        <div
          className="dropdown-menu__overflow-y-scroll"
          style={{ height: '100%' }}
        >
          {CallList.map((item, index) => {
            let isSelected = taskTypeTitle == item.label;
            return (
              <div
                id={`time-${item.value}`}
                key={item.value}
                className={`dropdown-menu__list ${
                  isSelected ? 'selected' : ''
                }`}
                onClick={() => onCallSelect(item.label, item.value)}
              >
                <img
                  src={renderIcon(item.value)}
                  alt=""
                  style={{
                    color: '#CE4961',
                    width: '15px',
                    height: '15px',
                    marginRight: '12px'
                  }}
                />
                {item.label}
              </div>
            );
          })}
        </div>
      </div>
      <CustomToolTip
        position="top"
        tooltipText="Task type"
        hideTooltip={showTaskType}
      >
        <ButtonItem
          active={showTaskType}
          icon={renderIcon(taskType)}
          text={taskTypeTitle}
          onClick={() => {
            setShowTaskType(true);
          }}
          // style={{ width: '109px' }}
        />
      </CustomToolTip>
    </div>
  );
};

export default React.memo(TaskType);
