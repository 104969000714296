import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
  deleteWebformMapping,
  getAllWebformForms,
  hideGuideForWebforms
} from 'slices/automationStuffSlice';
import { isEmpty } from 'utils/utils';
import FormItem from '../automations/components/formItem';
import EditWebformForm from '../forms/components/editWebformForm';
import DeleteConfirmation from 'components/alerts/deleteConfirmation';
import { getWebformMappingQuestions } from 'slices/webformMappingSlice';
import { setIsHideWebformGuide } from 'slices/myInfoSlice';
import { useSnackbar } from 'components/Snackbar';

const WebformForms = () => {
  const { channelId } = useParams();
  const dispatch = useDispatch();
  const { openSuccessSnackBar } = useSnackbar();
  const { webformYourForms } = useSelector((state) => state.automationStuff);
  const { subOrganizationId, isHideWebformGuide } = useSelector(
    (state) => state.myInfo
  );
  const [showMapFields, setShowMapFields] = useState(false);
  const [selectedForm, setSelectedForm] = useState();
  const [formId, setFormId] = useState(null);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(null);
  const [isDeleteLoading, setIsDeleteLoading] = useState(false);

  useEffect(() => {
    dispatch(getAllWebformForms({ channelId: channelId }));
    dispatch(getWebformMappingQuestions());
  }, [channelId]);

  const onEdit = (item) => {
    setShowMapFields(true);
    setFormId(item.id);
    setSelectedForm(item);
  };

  const handleDeleteForm = (id) => {
    setShowDeleteConfirm(id);
  };

  const onDeleteCallback = () => {
    openSuccessSnackBar('Deleted successfully', 'large');
    setIsDeleteLoading(false);
    setShowDeleteConfirm(null);
    dispatch(getAllWebformForms({ channelId: channelId }));
  };

  const confirmDelete = () => {
    setIsDeleteLoading(true);
    dispatch(
      deleteWebformMapping({
        formId: showDeleteConfirm,
        channelId: channelId,
        subOrgId: subOrganizationId,
        callback: onDeleteCallback
      })
    );
  };

  const hideCallback = (data) => {
    dispatch(setIsHideWebformGuide(true));
  };

  const onHideGuide = () => {
    dispatch(
      hideGuideForWebforms({ isHideGuide: true, callback: hideCallback })
    );
  };

  return (
    <>
      <DeleteConfirmation
        showConfirmation={showDeleteConfirm === null ? false : true}
        onCancel={() => {
          setShowDeleteConfirm(null);
        }}
        title="Are you sure you want to delete this form mapping?"
        onConfirm={confirmDelete}
        isLoading={isDeleteLoading}
      />
      <EditWebformForm
        showMapFields={showMapFields}
        onClose={() => setShowMapFields(false)}
        formId={formId}
        selectedForm={selectedForm}
        isDeleteLoading={isDeleteLoading}
      />
      <div
        className="h-full w-full"
        style={{ height: 'calc(100% - 45px)', overflowY: 'auto' }}
      >
        <div className="py-6 pr-6 pl-8">
          {!isHideWebformGuide && (
            <div
              className="flex justify-between py-3 pr-4 pl-6 rounded-lg"
              style={{ background: 'rgba(217, 217, 217, 0.26)' }}
            >
              <p className="text-sm">
                Once you have added the webform code to your website or landing
                page, send a test enquiry through. Once received, click the
                mapping button on the top of the enquiry. Map the your webform
                fields to your Reckonsys questions and name the form. Once
                mapped and named, your forms will show below.{' '}
                <span className="text-primary">
                  To learn, more read our setup guide
                </span>
              </p>
              <span
                className="text-primary text-sm font-medium ml-8 cursor-pointer"
                onClick={onHideGuide}
              >
                Hide
              </span>
            </div>
          )}
          {isEmpty(webformYourForms?.results) && (
            <div>
              <iframe
                src="https://player.vimeo.com/video/817353100?h=514657c98e&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
                webkitallowfullscreen="true"
                mozallowfullscreen="true"
                allowFullScreen={true}
                title="iframe"
                className="cursor-pointer"
                style={{
                  position: 'absolute',
                  width: '700px',
                  height: '420px'
                }}
              ></iframe>
            </div>
          )}
          {!isEmpty(webformYourForms?.results) &&
            webformYourForms?.results.map((item) => {
              const { name, form_web_page_link } = item;
              return (
                <div className="mt-5" key={item.id}>
                  <FormItem
                    item={item}
                    title={name}
                    isWebform={true}
                    formPage={form_web_page_link}
                    onEdit={onEdit}
                    handleDeleteForm={handleDeleteForm}
                  />
                </div>
              );
            })}
        </div>
      </div>
    </>
  );
};

export default WebformForms;
