import PrimaryButton from 'components/buttons/primary';
import PropTypes from 'prop-types';
import { useSnackbar } from 'components/Snackbar';

const CopySmsNumber = (props) => {
  const { phoneNumber } = props;
  const { openSuccessSnackBar } = useSnackbar();
  const copyToClipboard = (number) => {
    navigator.clipboard.writeText(number);
    openSuccessSnackBar('Number copied');
  };

  return (
    <div className="card-box px-6 py-4 mt-8">
      <h3 className="text-lg font-semibold letterspacing24em ">
        Your SMS number
      </h3>
      <p className="text-grey-800 text-sm letterspacing24em mt-1">
        You can use this number to receive SMS message from customers or leads
      </p>
      <div className="py-2 px-3 border border-grey-500-secondary bg-grey-300 rounded-lg mt-3">
        <span className="text-lg font-medium">{phoneNumber}</span>
      </div>
      <div className="flex items-center justify-between mt-4">
        <PrimaryButton
          className="btn--xs mr-4 flex-shrink-0"
          label="Copy number"
          onClick={() => copyToClipboard(`${phoneNumber}`)}
        />
        <span className="text-primary text-13 font-medium">
          <a
            href="https://help.reckonsys.io/en/articles/7228359-setup-and-connect-an-sms-number"
            target="_blank"
            rel="noreferrer"
          >
            Read more about receiving SMS
          </a>
        </span>
      </div>
    </div>
  );
};

CopySmsNumber.propTypes = {
  phoneNumber: PropTypes.string
};

export default CopySmsNumber;
