/* eslint-disable react/no-unescaped-entities */
import React, { useState } from 'react';
// import { ReactComponent as IconGoogle } from 'assets/icons/icon-gmail.svg';
// import CHOICES from 'choices';
import { ReactComponent as Logo } from 'assets/images/eb-logo.svg';
import { useSelector } from 'react-redux';
import DeleteConfirmation from 'components/alerts/deleteConfirmation';

const BoxEmail = ({ switchEmail }) => {
  const [showSwitchConfirmation, setShowSwitchConfirmation] = useState(false);
  const { box_provided_email } = useSelector((state) => state.emailAddress);

  const handleSwitch = () => {
    if (!box_provided_email?.is_active) {
      setShowSwitchConfirmation(true);
    }
  };

  const handleConfirm = () => {
    switchEmail({ id: box_provided_email?.id });
    setShowSwitchConfirmation(false);
  };

  return (
    <>
      <DeleteConfirmation
        showConfirmation={showSwitchConfirmation}
        onCancel={() => setShowSwitchConfirmation(false)}
        onConfirm={handleConfirm}
        title={`Are you sure you want to Switch to this email ${box_provided_email?.email} ? `}
        description=""
        confirmButtonText={'Confirm'}
      />
      <li
        className={
          box_provided_email?.is_active
            ? 'list-hover list-hover__active'
            : 'list-hover '
        }
        onClick={() => handleSwitch()}
      >
        <div className="list-hover__container">
          <figure className="general-settings__card-list__avatar-container p-2">
            <Logo width={22} />
          </figure>
          <div className="ml-4">
            <h4 className="text-base-1 font-semibold">
              Your Reckonsys email address
            </h4>
            <p className="text-sm text-grey-700 mt-1">
              When you reply to enquiries your emails currently come from:{' '}
              <span className="text-black font-medium">
                {box_provided_email?.email}
              </span>
            </p>
          </div>
        </div>
        {box_provided_email?.is_active && (
          <div
            className="absolute left-0 bg-secondary text-white text-11 font-medium rounded-t-lg"
            style={{ padding: '2px 10px', top: -21 }}
          >
            {' '}
            You're using this
          </div>
        )}
      </li>
    </>
  );
};

export default BoxEmail;
