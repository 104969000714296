/* eslint-disable no-unused-vars */
import React from 'react';
import iconCheck from 'assets/icons/icon-green-check-circle.svg';
// import CustomColorButton from 'components/buttons/customColorButton';
import { useSelector } from 'react-redux';
import DescriptionAside from 'modules/toolbox/component/descriptionAside';
import ToolboxVideoContent from 'modules/toolbox/component/videoContent';
import SMSContent from 'modules/toolbox/component/smsContent';
import { isEmpty } from 'utils/utils';
import { getCurrencyText } from 'utils/currency';
import SmsDescription from './description';

const Sms = ({ isConnected, setActiveTab, activeTab, setIsConnected }) => {
  const { smsDetails, smsPlans } = useSelector((state) => state.subscription);

  const handleTabClick = (index) => {
    setActiveTab(index);
  };

  const pricingFeatures = [
    '1 custom SMS number',
    'Send messages',
    'Receive messages',
    'Setup automated messages'
  ];

  const subscriptionDetails = [
    {
      msg: 'Subscribed by John King',
      date: '10 days ago'
    },
    {
      msg: '£15 per month',
      date: 'Next billing date 14/07/23'
    }
  ];

  const getStartPlan = () => {
    if (!isEmpty(smsPlans)) {
      const [plan] = smsPlans;
      return `${getCurrencyText(plan.currency)}${plan.price_per_month}`;
    }
    return '';
  };

  return (
    <>
      <div className="toolbox__main overflow-overlay">
        {/* {isConnected && ( */}
        <div className="tabs-wrapper">
          <div
            className={`tabs-header ${
              activeTab === 0 ? 'tabs-header__active' : ''
            }`}
            onClick={() => handleTabClick(0)}
          >
            Description
          </div>
          <div
            className={`tabs-header ${
              activeTab === 1 ? 'tabs-header__active' : ''
            }`}
            onClick={() => handleTabClick(1)}
          >
            SMS Plans
          </div>
        </div>
        {/* )} */}
        <div className="mt-5"></div>
        <div style={{ display: activeTab === 0 ? 'inline-block' : 'none' }}>
          {/* <ToolboxVideoContent src="https://player.vimeo.com/video/863532235?h=dfb50dfe53" /> */}
          <SmsDescription />
        </div>
        <div style={{ display: activeTab === 1 ? 'inline-block' : 'none' }}>
          <SMSContent />
        </div>
      </div>
      <div className="toolbox__aside overflow-overlay">
        {activeTab === 0 && (
          <>
            <div>
              <div className="flex items-start justify-between">
                <div className="flex items-end">
                  <span className="text-30 font-bold line-height-initial">
                    {getStartPlan()}
                  </span>
                  <span className="text-sm font-medium ml-2 mb-1">
                    Starting from
                  </span>
                </div>
              </div>
              {/* <h3 className="text-xl font-bold">Contact sales for pricing</h3> */}
              <p className="text-13 leading-5 mt-3">
                Prices start from {getStartPlan()} depending on the number of
                SMS you want to send and receive
              </p>
              <ul className="mt-3">
                {pricingFeatures.map((item, index) => {
                  return (
                    <li
                      className="flex items-center text-13 letterspacing24em mt-1"
                      key={index}
                    >
                      <code
                        className="inline-block bg-grey-900 rounded-full mr-2"
                        style={{ width: 3, height: 2 }}
                      ></code>
                      {item}
                    </li>
                  );
                })}
              </ul>
              {/* <CustomColorButton
                label="Schedule a demo"
                className="bg-blue-dark text-white text-sm mt-6 w-full"
                onClick={() => {
                  setIsConnected(true);
                  setActiveTab(1);
                }}
              /> */}
            </div>
            <div className="mt-8">
              <DescriptionAside helpGuideUrl="https://help.reckonsys.io/en/articles/7228359-setup-and-connect-an-sms-number" />
            </div>
          </>
        )}
        {activeTab === 1 && (
          <>
            {/* <div>
              <div className="flex items-end">
                <span className="text-30 font-bold line-height-initial">
                  £15
                </span>
                <span
                  className="text-sm font-medium ml-2 relative"
                  style={{ top: -4 }}
                >
                  Starting from / month
                </span>
              </div>
              <p className="text-13 font-medium letterspacing24em mt-3">
                Prices start from £15 per month{' '}
                <span className="font-normal">
                  {' '}
                  depending on the number of SMS you want to send
                </span>
              </p>
              <ul className="mt-5">
                {pricingFeatures.map((item, index) => {
                  return (
                    <li
                      className="flex items-center text-13 letterspacing24em mt-1"
                      key={index}
                    >
                      <code
                        className="inline-block bg-grey-900 rounded-full mr-2"
                        style={{ width: 3, height: 2 }}
                      ></code>
                      {item}
                    </li>
                  );
                })}
              </ul>
            </div> */}
            <div className="mt-0">
              <DescriptionAside helpGuideUrl="https://help.reckonsys.io/en/articles/7228359-setup-and-connect-an-sms-number" />
            </div>
            <ul className="mt-6">
              {!isEmpty(smsDetails?.current_plan) && (
                <li className="flex mb-3 items-center">
                  <img
                    src={iconCheck}
                    alt="icon-check"
                    className="relative inline-block flex-shrink-0"
                    style={{ width: 14, height: 14 }}
                  />
                  <div className="ml-3">
                    <h5 className="text-13 font-medium">
                      {getCurrencyText(smsDetails?.current_plan)}
                      {smsDetails?.current_plan?.price_per_month} per month
                    </h5>
                    {/* <p className="text-grey-600 text-13 font-medium">as</p> */}
                  </div>
                </li>
              )}
              {/* {subscriptionDetails.map((item, index) => {
                return (
                  <li className="flex mb-3" key={index}>
                    <img
                      src={iconCheck}
                      alt="icon-check"
                      className="relative inline-block flex-shrink-0"
                      style={{ width: 14, height: 14, top: 5 }}
                    />
                    <div className="ml-3">
                      <h5 className="text-13 font-medium">{item.msg}</h5>
                      <p className="text-grey-600 text-13 font-medium">
                        {item.date}
                      </p>
                    </div>
                  </li>
                );
              })} */}
            </ul>
          </>
        )}
      </div>
    </>
  );
};

export default Sms;
