import React, { useEffect, useRef, useState } from 'react';
import trashIcon from 'assets/icons/icon-trash-grey-700.svg';
import emailIcon from 'assets/icons/emailIcon.svg';
import noteIcon from 'assets/icons/help-icon.svg';
import Select from 'components/inputs/select';
import TextInputField from 'components/inputs/textField';
import {
  getBotScript,
  getBotUrlsOnPages,
  updateBotOnPages
} from 'slices/ai-assistant/deploy/websiteLinks/addToWebsite/slice';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import PrimaryButton from 'components/buttons/primary';
import { urlFieldValidation } from 'components/fieldsAndValidation';
import ErrorMessage from 'components/errorMessage';
import DeleteConfirmation from 'components/alerts/deleteConfirmation';
import { useSnackbar } from 'components/Snackbar';
import EmailModal from 'modules/enquirybox/channels/module/settings/channel/enquiryBot/emailModel';
import OverLayLoader from 'components/loader/overLayLoader';
import { animateScroll } from 'react-scroll';

const AddAssistant = () => {
  const options = [
    { label: 'Show the bot on all pages', value: 0 },
    { label: "Don't show the bot on specific pages", value: 1 },
    { label: 'Only show the bot on specific pages', value: 2 }
  ];

  const [openModal, setOpenModal] = useState(false);
  const [selectedValue, setSelectedValue] = useState(options[0].label);
  const [showAddUrl, setShowAddUrl] = useState(false);
  const dispatch = useDispatch();
  const [copied, setCopied] = useState(false);
  const codeRef = useRef(null);
  const { assistant_id } = useParams();
  const { botScript } = useSelector((state) => state.botOnPages);
  const { botOnPages } = useSelector((state) => state.botOnPages);
  const [listUrls, setListUrls] = useState([]);
  const [accessType, setAccessType] = useState(botOnPages.access_type);
  const [inputError, setInputError] = useState({});
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [deleteUrlIdx, setDeleteUrlIdx] = useState(null);
  const [showDeleteIcon, setShowDeleteIcon] = useState(null);
  const inputRefs = useRef([]);
  const { openSuccessSnackBar, openErrorSnackBar } = useSnackbar();

  const successCb = (option) => {
    setIsLoading(false);
    setSelectedValue(option);
    setInputError({});
  };

  const failedCb = () => {
    setIsLoading(false);
    openErrorSnackBar('Update failed');
  };

  const updateSuccessCb = () => {
    setIsLoading(false);
    setInputError({});
    openSuccessSnackBar('URL saved');
  };

  const checkUpdate = (arr1, arr2) => {
    if (arr1.length !== arr2.length) {
      return true;
    }
    for (let i = 0; i < arr1.length; i++) {
      if (arr1[i] !== arr2[i]) {
        return true;
      }
    }
    return false;
  };

  useEffect(() => {
    if (botOnPages.access_type === 0) setSelectedValue(options[0].label);
    else if (botOnPages.access_type === 1) setSelectedValue(options[1].label);
    else if (botOnPages.access_type === 2) setSelectedValue(options[2].label);

    setListUrls(botOnPages.url_list);
    setAccessType(botOnPages.access_type);
  }, [botOnPages]);

  useEffect(() => {
    dispatch(getBotScript(assistant_id));
  }, []);

  useEffect(() => {
    dispatch(getBotUrlsOnPages(assistant_id));
  }, []);

  useEffect(() => {
    if (selectedValue !== options[0].label) setShowAddUrl(true);
    else setShowAddUrl(false);
  }, [selectedValue]);

  const handleOptionChange = (option) => {
    if (selectedValue !== option.label) {
      setIsLoading(true);
      const data = {
        botId: assistant_id,
        payload: {
          access_type: option.value,
          url_list: []
        },
        option: option.label
      };
      dispatch(updateBotOnPages(data, successCb, failedCb));
    }
  };

  const copyToClipboard = () => {
    codeRef.current.select();
    document.execCommand('copy');
    setCopied(true);
  };

  const handleSubmit = (e, index) => {
    e.preventDefault();
    document.activeElement.blur();
    const hasError = inputError[index] !== null;
    const reduxState = botOnPages.url_list;
    const updated = checkUpdate(reduxState, listUrls);

    if (updated && !hasError) {
      setIsLoading(true);
      const data = {
        botId: assistant_id,
        payload: {
          access_type: accessType,
          url_list: listUrls
        },
        option: selectedValue
      };
      dispatch(updateBotOnPages(data, updateSuccessCb, failedCb));
    }
  };

  const handleUrlChange = (e, index) => {
    const updatedListUrls = [...listUrls];
    updatedListUrls[index] = e.target.value;
    setListUrls(updatedListUrls);

    const inputErrors = { ...inputError };
    if (!e.target.value.match(urlFieldValidation))
      inputErrors[index] = 'Please provide a valid link URL';
    else inputErrors[index] = null;
    setInputError(inputErrors);
  };

  const deleteSuccesCb = () => {
    setInputError({});
    setShowConfirmation(false);
    setIsLoading(false);
    openSuccessSnackBar('URL deleted');
  };

  const handleDeleteUrl = () => {
    const deleteUrl = listUrls.filter((url, idx) => idx === deleteUrlIdx);

    if (deleteUrl === '' || !deleteUrl[0].match(urlFieldValidation)) {
      const updatedList = listUrls.filter((url, idx) => idx !== deleteUrlIdx);
      setListUrls(updatedList);
      setShowConfirmation(false);
      setIsLoading(false);
      setInputError({});
      openSuccessSnackBar('Deleted');
    } else {
      const updataedUrls = listUrls.filter((url, idx) => idx !== deleteUrlIdx);
      const data = {
        botId: assistant_id,
        payload: {
          access_type: accessType,
          url_list: updataedUrls
        },
        option: selectedValue
      };
      setIsLoading(true);
      dispatch(updateBotOnPages(data, deleteSuccesCb));
    }
  };

  const scrollDown = () => {
    setTimeout(() => {
      animateScroll.scrollToBottom({
        containerId: 'bot-container',
        smooth: true,
        duration: 250
      });
    }, 100);
  };

  const handleAddUrlClick = () => {
    if (listUrls.length < botOnPages.url_list.length + 1) {
      setListUrls((prevList) => ['', ...prevList]);
      scrollDown();
    } else {
      inputRefs.current[0].focus();
      scrollDown();
    }
  };

  return (
    <>
      {isLoading && (
        <OverLayLoader
          style={{
            top: '0px',
            left: '150px',
            width: '100%',
            height: '100%'
          }}
        />
      )}
      <EmailModal
        open={openModal}
        onClose={() => setOpenModal(false)}
        scriptCode={botScript}
        chatbotAI={true}
        containerStyle={{ height: 'auto' }}
      />
      <section style={{ padding: '25px 0' }}>
        <div
          className="border-b border-grey-400"
          style={{ padding: '0 38px 40px' }}
        >
          <label className="input-field-label mb-0">
            Add your assistant to your website
          </label>
          <p className="text-grey-800 mt-2">
            Add the code before the &lt;/body&gt; tag on all pages. Not
            tech-savvy? Check out our guide for{' '}
            <Link
              to="https://help.reckonsys.io/en/articles/8710820-adding-chatbot-code-to-your-website"
              target="_blank"
              rel="noopener noreferrer"
              className="text-primary font-medium"
            >
              {' '}
              easy ways to add the code without a developer.
            </Link>
          </p>
          <div
            className="py-4 px-5 cursor-pointer border border-grey-500 rounded-lg mt-6 flex justify-between"
            style={{ backgroundColor: 'var(--gray-200, #FCFBF8)' }}
          >
            <p>
              Email the code and instructions to your web developer or agency.
            </p>
            <div
              onClick={() => setOpenModal(true)}
              className="send_email flex justify-start gap-1 items-center"
            >
              <img src={emailIcon} />
              <p>Send email</p>
            </div>
          </div>
          <div className="py-4 px-5 border border-grey-500 rounded-lg mt-6">
            <input
              className="w-full"
              readOnly
              value={botScript}
              ref={codeRef}
            />
          </div>
          <PrimaryButton
            className="btn--xs mt-3"
            label={copied ? 'Copied' : 'Copy Code'}
            onClick={copyToClipboard}
          />
          <div
            className="flex py-4 px-5 border border-grey-500 rounded-lg mt-6"
            style={{ backgroundColor: 'rgba(217, 217, 217, 0.26)' }}
          >
            <img src={noteIcon} />
            <p className="ml-3">
              Need help or have a question?
              <Link
                to="https://help.reckonsys.io/en/articles/8710820-adding-chatbot-code-to-your-website"
                target="_blank"
                rel="noopener noreferrer"
                className="cursor-pointer"
                style={{ color: '#CE4961' }}
              >
                {' '}
                Read our step by step setup guide
              </Link>
            </p>
          </div>
        </div>
        <div className="" style={{ padding: '40px 38px 40px' }}>
          <div className="border border-grey-400 border-radius-14 px-6 py-4">
            <label className="input-field-label mb-4">
              Control the pages your bot shows on
            </label>
            <Select
              name="botOnPages"
              options={options}
              readOnly={true}
              value={selectedValue}
              onChange={handleOptionChange}
              dropDownClassName="px-2 py-2"
            />
            {selectedValue === options[0].label ? (
              <p className="text-grey-800 text-15 mt-3">
                The bot will show on every page where the embed code has been
                added
              </p>
            ) : selectedValue === options[1].label ? (
              <p className="text-grey-800 text-15 mt-3">
                The bot will show on all pages{' '}
                <span className="underline">except</span> for the page URLs that
                you add below
              </p>
            ) : (
              <p className="text-grey-800 text-15 mt-3">
                The bot will <span className="underline">only</span> show on all
                the page URLs that you add below add below
              </p>
            )}

            {showAddUrl && (
              <div className="text-primary text-15 mt-3 mb-3">
                <span className="cursor-pointer" onClick={handleAddUrlClick}>
                  + Add a page URL{' '}
                </span>
              </div>
            )}
            {listUrls?.map((url, index) => (
              <div
                key={index}
                className="add-item-input-container mb-2 flex"
                onMouseEnter={() => setShowDeleteIcon(index)}
                onMouseLeave={() => setShowDeleteIcon(null)}
              >
                <form
                  style={{ width: '100%' }}
                  onSubmit={(e) => handleSubmit(e, index)}
                >
                  <TextInputField
                    autoComplete={'off'}
                    name="PageURL"
                    ref={(ref) => (inputRefs.current[index] = ref)}
                    type="text"
                    className="input-field--h48 add-item-input-container__input"
                    placeholder="Paste the page URL here"
                    value={url}
                    onChange={(e) => handleUrlChange(e, index)}
                    onBlur={(e) => handleSubmit(e, index)}
                    error={inputError[index]}
                  />
                  <div>
                    {inputError[index] && (
                      <ErrorMessage message={inputError[index]} />
                    )}
                  </div>
                </form>
                {showDeleteIcon === index && (
                  <div
                    className="add-item-input-container__delete mt-[14px]"
                    onClick={() => {
                      setShowConfirmation(true);
                      setDeleteUrlIdx(index);
                    }}
                  >
                    <img
                      className="cursor-pointer ml-2"
                      src={trashIcon}
                      alt="delete-icon"
                    />
                  </div>
                )}
              </div>
            ))}
            <DeleteConfirmation
              showConfirmation={showConfirmation}
              onCancel={() => setShowConfirmation(false)}
              onConfirm={() => handleDeleteUrl()}
              title="Are you sure you want to delete?"
              isLoading={isLoading}
            />
          </div>
        </div>
      </section>
    </>
  );
};

export default AddAssistant;
