/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import EnquiryBotName from './enquiryBotName';
import HeaderItem from 'modules/enquirybox/channels/components/layout/header';
import { useDispatch, useSelector } from 'react-redux';
import {
  getConnectedChannelById,
  updateChannelNameAndStatus
} from 'slices/channelsSlice';
import { useNavigate, useParams } from 'react-router-dom';
import EnquiryBotScript from './enquiryBotScript';
import { Loader } from 'components/loader';
import { useSnackbar } from 'components/Snackbar';
import DeleteChannel from '../deleteChannel';
import { ReactComponent as EmailIcon } from 'assets/icons/icon-send-email-channel.svg';
import CHOICES from 'choices';
import ConnectedChannelTab from 'modules/channels/components/settingsTab';
import CustomColorButtonWithLogo from 'components/buttons/customColorButtonWithLogo';
import EmailModal from './emailModel';

const EnquiryBotConnectedSettings = ({ channelType }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { channelId } = useParams();
  const { openSuccessSnackBar } = useSnackbar();
  const { subOrganizationId } = useSelector((state) => state.myInfo);
  const { connectedChannelById } = useSelector((state) => state.channels);
  const [isLoading, setIsLoading] = useState(false);
  const [channelName, setChannelName] = useState('');
  const [isActiveBot, setIsActiveBot] = useState(false);
  const [isEmailModalOpened, setIsEmailModalOpend] = useState(false);

  const onSuccess = (data) => {
    setIsLoading(false);
    setChannelName(data?.name);
    setIsActiveBot(data?.ebot?.is_active);
  };

  const loadConnectedEnquiryBotChannel = () => {
    dispatch(
      getConnectedChannelById(
        { subOrgId: subOrganizationId, channelId },
        onSuccess
      )
    );
  };

  useEffect(() => {
    setIsLoading(true);
    loadConnectedEnquiryBotChannel();
  }, [channelId]);

  const onSuccessUpdate = (data) => {
    setChannelName(data?.channel_name);
    setIsActiveBot(data?.is_active);
  };

  const onSave = async (data) => {
    const formData = {
      channel_name: data.name,
      is_active: isActiveBot
    };
    dispatch(
      updateChannelNameAndStatus(
        {
          subOrgId: subOrganizationId,
          channelId: channelId,
          body: formData
        },
        (data) => {
          onSuccessUpdate(data);
          openSuccessSnackBar('Success');
        }
      )
    );
  };

  const onChange = (e) => {
    setIsActiveBot(e.target.checked);
    const formData = {
      channel_name: channelName,
      is_active: e.target.checked
    };
    dispatch(
      updateChannelNameAndStatus(
        {
          subOrgId: subOrganizationId,
          channelId: channelId,
          body: formData
        },
        (data) => {
          onSuccessUpdate(data);
          openSuccessSnackBar('Success');
        }
      )
    );
  };

  const onDeleteSuccess = () => {
    // dispatch(getMyInfo());
    openSuccessSnackBar('Channel Removed');
    navigate('/channels');
  };

  const onCloseEmailModal = () => {
    setIsEmailModalOpend(false);
  };

  if (isLoading) return <Loader />;

  return (
    <div className="h-full w-full">
      <div
        className="py-6 px-8"
        style={{ height: 'calc(100% - 45px)', overflowY: 'auto' }}
      >
        <div style={{ width: '70%' }}>
          <div className="card-box px-6 py-5">
            <div>
              <HeaderItem title="Add your EnquiryBot to your website" />
              <p className="text-grey-800 text-15 font-medium">
                {`Add the code before the </body> tag on all pages. Not tech-savvy?
                 Check out our guide for easy ways to add the code without a developer. `}
                <span className="text-primary cursor-pointer">
                  easy ways to add the code without a developer.
                </span>
              </p>
              <p className="bg-grey-200 text-grey-800 flex pt-4 pb-4 justify-around rounded-lg mt-5">
                Email the code and instructions to your web developer or agency.
                <CustomColorButtonWithLogo
                  className="border-black btn--xs-28 justify-around p-3"
                  label="Send email"
                  style={{
                    border: '1px solid #CE4961',
                    padding: '6px',
                    fontSize: '13px',
                    fontStyle: 'normal',
                    fontWeight: 600,
                    lineHeight: ' 19px',
                    letterSpacing: ' -0.031px'
                  }}
                  renderSvg={() => (
                    <EmailIcon className="mr-1" style={{ marginTop: 2 }} />
                  )}
                  onClick={() => setIsEmailModalOpend(true)}
                />
              </p>
              <EnquiryBotScript
                scriptCode={connectedChannelById?.ebot?.scrpit}
                isLoading={isLoading}
                isActiveBot={isActiveBot}
                onChange={onChange}
                botId={connectedChannelById?.ebot?.bot_uid}
              />
            </div>
          </div>
          <div className="card-box pt-5 px-6 pb-6 mt-8 relative">
            <EnquiryBotName channelName={channelName} onSave={onSave} />
          </div>
          <DeleteChannel channelId={channelId} onSuccess={onDeleteSuccess} />
        </div>
      </div>
      <div>
        <EmailModal
          open={isEmailModalOpened}
          onClose={onCloseEmailModal}
          channelType={channelType}
          scriptCode={connectedChannelById?.ebot?.scrpit}
        />
      </div>
    </div>
  );
};

export default EnquiryBotConnectedSettings;
