import React from 'react';
import HeaderLayout from 'components/layout/headerless';
import { ReactComponent as RedCross } from 'assets/icons/icon-red-cross.svg';
import CardLayout from 'components/layout/card';
import CardTitle from 'components/layout/cardTitle';
import ResetLinkSent from '../forgotPassword/resetLink';
import Form from './form';
import PropTypes from 'prop-types';
import { Loader } from 'components/loader';

const ResetPassword = (props) => {
  const {
    title,
    subTitle,
    onClick,
    isSuccess,
    isLoading,
    isResetPassword,
    children
  } = props;

  if (isSuccess) {
    return <ResetLinkSent isResetPassword={isResetPassword} />;
  }

  return (
    <HeaderLayout>
      <CardLayout
        style={{
          paddingLeft: '6rem',
          paddingRight: '6rem',
          paddingBottom: '4rem'
        }}
      >
        <div className="flex justify-center items-center mb-6">
          {isLoading ? (
            <div style={{ width: 48, height: 48 }}>
              <Loader />
            </div>
          ) : (
            <RedCross />
          )}
        </div>
        <CardTitle title={title} />
        <Form
          isLoading={isLoading}
          onClick={onClick}
          title="Click here to resend a new link"
        />
        <div className="text-13 font-normal mt-5 text-center text-grey-800">
          {subTitle} {` `}
          <a
            target="blank"
            className="text-primary font-normal cursor-pointer text-13"
          >
            notifications@reckonsys.io
          </a>
        </div>
        {children}
      </CardLayout>
    </HeaderLayout>
  );
};

ResetPassword.propTypes = {
  title: PropTypes.string,
  subTitle: PropTypes.string,
  onClick: PropTypes.func,
  isSuccess: PropTypes.bool,
  isLoading: PropTypes.bool,
  isResetPassword: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node)
  ])
};

export default ResetPassword;
