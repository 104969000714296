import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useSnackbar } from 'components/Snackbar';
import DeleteChannel from 'modules/enquirybox/channels/module/settings/channel/deleteChannel';
import { useDispatch, useSelector } from 'react-redux';
import { getConnectedWebform, updateWebFormData } from 'slices/webformSlice';
import { Loader } from 'components/loader';
import WebFormChannelName from 'modules/enquirybox/channels/module/settings/channel/webform/webFormChannelName';
import WebFormScript from 'modules/enquirybox/channels/module/settings/channel/webform/webFormScript';
import CustomColorButtonWithLogo from 'components/buttons/customColorButtonWithLogo';
import { ReactComponent as EmailIcon } from 'assets/icons/icon-send-email-channel.svg';
import EmailModal from 'modules/enquirybox/channels/module/settings/channel/enquiryBot/emailModel';

const WebformSettings = () => {
  const dispatch = useDispatch();
  const { channelId } = useParams();
  const navigate = useNavigate();
  const { openSuccessSnackBar } = useSnackbar();
  const { subOrganizationId } = useSelector((state) => state.myInfo);
  const [channelName, setChannelName] = useState('');
  const [webFormData, setWebformData] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [isEmailModalOpened, setIsEmailModalOpend] = useState(false);

  const onSuccess = (data) => {
    setWebformData(data);
    setChannelName(data?.connected_channel?.name);
    setIsLoading(false);
  };

  const getFormData = () => {
    setIsLoading(true);
    dispatch(
      getConnectedWebform({ subOrgId: subOrganizationId, channelId }, onSuccess)
    );
  };

  useEffect(() => {
    getFormData();
  }, [channelId]);

  const onSave = async (data) => {
    const formData = {
      name: data.name,
      is_connected: webFormData.connected_channel.is_connected,
      subOrgId: subOrganizationId,
      channelId: channelId
    };
    dispatch(
      updateWebFormData(
        {
          subOrgId: subOrganizationId,
          channelId: channelId,
          body: formData
        },
        (data) => {
          onSuccess(data);
          openSuccessSnackBar('Success');
        }
      )
    );
  };

  const onDeleteSuccess = () => {
    openSuccessSnackBar('Channel Removed');
    navigate('/channels');
  };

  const onCloseEmailModal = () => {
    setIsEmailModalOpend(false);
  };

  if (isLoading)
    return (
      <div className="flex justify-center item-center">
        <Loader />
      </div>
    );

  return (
    <div
      className="w-full"
      style={{ height: 'calc(100% - 45px)', overflowY: 'auto' }}
    >
      <div className="py-6 px-8" style={{ maxWidth: '70%' }}>
        <div className="card-box pt-5 px-7 pb-6">
          <h2 className="font-semibold text-lg">
            Follow these directions to receive enquiries from your website forms
          </h2>
          <ul className="mt-5 pl-5">
            <li style={{ listStyle: 'disc' }} className="text-sm font-medium">
              Add the code below to your website.{' '}
              <span className="text-primary cursor-pointer">
                Here are directions on how to do that
              </span>
            </li>
            <li
              style={{ listStyle: 'disc' }}
              className="text-sm font-medium mt-2"
            >
              Once added, put a test enquiry through a form
            </li>
            <li
              style={{ listStyle: 'disc' }}
              className="text-sm font-medium mt-2"
            >
              Once received in Reckonsys, map your fields and name your form
            </li>
          </ul>
          <div
            className="mt-6 rounded-xl py-5 px-6"
            style={{ background: '#FFFAED' }}
          >
            <div>
              <h2 className="text-sm font-semibold text-grey-800">
                Option 1: Connect all forms on your website
              </h2>
              <p className="text-13 text-grey-800">{`Paste code before </body> on every page on your website`}</p>
            </div>
            <div className="mt-5">
              <h2 className="text-sm font-semibold text-grey-800">
                Option 2: Connect specific forms on your website or landing page
              </h2>
              <p className="text-13 text-grey-800">{`Paste code before </body> on only pages with the forms you want to receive enquiries from`}</p>
            </div>
          </div>
          <h2 className="text-13 font-medium mt-6">
            Add this code to your website 👇
          </h2>
          <WebFormScript
            scriptCode={webFormData}
            isConnected
            newChannelText="Where do I add the code?"
          />
          <div className="flex justify-between rounded-xl bg-grey-200 mt-5 py-3 pr-3 pl-5 items-center">
            <p className="text-15 text-grey-800">
              Email the code and instructions to your web developer or agency.
            </p>
            <CustomColorButtonWithLogo
              label="Send email"
              renderSvg={() => (
                <EmailIcon className="mr-1" style={{ marginTop: 2 }} />
              )}
              className="border border-grey-900"
              style={{ fontSize: 13, fontWeight: 600, padding: '4px 12px' }}
              onClick={() => setIsEmailModalOpend(true)}
            />
          </div>
        </div>
        <div className="card-box pt-5 px-6 pb-6 mt-8 relative">
          <WebFormChannelName onSave={onSave} channelName={channelName} />
        </div>
        <DeleteChannel channelId={channelId} onSuccess={onDeleteSuccess} />
        <div>
          <EmailModal
            open={isEmailModalOpened}
            onClose={onCloseEmailModal}
            scriptCode={webFormData?.expose_script}
          />
        </div>
      </div>
    </div>
  );
};

export default WebformSettings;
