/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import iconSearch from 'assets/icons/icon-search.svg';
import iconSettings from 'assets/icons/icon-settings.svg';
import Avatar from 'components/avatar';
import Search from './search';
import { route } from 'utils/route';
import ToggleHeader from './ToggleHeader';
import CustomToolTip from 'components/CustomTooltip';
import SearchModal from 'modules/search';
import CustomColorButtonWithLogo from 'components/buttons/customColorButtonWithLogo';
import ChannelSettingsIcon from 'assets/icons/icon-channel-header-new.svg';
import GuideDropdown from 'components/guideDropdown';
import { channelGuideOptions } from 'utils/guide';
const ChannelHeader = () => {
  const { channelId } = useParams();
  const navigate = useNavigate();
  const [activateSearch, setActivateSearch] = useState(false);
  const [mouseIn, setMouseIn] = useState(false);

  const OnSettingsClick = () => {
    navigate(`${route.dashboard}${route.enquirybox}${route.settings}`);
  };
  return (
    <>
      <SearchModal
        open={activateSearch}
        onClose={() => setActivateSearch(false)}
      />
      <div
        className="dashboard__main__header"
        style={{ padding: '20px 25px 20px 40px' }}
      >
        <div className="flex items-center dashboard__main__header__section-left">
          <CustomToolTip
            position="right"
            tooltipText="Search"
            tooltipStyle={{ top: 5, left: '117%' }}
          >
            <div
              onClick={() => setActivateSearch(true)}
              onMouseEnter={() => setMouseIn(true)}
              onMouseLeave={() => setMouseIn(false)}
            >
              <Avatar
                src={iconSearch}
                imgStyle={{ width: '35px', height: '20px' }}
                style={{ width: 50, height: 50 }}
                larger={true}
                className={`cursor-pointer ${
                  mouseIn ? 'bg-grey-90' : 'bg-color-800'
                }`}
              />
            </div>
          </CustomToolTip>
          <div className="ml-5">
            <h3 className="text-xl font-semibold">Things to do</h3>
            {/* <p className="text-base-1 font-medium text-primary">Hide this Channel for now</p> */}
          </div>
        </div>
        {/* <ToggleHeader /> */}
        <div className="flex items-center dashboard__main__header__section-right justify-end">
          <div className="search-container">
            {/* <Search /> */}
            {/* <code className="bg-grey-400 mx-4" style={{ width: 1, height: 26 }} /> */}
            {/* <CustomColorButtonWithLogo
              label="Channels"
              className={`btn--xs gap-1 border border-basic-button`}
              onClick={OnSettingsClick}
              renderSvg={() => {
                return (
                  <img
                    src={iconSettings}
                    alt="icon-settings"
                    className="cursor-pointer"
                    style={{ height: 11, width: 13 }}
                  />
                );
              }}
              style={{ fontSize: 13, fontWeight: 500 }}
            /> */}
            <GuideDropdown
              description='The "Things To Do" section provides a single place to view and manage your daily workload in reckonsys.'
              guideOptions={channelGuideOptions}
            />
            <CustomToolTip
              tooltipText="Connect and manage channels, Setup automations and more."
              position="bottom-left"
              tooltipStyle={{
                maxWidth: '223px'
              }}
            >
              <NavLink
                to={`${route.channels}`}
                className="btn btn--xs cursor-pointer border border-basic-button"
              >
                <img
                  src={ChannelSettingsIcon}
                  alt="icon-settings"
                  className="cursor-pointer"
                  // style={{ height: 18, width: 17 }}
                />
                <p className="text-13 font-medium ml-1">Channels</p>
              </NavLink>
            </CustomToolTip>
          </div>
        </div>
      </div>
    </>
  );
};

export default ChannelHeader;
