/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { route } from 'utils/route';
import { Link, useNavigate } from 'react-router-dom';
import { ReactComponent as PlusIcon } from 'assets/icons/icon-plus-green.svg';
import { isEmpty } from 'utils/utils';
import ChannelSettingsLink from 'modules/enquirybox/channels/components/channelSettingsLink';
import { ReactComponent as DeleteIcon } from 'assets/icons/icon-channel-delete.svg';
import { useSelector, useDispatch } from 'react-redux';
import {
  getAllPendingChannels,
  getAllConnectedChannels
} from 'slices/channelsSlice';
import { Loader } from 'components/loader';
import { getMyInfo } from 'slices/myInfoSlice';
import { deleteChannel } from 'slices/channelsSlice';
import { getRoutePath } from 'utils/channels';
import { getConnectedChannel } from 'utils/channels';
import DeleteConfirmation from 'components/alerts/deleteConfirmation';
import choices from 'choices';
import { useSnackbar } from 'components/Snackbar';

const { WEBFORM, WHATSAPP, ZAPIER, FACEBOOK, INSTAGRAM, SMS, EBOT } =
  choices.ChannelChoices;

const ChannelSettings = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const { openSuccessSnackBar } = useSnackbar();
  const [deleteLoading, setDeleteLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const subOrgId = useSelector((state) => state.myInfo.subOrganizationId);

  const { allPendingChannels, allConnectedChannels } = useSelector(
    (state) => state.channels
  );
  const getPendingChannels = async (subOrgId) => {
    return await dispatch(getAllPendingChannels({ subOrgId }));
  };

  const getConnectedChannels = async (subOrgId) => {
    return await dispatch(getAllConnectedChannels({ subOrgId }));
  };

  const GetChannels = () => {
    setIsLoading(true);
    Promise.all([
      getPendingChannels(subOrgId),
      getConnectedChannels(subOrgId)
    ]).then(() => {
      setIsLoading(false);
    });
  };

  useEffect(() => {
    if (isEmpty(allPendingChannels) || isEmpty(allConnectedChannels)) {
      GetChannels();
    } else {
      setIsLoading(false);
    }
  }, []);

  const onDeleteSuccess = () => {
    setDeleteId(null);
    setShowDelete(false);
    openSuccessSnackBar('Channel Removed');
    dispatch(getMyInfo());
    setDeleteLoading(false);
    navigate('dashboard/enquirybox/settings');
  };

  const onDeleteChannel = () => {
    setDeleteLoading(true);
    dispatch(
      deleteChannel(
        { subOrgId: subOrgId, channelId: deleteId },
        onDeleteSuccess
      )
    );
  };

  return (
    <>
      <DeleteConfirmation
        title="Are you sure you want to delete this channel?"
        description="Once deleted you will not be able to get it back. You will have to
      reconnect it again. Contacts in the channel will be stored in your
      all contacts list."
        showConfirmation={showDelete}
        onCancel={() => {
          setDeleteId(null);
          setShowDelete(false);
        }}
        onConfirm={onDeleteChannel}
        isLoading={deleteLoading}
      />
      <Link
        className="flex items-center cursor-pointer py-3"
        to={`${route.dashboard}${route.enquirybox}${route.settings}/select-channels`}
      >
        <figure
          className="bg-grey-90 flex justify-center items-center"
          style={{ width: 42, height: 42, borderRadius: '50%' }}
        >
          <PlusIcon width={12} height={12} />
        </figure>
        <p className="ml-4 text-15 font-semibold text-primary truncate">
          Connect a channel to your reckonsys
        </p>
      </Link>
      {isLoading && (
        <div className="flex justify-center items-center">
          <Loader small />
        </div>
      )}
      {!isEmpty(allPendingChannels) && (
        <div className="mt-2">
          <h2 className="text-13 font-medium text-grey-700">
            PENDING CONNECTIONS
          </h2>
          {allPendingChannels.map((item) => {
            const {
              id,
              description,
              title,
              channel_type,
              is_it_deleteable,
              avatar_link,
              connected_channel_name
            } = item;
            // const isHide =
            //   channel_type === WHATSAPP ||
            //   channel_type === ZAPIER ||
            //   channel_type === INSTAGRAM ||
            //   channel_type === FACEBOOK
            //     ? true
            //     : false;
            const isHide = channel_type === WHATSAPP ? true : false;
            // const isHide =
            //   channel_type === WHATSAPP || channel_type === ZAPIER
            //     ? true
            //     : false;
            if (isHide) return <div key={id}></div>;
            if (!isHide) {
              let url = `${route.dashboard}${route.enquirybox}${
                route.settings
              }/${getRoutePath(channel_type)}/pending/${id}`;
              if (channel_type === WEBFORM && is_it_deleteable) {
                url = `${url}/draft`;
              }
              if (channel_type === SMS) {
                url = `${route.toolBox}/sms`;
              }
              if (channel_type === EBOT) {
                url = `${route.toolBox}/enquiry-bot`;
              }
              return (
                <ChannelSettingsLink
                  key={id}
                  channelId={id}
                  title={
                    connected_channel_name
                      ? connected_channel_name
                      : `Connect ${title}`
                  }
                  subTitle={description}
                  // icon={getIcon(channel_type)}
                  to={url}
                  renderSvg={() => <DeleteIcon />}
                  icon={avatar_link}
                  isDeletable={is_it_deleteable}
                  onDelete={(id) => {
                    setDeleteId(id);
                    setShowDelete(true);
                  }}
                />
              );
            }
          })}
        </div>
      )}
      {!isEmpty(allConnectedChannels) && (
        <div className="mt-6">
          <h2 className="text-13 font-medium text-grey-700">
            CONNECTED CHANNELS
          </h2>
          {allConnectedChannels.map((item) => {
            const { id, channel, name } = item;
            // const isHide = channel.channel_type === ZAPIER ? true : false;
            // if (isHide) return <div key={id}></div>;
            return (
              <ChannelSettingsLink
                key={id}
                title={name || channel?.channel_name}
                subTitle={getConnectedChannel(channel.channel_type)}
                icon={channel.avatar_link}
                to={`${route.dashboard}${route.enquirybox}${
                  route.settings
                }/${getRoutePath(channel.channel_type)}/connected/${id}`}
              />
            );
          })}
        </div>
      )}
    </>
  );
};

export default React.memo(ChannelSettings);
