import React from 'react';
import Modal from 'components/modal';
import ImageCrop from 'components/reactImageCrop';
import CustomColorButton from 'components/buttons/customColorButton';

const CropImage = (props) => {
  const { open, setOpen, src, setCanvas, handleSaveCroppedImage } = props;
  return (
    <Modal.Main open={open} className="modal--crop-image">
      <Modal.Header
        title="Crop image"
        onClose={() => {
          setOpen(false);
        }}
      />
      <Modal.Body className="overflow-y-auto">
        <div className="crop-image-container flex justify-center items-center">
          <ImageCrop
            src={src}
            cropPositionX={0}
            cropPositionY={0}
            cropWidthInPercent={20}
            onComplete={setCanvas}
          />
        </div>
      </Modal.Body>
      <div className="flex items-center justify-end px-7 py-4">
        <CustomColorButton
          label="Cancel"
          className="btn--wide-md-40 w-[120px] btn--cancel text-base-1 mr-3 cursor-pointer"
          onClick={() => setOpen(false)}
        />
        <CustomColorButton
          label={'Crop'}
          className="btn--wide-md-40 w-[120px] text-base-1 bg-[#CE4961] text-white mr-3 cursor-pointer"
          onClick={handleSaveCroppedImage}
        />
      </div>
    </Modal.Main>
  );
};

export default CropImage;
