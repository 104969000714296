/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useRef, useEffect } from 'react';
import { object } from 'yup';
import PropTypes from 'prop-types';
import Modal from 'components/modal';
import { useForm } from 'react-hook-form';
import Select from 'components/inputs/select';
import useClickOutside from 'hooks/useOutsideClick';
import { yupResolver } from '@hookform/resolvers/yup';
import TextInputField from 'components/inputs/textField';
import { getFormSchema } from 'components/fieldsAndValidation';
import CustomColorButton from 'components/buttons/customColorButton';
import { handleScrollIntoview, isEmpty } from 'utils/utils';
import { useDispatch, useSelector } from 'react-redux';
import { getAllWebformMappingFields } from 'slices/webformMappingSlice';
import { useSnackbar } from 'components/Snackbar';
import { requiredSelectMsg } from 'utils/messages';
import { useParams } from 'react-router-dom';
import ErrorMessage from 'components/errorMessage';
// import closeIcon from 'assets/icons/icon-close-red.svg';
import ListHeader from 'modules/modals/webformMapFields/components/listHeader';
import {
  ErrorMessagePopUp,
  ErrorPopUpBackdrop
} from 'modules/modals/webformMapFields/components/errorPopup';
import FieldListItem from 'modules/modals/webformMapFields/components/fieldListItem';
import {
  editWebformMappingFields,
  getAllWebformForms,
  getFormMappingData
} from 'slices/automationStuffSlice';
import { Loader } from 'components/loader';

// import { animateScroll } from 'react-scroll';

const EditWebformForm = (props) => {
  const { showMapFields, onClose, formId, selectedForm } = props;
  const nodeRef = useRef();
  let webRef = useRef();
  const parentRef = useRef();
  const dispatch = useDispatch();
  const { channelId } = useParams();
  const { openSuccessSnackBar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const [, setOpenDropdown] = useState(false);
  const [callbackError, setCallbackError] = useState([]);
  const [resetValue, setResetValue] = useState(false);
  const [isApiLoading, setIsApiLoading] = useState(false);
  const { webFormMappingQuestions, allFields } = useSelector(
    (state) => state.mapFields
  );
  const { allWebformFormFields } = useSelector(
    (state) => state.automationStuff
  );
  const getFormFields = !isEmpty(webFormMappingQuestions)
    ? webFormMappingQuestions?.map((e) => e.form_value)
    : [];
  const [errorPopUp, setErrorPopUp] = useState(false);
  const [isMapping, setIsMapping] = useState(false);
  const [webformName, setWebformName] = useState(selectedForm?.name);
  const selectFormSchema = getFormFields.reduce((acc, field) => {
    return {
      ...acc,
      [field]: object().nullable().required(requiredSelectMsg(field))
    };
  }, {});

  const formSchema = {
    // ...getFormSchema(['webformName']),
    ...selectFormSchema
  };

  const {
    register,
    unregister,
    handleSubmit,
    control,
    setValue,
    getValues,
    clearErrors,
    reset,
    watch,
    formState: { errors, touchedFields }
  } = useForm({
    resolver: yupResolver(object().shape(formSchema)),
    mode: 'onChange'
  });

  useClickOutside(() => setOpenDropdown(false), nodeRef.current);

  const fieldCallback = () => {
    dispatch(getFormMappingData({ formId: formId, callback }));
  };

  const callback = () => {
    setIsApiLoading(false);
  };

  useEffect(() => {
    if (showMapFields && formId) {
      setIsApiLoading(true);
      dispatch(getAllWebformMappingFields({ formId: formId }, fieldCallback));
    }
  }, [showMapFields, formId]);

  const getSelectedKeyValue = (data) => {
    const isValueExist = allWebformFormFields?.filter(
      (item) => item.question === data.id
    );
    if (!isEmpty(isValueExist)) {
      const selectedField = allFields?.form_fields.filter(
        (item) => item.value === isValueExist[0].field_name
      );
      setIsMapping(false);
      return selectedField[0];
    } else {
      setIsMapping(false);
      return { label: 'Do not map', value: 'donotmap' };
    }
  };

  useEffect(() => {
    if (isEmpty(allWebformFormFields)) {
      const keys = Object.keys(selectFormSchema);
      clearErrors(keys);
      reset();
    } else {
      if (
        showMapFields &&
        formId &&
        !isEmpty(webFormMappingQuestions) &&
        !isEmpty(allFields) &&
        !isEmpty(allWebformFormFields)
      ) {
        setIsMapping(true);
        setTimeout(() => {
          webFormMappingQuestions?.map((item) => {
            return setValue(item.form_value, getSelectedKeyValue(item), {
              shouldTouch: true
            });
          });
        }, 2000);
      }
    }
  }, [
    showMapFields,
    formId,
    webFormMappingQuestions,
    allFields,
    allWebformFormFields
  ]);

  useEffect(() => {
    setWebformName(selectedForm?.name);
    const keys = Object.keys(selectFormSchema);
    clearErrors(keys);
    reset();
    if (showMapFields) {
      setResetValue(true);
    }
  }, [showMapFields]);

  const getQuestionIdFromValue = (value) => {
    const selectedQuestion = webFormMappingQuestions.filter(
      (e) => e.form_value === value
    );
    return selectedQuestion[0]?.id;
  };

  const onSubmit = async (data) => {
    if (isEmpty(webformName)) {
      setErrorPopUp(true);
      return;
    } else {
      setIsLoading(true);
      setCallbackError([]);
      const form_fields = Object.keys(data);
      const mappedFields = form_fields.map((e) => ({
        field_name: data[e].value,
        question: getQuestionIdFromValue(e),
        form: formId
      }));
      if (mappedFields.some((item) => item.field_name === 'donotmap')) {
        const removeDonotMap = mappedFields.filter(
          (e) => e.field_name !== 'donotmap'
        );
        const formData = {
          body: {
            mapping_data: removeDonotMap,
            name: webformName
          },
          formId: formId
        };
        dispatch(editWebformMappingFields(formData, onSuccess, onError));
      } else {
        const formData = {
          body: {
            mapping_data: mappedFields,
            name: webformName
          },
          formId: formId
        };
        dispatch(editWebformMappingFields(formData, onSuccess, onError));
      }
    }
  };

  const onError = (err) => {
    setIsLoading(false);
    setCallbackError(err.mapping);
  };

  const onSuccess = () => {
    setIsLoading(false);
    dispatch(getAllWebformForms({ channelId: channelId }));
    onClose();
    openSuccessSnackBar('Mapping Success');
  };

  useEffect(() => {
    if (webRef.current) {
      setTimeout(() => {
        webRef.current?.focus();
      }, 100);
    }
  }, [webRef, webRef.current, showMapFields]);

  useEffect(() => {
    if (!isEmpty(errors)) {
      setErrorPopUp(true);
    }
  }, [errors, touchedFields]);

  const getSelectedAnswer = (id) => {
    if (isEmpty(allWebformFormFields)) {
      return '';
    }
    const isValueExist = allWebformFormFields?.filter(
      (item) => item.question === id
    );
    if (!isEmpty(isValueExist) && !isEmpty(allFields)) {
      const selectedField = allFields?.form_fields.filter(
        (item) => item.value === isValueExist[0].field_name
      );
      return selectedField[0].value;
    } else {
      return 'donotmap';
    }
  };

  return (
    <Modal.Main open={showMapFields} className="modal--match-fields">
      <Modal.Header
        title="Map your fields"
        onClose={() => {
          reset();
          onClose();
        }}
      />
      <ErrorMessagePopUp
        show={errorPopUp}
        onClose={() => setErrorPopUp(false)}
        message="Please match all webform fields to reckonsys questions. Unmatched fields are marked in red"
      />
      <Modal.Body className="px-0 pt-0 pb-8 relative">
        {isMapping || isApiLoading ? (
          <Loader />
        ) : (
          <>
            {errorPopUp && <ErrorPopUpBackdrop />}
            <div className="mt-5 px-12 ">
              <label htmlFor="webformName" className="font-semibold">
                Webform name
              </label>
              <TextInputField
                name="webformName"
                placeholder="Name your webform so you recognise submissions in the future"
                className="input-field--md mb-6 mt-3"
                style={{ backgroundColor: '#FAF9F8' }}
                onChange={(evt) => {
                  // webformName.onChange(evt);
                  // setValue('webformName', evt.target.value);
                  setWebformName(evt.target.value);
                }}
                value={webformName}
                // onBlur={webformName.onBlur}
                ref={webRef}
                error={isEmpty(webformName)}
              />
            </div>
            <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
              <div
                className="overflow-y-auto px-12 pt-3 scroll-smooth "
                ref={parentRef}
              >
                <div style={{ maxHeight: 300 }}>
                  <div className="flex">
                    <ListHeader title="Reckonsys questions" className="w-3/6" />
                    <ListHeader title="Webform fields" className="w-3/6 pl-3" />
                  </div>
                  {!isEmpty(webFormMappingQuestions) &&
                    webFormMappingQuestions?.map((item, index) => {
                      const { text, form_value, id } = item;
                      return (
                        <div
                          className="flex mt-4 gap-5"
                          id={`${index}-select`}
                          key={index}
                        >
                          <FieldListItem name={text} />
                          <div
                            className="relative w-3/6"
                            ref={nodeRef}
                            onClick={() =>
                              handleScrollIntoview(
                                `dropDownId-${index}`,
                                'start'
                              )
                            }
                          >
                            <Select
                              id={`dropDownId-${index}`}
                              inputClassName={
                                isEmpty(errors[form_value]) &&
                                !isEmpty(getValues(form_value))
                                  ? 'input-field--selected'
                                  : 'input-field--md'
                              }
                              placeholder="Select a field"
                              options={allFields?.form_fields || []}
                              name={form_value}
                              control={control}
                              setValue={setValue}
                              // register={register}
                              // selectedValue={getValues(form_value)}
                              error={!isEmpty(errors[form_value])}
                              showGreenBorder={
                                isEmpty(errors[form_value]) &&
                                !isEmpty(getValues(form_value))
                              }
                              required
                              dropDownClassName="dropdown-menu__map-fields"
                              resetValue={resetValue}
                              isModalOpen={showMapFields}
                              selectedAnswer={() => getSelectedAnswer(id)}
                            />
                          </div>
                        </div>
                      );
                    })}
                </div>
                <div className="mt-5">
                  {!isEmpty(callbackError) &&
                    callbackError.map((err) => (
                      <ErrorMessage className="mb-5" message={err} key={err} />
                    ))}
                </div>
              </div>
              <div className="flex items-center justify-between gap-3 px-12 pt-6">
                <div>
                  <a
                    href="https://help.reckonsys.io/en/articles/7241966-mapping-forms"
                    target="_blank"
                    className="font-bold cursor-pointer text-20"
                    rel="noreferrer"
                  >
                    Learn about mapping
                  </a>
                </div>
                <div className="flex gap-2">
                  <CustomColorButton
                    label="Cancel"
                    className="btn--secondary btn--md min-w-100"
                    onClick={() => {
                      onClose();
                    }}
                  />
                  <CustomColorButton
                    type="submit"
                    label="Save & finish"
                    className="btn--green btn--md btn--wide-20 min-w-100"
                    isLoading={isLoading}
                    enableLoader={isLoading}
                    loaderInline={true}
                  />
                </div>
              </div>
            </form>
          </>
        )}
      </Modal.Body>
    </Modal.Main>
  );
};

EditWebformForm.propTypes = {
  showMapFields: PropTypes.bool,
  onClose: PropTypes.func
};

export default EditWebformForm;
