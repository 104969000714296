import React from 'react';
import PropTypes from 'prop-types';
import closeGreen from 'assets/icons/icon-close-green.svg';

const SettingsHeader = ({ text, onClose }) => {
  return (
    <div
      className="dashboard__main__header"
      style={{ padding: '27px 35px 25px 41px' }}
    >
      <h3 className="text-xl font-semibold">{text}</h3>
      <div
        className="flex items-center p-2 border-radius-20 cursor-pointer"
        onClick={onClose}
        style={{
          border: '1px solid #E5E5E5',
          boxShadow: '0px 3px 6px -2px rgba(85, 83, 80, 0.15)'
        }}
      >
        <p
          className="text-13 font-medium ml-2 mr-2"
          style={{ color: '#CE4961' }}
        >
          Close Settings
        </p>
        <img src={closeGreen} alt="arrow-right" className="mr-2 " />
      </div>
    </div>
  );
};

SettingsHeader.propTypes = {
  text: PropTypes.string.isRequired,
  onClose: PropTypes.func
};

SettingsHeader.defaultProps = {
  text: ''
};

export default SettingsHeader;
