import React from 'react';
import PropTypes from 'prop-types';
import CustomColorButton from 'components/buttons/customColorButton';

const MapFieldContainer = (props) => {
  const { onClick, isBin, lead, isFbLeads, isWebform } = props;
  const { connected_channel, form } = lead;

  return (
    <div className="py-3 px-5 bg-violet-700 text-white flex justify-between mb-4 rounded-xl items-center">
      {connected_channel && form && isWebform && (
        <div>
          <h3 className="font-semibold">
            Map your webform fields to Reckonsys
          </h3>
          <span className="text-sm text-grey-200">
            Once mapped all future submissions from this form will map
            automatically
          </span>
        </div>
      )}
      {connected_channel && isFbLeads && (
        <div>
          <h3 className="font-semibold">Map your fields to Reckonsys</h3>
          <span className="text-sm text-grey-200">
            Once mapped all future submissions from this form will map
            automatically
          </span>
        </div>
      )}
      {!connected_channel && !form && (
        <div>
          <h3 className="font-semibold">This channel is disconnected</h3>
        </div>
      )}
      <CustomColorButton
        label="Map fields"
        className="btn--white text-grey-800 text-xs font-semibold items-start"
        style={{ padding: '9px 18px', minWidth: 98 }}
        onClick={isBin ? () => {} : onClick}
        isDisable={!connected_channel && !form}
      />
    </div>
  );
};

MapFieldContainer.propTypes = {
  onClick: PropTypes.func,
  isWebform: PropTypes.bool,
  isFbLeads: PropTypes.bool
};

MapFieldContainer.defaultProps = {
  isWebform: false,
  isFbLeads: false
};

export default MapFieldContainer;
