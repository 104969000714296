/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { object, number } from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { getFormSchema } from 'components/fieldsAndValidation';
import { useForm } from 'react-hook-form';
import { useInviteUserMutation } from 'services/organizationApi';
// import { SuccessToast } from 'components/toast';
import { requiredSelectMsg } from 'utils/messages';
import Modal from 'components/modal';
import InputField from 'components/inputs/textField';
import PrimaryButton from 'components/buttons/primary';
import Error from 'components/errorMessage';
import UserLevel from 'modules/dashboard/component/userLevel';
import choices from 'choices';
// import { useSnackbar } from 'components/Snackbar';
import { useDispatch, useSelector } from 'react-redux';
import { getOrganizationUser } from 'slices/organizationuserSlice';
import { ReactComponent as SuccessIcon } from 'assets/icons/icon-invite-sent.svg';
import { isEmpty } from 'utils/utils';
import ErrorMessage from 'components/errorMessage';
import useGetUserRole from 'hooks/useGetUserRole';
import { useAnalytics } from 'use-analytics';

const TEAM_ADMIN = choices.TeamMemberRoleChoice.TEAM_ADMIN;
const TEAM_MEMBER = choices.TeamMemberRoleChoice.TEAM_MEMBER;

const InviteNewTeamMember = ({ open, onClose }) => {
  const dispatch = useDispatch();
  // const { openSuccessSnackBar } = useSnackbar();
  const [isInviteSent, setIsInviteSent] = useState(false);
  const [showError, setShowError] = useState('');
  const { subOrganizationId } = useSelector((state) => state.myInfo);
  const { isAdmin, isSuperUser } = useGetUserRole();
  // const { analytics } = useSelector((state) => state.segments);
  const { track } = useAnalytics();

  const formSchema = {
    ...getFormSchema(['email']),
    user_level: number()
      .required(requiredSelectMsg('user type'))
      .nullable()
      .oneOf([TEAM_MEMBER, TEAM_ADMIN])
  };

  const {
    register,
    handleSubmit,
    formState: { errors, touchedFields },
    clearErrors,
    getValues,
    reset
  } = useForm({
    resolver: yupResolver(object().shape(formSchema)),
    mode: 'onChange'
  });
  const [inviteUser, { isLoading, isSuccess, isError, error }] =
    useInviteUserMutation();

  const loadUsersList = () => {
    dispatch(getOrganizationUser({ subOrgId: subOrganizationId }));
  };

  const onInviteSuccess = () => {
    // openSuccessSnackBar('Invite sent');
    const user_level = getValues('user_level');
    track(
      'invite_sent',
      {
        user_type: choices.TeamMemberRoleChoice.CHOICES[user_level]
      },
      {
        groupId: subOrganizationId
      }
    );
    loadUsersList();
    setIsInviteSent(true);
    // onClose();
  };

  useEffect(() => {
    if (isSuccess) {
      onInviteSuccess();
    }
  }, [isSuccess]);

  useEffect(() => {
    if (isError) {
      setShowError(error?.data?.error[0]);
    }
  }, [isError]);

  useEffect(() => {
    reset({ email: '', user_level: null });
    setShowError('');
  }, [open]);

  const onSubmit = async (data) => {
    const { email, user_level } = data;
    const formData = {
      email: email,
      role: user_level
    };
    await inviteUser({ body: formData, subOrgId: subOrganizationId });
  };

  const handleClose = () => {
    const keys = Object.keys(formSchema);
    clearErrors(keys);
    onClose();
    setIsInviteSent(false);
  };

  return (
    <Modal.Main open={open} className="z-502">
      <Modal.Header
        title={isInviteSent ? 'Add team member' : 'Invite the team'}
        onClose={handleClose}
        style={{ borderRadius: '10px 10px 0px 0px' }}
      />
      <Modal.Body className="p-8 overflow-y-auto">
        {isInviteSent ? (
          <>
            <div className="text-center">
              <SuccessIcon className="m-auto" />
              <h3 className="font-semibold text-lg mt-6">Invitation sent</h3>
              <p className="text-sm mt-2">
                {`Once they accept and create their account you'll get a
                notification from your enquiry assistant.`}
              </p>
            </div>
            <div className="flex justify-end">
              <PrimaryButton
                label="Done"
                className="btn--md min-w-120"
                style={{ marginTop: 60 }}
                onClick={() => {
                  setIsInviteSent(false);
                  onClose();
                }}
              />
            </div>
          </>
        ) : (
          <div className="mt-2">
            <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
              <label className="input-field-label">Email</label>
              <InputField
                name="email"
                placeholder="Add the email address for the invitation"
                className="input-field--md"
                error={errors.email && touchedFields.email}
                register={register}
              />
              {errors.email && touchedFields.email && (
                <Error message={errors.email.message} />
              )}
              <div>
                <p className="text-grey-900 font-semibold mt-8">
                  Select the user type
                </p>
                <span className="text-13 font-normal mt-10 text-grey-800">
                  Not sure? See what{' '}
                  <a
                    target="_blank"
                    rel="noreferrer"
                    className="text-primary font-semibold cursor-pointer text-13 underline"
                    href="https://help.reckonsys.io/en/articles/7228599-inviting-a-new-team-member"
                  >
                    access and permissions
                  </a>{' '}
                  each user type has.
                </span>
                <div className="relative">
                  <UserLevel
                    title="Team member"
                    id={TEAM_MEMBER}
                    desc="Basic access, limited permissions to delete or download"
                    value={TEAM_MEMBER}
                    register={register}
                    name="user_level"
                    required
                  />
                  {(isAdmin || isSuperUser) && (
                    <UserLevel
                      id={TEAM_ADMIN}
                      title="Account admin"
                      desc="Full access, can add users, manage billing and account setup."
                      value={TEAM_ADMIN}
                      register={register}
                      name="user_level"
                      required
                    />
                  )}
                </div>
                {errors.user_level && touchedFields.email && (
                  <Error message={errors?.user_level?.message} />
                )}
                <div className="text-13 text-grey-800 mt-5 bg-grey-200 rounded-xl px-4 py-3">
                  You will be notified once the team member has joined
                </div>
              </div>
              {!isEmpty(showError) && <ErrorMessage message={showError} />}
              <PrimaryButton
                type="submit"
                label="Send Invite"
                className="btn--wide-sm-40 ml-auto mt-10"
                isLoading={isLoading}
                enableLoader={true}
                loaderInline={true}
              />
            </form>
          </div>
        )}
      </Modal.Body>
    </Modal.Main>
  );
};

InviteNewTeamMember.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func
};

InviteNewTeamMember.defaultProps = {
  open: false,
  onClose: () => {}
};

export default InviteNewTeamMember;
