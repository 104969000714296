/* eslint-disable no-unused-vars */
import Modal from 'components/modal';
import React, { useEffect, useState } from 'react';
import SendEmail from './sendEmail';
import PrimaryButton from 'components/buttons/primary';
import CustomColorButton from 'components/buttons/customColorButton';
import { useDispatch, useSelector } from 'react-redux';
import { sendEmailToDeveloper } from 'slices/channelsSlice';
import { isEmpty } from 'utils/utils';
import ErrorMessage from 'components/errorMessage';
import CHOICES from 'choices';

const { EBOT } = CHOICES.ChannelChoices;

const EmailModal = (props) => {
  const {
    open,
    onClose,
    scriptCode,
    channelType,
    chatbotAI = false,
    containerStyle = {}
  } = props;
  const dispatch = useDispatch();
  const { selectedFromEmail } = useSelector((state) => state.editor);
  const { myInformation } = useSelector((state) => state.myInfo);
  const [emailData, setEmailData] = useState({
    from: '',
    to: [],
    subject: '',
    body: ''
  });
  const [error, setError] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (open && !chatbotAI) {
      setError({});
      setEmailData({
        ...emailData,
        subject: `Add enquirybot code to ${myInformation?.website}`,
        body:
          channelType === EBOT
            ? `Hi There,

We have recently signed up for Reckonsys and want to add their Chatbot to our website to generate more leads. Can you please add the chatbot script below to the footer of our website. This is just before the </body>.

${scriptCode}

If you need any assistance, please read this article.

Thanks,

${myInformation?.name}`
            : `Hi There,

We have recently signed up for Reckonsys and to use their webform integration to get all the webform submissions from our website connected.

In order to do this, can you please add the webform script below to the footer of our website. This is just before the </body>.

${scriptCode}

If you need any assistance, please read this article.

Thanks,

${myInformation?.name}`
      });
    }
  }, [open, scriptCode, chatbotAI]);

  useEffect(() => {
    if (chatbotAI && open) {
      setError({});
      setEmailData({
        ...emailData,
        to: [],
        subject: `Add chatbot code to ${myInformation?.website}`,
        body: `Hi There,

Channels are the various ways in which your business receives enquiries. Start connecting your channels and bring all your enquiries into Reckonsys.

In order to do this, can you please add the script below to the footer of our website. This is just before the </body>.

${scriptCode}

If you need any assistance, please read this article.

Thanks,

${myInformation?.name}`
      });
    }
  }, [chatbotAI, open]);

  const callback = () => {
    setEmailData({
      ...emailData,
      to: [],
      subject: `Add enquirybot code to ${myInformation?.website}`,
      body:
        channelType === EBOT
          ? `Hi There,

We have recently signed up for Reckonsys and want to add their Chatbot to our website to generate more leads. Can you please add the chatbot script below to the footer of our website. This is just before the </body>.

${scriptCode}

If you need any assistance, please read this article.

Thanks,

${myInformation?.name}`
          : `Hi There,

We have recently signed up for Reckonsys and to use their webform integration to get all the webform submissions from our website connected.

In order to do this, can you please add the webform script below to the footer of our website. This is just before the </body>.

${scriptCode}

If you need any assistance, please read this article.

Thanks,

${myInformation?.name}`
    });
    setIsLoading(false);
    onClose();
  };

  const checkIsValid = () => {
    let isValid = true;
    if (isEmpty(selectedFromEmail?.email)) {
      setError({ ...error, fromEmail: 'add from email' });
      isValid = false;
    }
    if (isEmpty(emailData?.to)) {
      setError({ ...error, to: 'add to email' });
      isValid = false;
    }
    if (isEmpty(emailData?.subject)) {
      setError({ ...error, subject: 'add subject' });
      isValid = false;
    }
    if (isEmpty(emailData?.body)) {
      setError({ ...error, emailBody: 'add some text' });
      isValid = false;
    }
    return isValid;
  };

  const onClick = () => {
    const isValid = checkIsValid();
    if (isValid) {
      setIsLoading(true);
      const data = {
        mail_from: selectedFromEmail?.email,
        mail_to: emailData?.to,
        mail_subject: emailData?.subject,
        mail_body: emailData?.body
      };
      console.log(data, 'data');
      dispatch(sendEmailToDeveloper({ data: data, callback }));
    }
  };

  return (
    <Modal.Main
      open={open}
      className="modal--channels-request"
      containerStyle={{ width: 613, height: 600, ...containerStyle }}
    >
      <Modal.Header
        title="Email your web Developer or Agency"
        onClose={onClose}
      />
      <Modal.Body style={{ padding: 0 }}>
        <div>
          <SendEmail
            setEmailData={setEmailData}
            emailData={emailData}
            setError={setError}
            error={error}
          />
        </div>
        <div
          className={`flex p-3 ${
            isEmpty(error) ? 'justify-end' : 'justify-between items-center'
          }`}
        >
          {!isEmpty(error) && (
            <ErrorMessage message="Please fill all the details" />
          )}
          <div className="flex justify-end">
            <CustomColorButton
              className="btn--grey btn--md mr-2"
              style={{ paddingLeft: '25px', paddingRight: '25px' }}
              label="Cancel"
              onClick={onClose}
            />
            <PrimaryButton
              className="btn--md btn btn--primary text-13 mr-2 relative"
              label="Send"
              style={{ paddingLeft: '25px', paddingRight: '25px' }}
              enableLoader
              loaderInline
              isLoading={isLoading}
              onClick={onClick}
            />
          </div>
        </div>
      </Modal.Body>
    </Modal.Main>
  );
};

export default EmailModal;
