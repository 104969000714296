/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import CustomEmail from './customEmail';
import BoxEmail from './boxEmail';
import {
  getProvidedEmail,
  getEmailVerificationProvider
} from 'slices/emailAddress/slice';
import { useSelector, useDispatch } from 'react-redux';
import Loader from 'components/loader';
import APIRequest from 'apiRequest';
import { SWITCH_EMAIL } from 'urls/emailAddress';
import { useSnackbar } from 'components/Snackbar';

const EmailAddress = () => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const { box_provided_email } = useSelector((state) => state.emailAddress);
  const { openSuccessSnackBar } = useSnackbar();

  useEffect(() => {
    setIsLoading(true);
    dispatch(getProvidedEmail());
    dispatch(getEmailVerificationProvider());
  }, []);

  const switchEmail = (formData) => {
    setIsLoading(true);
    new APIRequest().post(SWITCH_EMAIL, formData).then((res) => {
      openSuccessSnackBar('Sucessfully swtiched email', 'large');
      setIsLoading(false);
      dispatch(getProvidedEmail());
      dispatch(getEmailVerificationProvider());
    });
  };

  return (
    <>
      <div className="text-center">
        <h2 className="text-2xl font-semibold">Email reply address</h2>
        <p className="text-base-1 text-grey-800 mt-2">
          This is the email address that customers will see when you send
          replies to enquiries by email.
          <a
            className="font-medium text-primary"
            href="https://help.reckonsys.io/en/articles/7228829-sending-replies-from-your-own-email-address"
            target="_blank"
            rel="noreferrer"
          >
            {' Learn more about this feature.'}
          </a>
        </p>
      </div>
      <div className="card-box py-5 px-6 mt-5">
        <h3 className="text-lg font-semibold">Your email reply address</h3>
        <ul className="mt-10">
          <div className="mb-8">
            <BoxEmail switchEmail={switchEmail} />
            <CustomEmail switchEmail={switchEmail} />
          </div>
        </ul>
      </div>
    </>
  );
};

export default EmailAddress;
