/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { object } from 'yup';
import { useForm, useController } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { getFormSchema } from 'components/fieldsAndValidation';
import choices from 'choices';
import InputField from 'components/inputs/textField';
import Error from 'components/errorMessage';
import PrimaryButton from 'components/buttons/primary';
import Select from 'components/inputs/select';
import Modal from 'components/modal';
import { useCreateBusinessMutation } from 'services/organizationApi';
import { ReactComponent as IndustryIcon } from 'assets/icons/icon-industry.svg';
import { ReactComponent as BusinessIcon } from 'assets/icons/icon-business.svg';
import { ReactComponent as UserGroupIcon } from 'assets/icons/icon-user-group.svg';
import { ReactComponent as WebIcon } from 'assets/icons/icon-web.svg';
import { handlePlanType, isEmpty } from 'utils/utils';
import { useDispatch, useSelector } from 'react-redux';
import {
  getCountryList,
  organizationPlanSignup,
  setLoadingFlag
} from 'slices/businessInfoSlice';
import Country from './CountrySelect';
import { useNavigate } from 'react-router-dom';
import { ENQUIRYBOX_PLAN_TYPE } from 'utils/constants';
import { getSubscriptionPlans } from 'slices/subscription/slice';
// import { SuccessToast } from 'components/toast';

const Industrys = choices.IndustryChoices.CHOICE_LIST;
const TeamSize = choices.TeamSizeChoice.CHOICE_LIST;

const BusinessInfo = (props) => {
  const { open, onClose } = props;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const countryList = useSelector(
    (state) => state.businessInfoSettings.countryList
  );
  const { loadingFlag } = useSelector((state) => state.businessInfoSettings);
  const formSchema = getFormSchema([
    'businessName',
    'industry',
    'teamSize',
    'websiteUrl',
    'countryName'
  ]);
  const {
    register,
    handleSubmit,
    getValues,
    control,
    setValue,
    watch,
    formState: { errors, touchedFields }
  } = useForm({
    resolver: yupResolver(object().shape(formSchema)),
    mode: 'onChange'
  });

  const [
    createBusiness,
    { isSuccess, data: createBusinessResponse, isError, isLoading, error }
  ] = useCreateBusinessMutation();
  const watchBusinessName = watch('businessName');
  const watchCountryName = watch('countryName');
  const { subscriptionPlans } = useSelector((state) => state.subscription);
  const enquirybox_plan_type = localStorage.getItem(ENQUIRYBOX_PLAN_TYPE);
  // useEffect(() => {
  //   if (isSuccess) {
  //     // SuccessToast(data.message);

  //     // closeModal();
  //   }
  // }, [isSuccess]);

  useEffect(() => {
    if (isError) {
      dispatch(setLoadingFlag(false));
    }
  }, [isError]);

  const callOrgPlanSignup = (data) => {
    dispatch(
      organizationPlanSignup(data, createBusinessResponse?.message, closeModal)
    );
  };

  useEffect(() => {
    if (createBusinessResponse && subscriptionPlans.length === 0) {
      dispatch(getSubscriptionPlans(createBusinessResponse.message));
    }
  }, [createBusinessResponse, subscriptionPlans]);

  useEffect(() => {
    if (createBusinessResponse && subscriptionPlans?.length !== 0) {
      let jsonPost;
      let subPlan = handlePlanType(enquirybox_plan_type)
        ? subscriptionPlans?.find(
            (i) => i.plan_type === parseInt(enquirybox_plan_type)
          )
        : undefined;

      if (subPlan) {
        jsonPost = {
          is_free_plan: false,
          plan_id: subPlan?.id,
          payment_by: 'card',
          s_url: `${process.env.REACT_APP_FRONT_END_DOMAIN}/dashboard/enquirybox/eb-box?payment_status=success`,
          f_url: `${process.env.REACT_APP_FRONT_END_DOMAIN}/dashboard/enquirybox/eb-box?payment_status=failed`
        };
      } else {
        jsonPost = {
          is_free_plan: true
        };
      }
      callOrgPlanSignup(jsonPost);
    }
  }, [createBusinessResponse, subscriptionPlans]);

  useEffect(() => {
    dispatch(getCountryList());
  }, []);

  const CountryOptions = (countryList || []).map((item, index) => {
    return {
      label: item.name,
      value: item?.id,
      icon: item?.flag_url
    };
  });

  // useEffect(() => {
  //   if (!isEmpty(watchBusinessName)) {
  //     setValue('industry', { shouldTouch: true });
  //     setValue('teamSize', { shouldTouch: true });
  //   }
  // }, [watchBusinessName]);

  const closeModal = (data) => {
    dispatch(setLoadingFlag(false));
    if (data.checkout_url !== '') {
      onClose();
      window.location.href = data.checkout_url;
    } else if (data.checkout_url === '') {
      onClose();
    }
  };
  const onSubmit = async (data) => {
    dispatch(setLoadingFlag(true));
    const { industry, teamSize, websiteUrl, businessName, countryName } = data;
    const formData = {
      website_link: websiteUrl,
      industry_type: industry.value,
      team_size: teamSize.value,
      organization_name: businessName,
      country: countryName.value
      // is_free_plan_sign_up: true
    };
    await createBusiness(formData);
  };
  return (
    <Modal.Main open={open}>
      <Modal.Body className="p-8">
        <div className="flex flex-col justify-center items-center">
          <p className="font-bold text-xl">Let’s setup your reckonsys</p>
          <p className="font-normal text-sm mt-4">
            We’ll use this info to suggest the perfect setup
          </p>
          <div className="w-full mt-10 px-15">
            <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
              <InputField
                name="businessName"
                placeholder="Business Name"
                // className="input-field--icon-business"
                icon={true}
                renderIcon={() => <BusinessIcon />}
                error={errors.businessName && touchedFields.businessName}
                register={register}
              >
                {errors.businessName && touchedFields.businessName && (
                  <Error message={errors.businessName.message} />
                )}
              </InputField>
              <div className="mt-3">
                <Country
                  options={CountryOptions}
                  placeholder="Select Country"
                  control={control}
                  error={
                    errors.countryName && !errors.businessName ? true : false
                  }
                  iconStyle={{
                    position: 'relative',
                    top: '-2px',
                    zIndex: 1,
                    left: '0px',
                    marginRight: '10px',
                    width: '20px'
                  }}
                  defaultIconStyle={{
                    position: 'relative',
                    top: '0px',
                    zIndex: 1,
                    left: '2px',
                    marginRight: '10px',
                    width: '16px'
                  }}
                />
                {errors.countryName && !errors.businessName && (
                  <Error message={errors.countryName.message} />
                )}
              </div>
              <div className="mt-3">
                <Select
                  name="industry"
                  placeholder="Select Industry"
                  // inputClassName="input-field--icon-industry"
                  options={Industrys}
                  icon={true}
                  renderIcon={() => <IndustryIcon />}
                  error={errors.industry && !errors.businessName ? true : false}
                  control={control}
                  setValue={setValue}
                  register={register}
                  readOnly
                />
                {errors.industry && !errors.businessName && (
                  <Error message={errors.industry.message} />
                )}
              </div>
              <div className="mt-3">
                <Select
                  name="teamSize"
                  // inputClassName="input-field--icon-user-group"
                  placeholder="Team Size"
                  options={TeamSize}
                  selectedValue={getValues('teamSize')}
                  icon={true}
                  error={
                    errors.teamSize && !errors.businessName && !errors.industry
                  }
                  control={control}
                  setValue={setValue}
                  renderIcon={() => <UserGroupIcon />}
                  readOnly
                  register={register}
                />
                {errors.teamSize &&
                  !errors.businessName &&
                  !errors.industry && (
                    <Error message={errors.teamSize.message} />
                  )}
              </div>
              <div className="mt-3">
                <InputField
                  name="websiteUrl"
                  placeholder="Website URL"
                  icon={true}
                  error={errors.websiteUrl && touchedFields.websiteUrl}
                  register={register}
                  renderIcon={() => <WebIcon />}
                >
                  {errors.websiteUrl && touchedFields.websiteUrl && (
                    <Error message={errors.websiteUrl.message} />
                  )}
                </InputField>
              </div>

              {isError && (
                <div className="mt-5">
                  <Error error={error?.data?.website_link} />
                </div>
              )}
              <PrimaryButton
                label="Take me to my Reckonsys"
                className="w-full btn--sm mt-10 mb-10"
                // isLoading={isLoading}
                isLoading={loadingFlag}
                enableLoader={true}
              />
            </form>
          </div>
        </div>
      </Modal.Body>
    </Modal.Main>
  );
};

BusinessInfo.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func
};

BusinessInfo.defaultProps = {
  open: false,
  onClose: () => {}
};

export default BusinessInfo;
