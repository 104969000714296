import React, { useState } from 'react';
import { ReactComponent as IosWhite } from 'assets/icons/icon-whatsApp-ios-white.svg';
import { ReactComponent as IosGrey } from 'assets/icons/icon-whatsApp-ios-grey.svg';
import { ReactComponent as AndroidWhite } from 'assets/icons/icon-whatsApp-android-white.svg';
import { ReactComponent as AndroidGrey } from 'assets/icons/icon-whatsApp-android-grey.svg';
import QRCode from 'assets/icons/qrcode.svg';
import MobileIcon from 'assets/icons/mobileView.svg';
import Header from '../../../../components/layout/header';

const WhatsApp = () => {
  const [isActive, setIsActive] = useState(1);

  return (
    <div className="toolbox__main  card-container--new w-full flex">
      <div className="w-6/12">
        <Header
          title="Connect a WhatsApp account"
          description="By connecting a whats app account your will beable to receive and respond to messages
            right within your reckonsys."
        />
        <p className="text-base-1 font-semibold mt-5">
          To connect, watch the video on the right for the device you use
          WhatsApp on. Then scan the QR Code to connect your account.
        </p>
        <figure className="flex items-center justify-center bg-grey-90 border-radius-14 p-12 mt-5">
          <img src={QRCode} alt="QR-Code" className="cursor-pointer" />
        </figure>
        <div className="mt-8">
          <h5 className="text-sm font-semibold text-red-900">
            Important to note
          </h5>
          <p className="text-grey-800 text-13 mt-2">
            To maintain the connection with your reckonsys, do not remove the
            linked device in WhatsApp and make sure to use the connected phone
            at least once every 14 days.
          </p>
        </div>
      </div>
      <div className="w-6/12 pl-10">
        <div className="flex justify-center">
          <div className="header-toggle" style={{ width: '200px' }}>
            <div
              className={`header-toggle__switch ${
                isActive === 1 ? 'header-toggle__switch--active' : ''
              }`}
              onClick={() => setIsActive(1)}
              style={{ paddingLeft: 29 }}
            >
              {isActive === 1 ? <IosWhite /> : <IosGrey />}
              <span
                className={`text-sm ml-1 font-medium ${
                  isActive === 1 ? 'text-white' : 'text-grey-800'
                }`}
              >
                iOS
              </span>
            </div>
            <div
              className={`header-toggle__switch ${
                isActive === 2 ? 'header-toggle__switch--active' : ''
              }`}
              onClick={() => setIsActive(2)}
            >
              {isActive === 2 ? <AndroidWhite /> : <AndroidGrey />}
              <span
                className={`text-sm ml-1 font-medium ${
                  isActive === 2 ? 'text-white' : 'text-grey-800'
                }`}
              >
                Android
              </span>
            </div>
          </div>
        </div>
        <img src={MobileIcon} alt="mobile-view" className="p-5" />
      </div>
    </div>
  );
};

export default WhatsApp;
