/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
// import propTypes from 'prop-types';
import VideoIcon from 'modules/channel/assets/icons/bigVideoIcon.svg';
import closeGreen from 'assets/icons/icon-close-green.svg';
import { ReactComponent as IconGreen } from 'assets/icons/icon-close-green.svg';
import { ReactComponent as IconPlayBtnSvg } from 'assets/icons/icon-play-button.svg';
import PrimaryButton from 'components/buttons/primary';

const AddboxTemplateHeadder = (props) => {
  const {
    title,
    HeaderIcon,
    showPreview,
    setShowPreview,
    handleCreateBox,
    setOpen,
    setSelectedTemplate
  } = props;
  const [modalShow, setShowModalShow] = useState(false);

  // const nextScreen = () => {
  //   // history.push("/dashboard-facebook");
  // };

  return (
    <div
      className="dashboard__main__header"
      style={{ padding: '20px 25px 20px 40px', height: 91 }}
    >
      <div className="flex items-center dashboard__main__header__section-left">
        <HeaderIcon />
        <div className="ml-5">
          <h3 className="text-xl font-semibold">{title}</h3>
        </div>
      </div>

      <div className="flex items-center dashboard__main__header__section-right mr-4">
        {/* {!showPreview ? (
          <div
            className="bg-blue-700 border-radius-20 flex items-center cursor-pointer"
            style={{ padding: '6px 14px 6px 6px' }}
            onClick={() => setShowModalShow(true)}
          >
            <figure
              className="bg-white flex justify-center items-center"
              style={{ width: 24, height: 24, borderRadius: '50%' }}
            >
              <IconPlayBtnSvg />
            </figure>

            <span className="text-white text-13 font-semibold ml-2">
              What are boxes?
            </span>
          </div>
        ) :  */}
        {showPreview && (
          <div className="flex items-center dashboard__main__header__section-right mr-4">
            <div className="flex gap-3">
              <PrimaryButton
                className="btn btn--primary text-13"
                style={{ padding: '5px 14px', fontWeight: 500 }}
                label={' Use this template'}
                onClick={() => {
                  setSelectedTemplate(showPreview);
                  setOpen(true);
                }}
              />

              <div
                className="btn border border-grey-400 rounded-md text-primary gap-2 text-13 cursor-pointer"
                style={{ padding: '5px 14px', fontWeight: 500 }}
                onClick={() => setShowPreview(false)}
              >
                <IconGreen />
                Close preview
              </div>
            </div>
          </div>
        )}

        {modalShow && (
          <div className="modal show" style={{ top: '0px', height: '100%' }}>
            <div
              className="modal__container"
              style={{ background: 'transparent', maxHeight: 'none' }}
            >
              <div
                className="modal__header modal__header--plain"
                style={{
                  justifyContent: 'flex-end',
                  paddingRight: '0px',
                  paddingBottom: '0.5rem',
                  marginTop: '48px'
                }}
              >
                <div
                  className="flex border border-grey-400 cursor-pointer border-radius-30"
                  style={{ background: 'white', borderRadius: '20px' }}
                >
                  <div
                    className="flex items-center py-1 pr-4 pl-3"
                    onClick={() => setShowModalShow(false)}
                  >
                    <img
                      src={closeGreen}
                      alt="arrow-right"
                      className="cursor-pointer"
                    />
                    <p
                      className="text-13 font-medium ml-2"
                      style={{ color: '#CE4961' }}
                    >
                      Close Video
                    </p>
                  </div>
                </div>
              </div>
              <div className="" style={{ padding: '0px' }}>
                <div
                  style={{
                    padding: '0 0 70% 0',
                    position: 'relative',
                    borderRadius: '30px',
                    MozBorderRadius: '15px'
                  }}
                >
                  <iframe
                    src={
                      'https://player.vimeo.com/video/820515204?h=02659bbf2c&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479'
                    }
                    allow="autoplay; fullscreen; picture-in-picture"
                    allowfullscreen
                    style={{
                      position: 'absolute',
                      width: '100%',
                      height: '75%',
                      border: '10px solid #fff',
                      borderRadius: '10px',
                      backgroundColor: '#fff'
                    }}
                    title="coming soon"
                  ></iframe>
                </div>
                {/* <script src="https://player.vimeo.com/api/player.js"></script> */}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

AddboxTemplateHeadder.defaultProps = {
  title: '',
  HeaderIcon: () => <></>
};

export default AddboxTemplateHeadder;
