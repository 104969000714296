/* eslint-disable react/no-unescaped-entities */
const EnquiryBotDescription = () => {
  return (
    <div className="mt-0 product-description">
      <p className="text-15 leading-6 font-semibold">
        Turn your website visitors into customers with EnquiryBot
      </p>
      <p className="text-15 leading-6 mt-5">
        EnquiryBot is an automated chatbot that engages website visitors, asking
        tailored questions to fully qualify each lead. It's custom-built for
        your unique business needs and integrated with your Reckonsys account.
      </p>
      <p className="text-15 leading-6 font-semibold mt-5">
        Some key benefits include:
      </p>
      <ul className="text-15 leading-6 mt-5">
        <li>
          Supercharge your enquiry volume. On average, clients see a 200% boost
          from implementing EnquiryBot.
        </li>
        <li>
          Auto-route enquiries to the right box. EnquiryBot categorises leads
          and passes them to relevant internal teams.
        </li>
        <li>
          Pre-qualify high-value sales. The bot identifies prospects most ready
          to purchase without wasting reps' time.
        </li>
        <li>
          Streamline FAQ handling. Time-consuming enquiries are answered
          instantly by EnquiryBot.
        </li>
        <li>
          Collect crucial upfront details. EnquiryBot gathers all contact and
          enquiry information, saving you time running back and forth.
        </li>
      </ul>
      <p className="text-15 leading-6 mt-5">
        Ready to increase qualified enquiries flowing in? Book a customised
        EnquiryBot demo to see what this intelligent lead generation tool can do
        for your business.
      </p>
    </div>
  );
};

export default EnquiryBotDescription;
