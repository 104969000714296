/* eslint-disable react/no-unescaped-entities */
/* eslint-disable no-unused-vars */
import React from 'react';
import UKFlag from 'assets/images/uk-flag.png';
import { useSelector } from 'react-redux';
import FAQ from '../faq';
import CopySmsNumber from 'modules/enquirybox/channels/components/copySmsNumber';
import SmsPlans from './plans';
import { isEmpty } from 'utils/utils';
import ChannelBack from 'modules/enquirybox/channels/module/settings/channel/back';
import GuideLink from 'components/guideLink';

const SMSContent = (props) => {
  const { smsDetails } = useSelector((state) => state.subscription);

  return (
    <div className="toolbox__main overflow-overlay channels">
      <div
        className="card-container--new connect-new"
        style={{
          width: '820px'
        }}
      >
        <div>
          <ChannelBack />
          <p className="text-16 font-semibold mb-5">Send and receive SMS</p>
          <p className="text-grey-800 text-15 leading-6">
            Whether you're sending appointment reminders, auto replies, or just
            checking in to see how your customers are doing, our 2-way SMS
            feature makes it easy to stay connected. And because it's all done
            via text message, your customers can respond quickly and
            conveniently from their own mobile devices.
          </p>
          {/* <div className="border border-grey-400 border-radius-14 px-6 py-5 mt-4">
        <h3 className="text-lg font-semibold">Your SMS number</h3>
        <p className="text-grey-800 text-15 mt-2">
          You can use this number to receive SMS message from customers or leads
        </p>
        <div className="px-4 py-2 text-15 font-medium border border-grey-500 bg-grey-300 rounded-lg mt-3">
          +4455468953473
        </div>
        <div className="flex justify-between mt-4">
          <PrimaryButton
            className="btn--xs"
            label="Copy number"
            onClick={() => copyToClipboard('+4455468953473')}
          />
          <span className="text-primary text-13 font-medium">
            Read more about receiving SMS
          </span>
        </div>
      </div> */}
          {!isEmpty(smsDetails?.phone_number) && (
            <CopySmsNumber phoneNumber={smsDetails?.phone_number} />
          )}
          <div className="mt-8">
            <div className="flex items-center justify-between">
              {/* <div className="tabs-wrapper" style={{ width: 'initial' }}>
            <div
              className={`tabs-header ${
                activeTab === 0 ? 'tabs-header__active' : ''
              }`}
              onClick={() => handleTabClick(0)}
            >
              GBP £
            </div>
            <div
              className={`tabs-header ${
                activeTab === 1 ? 'tabs-header__active' : ''
              }`}
              onClick={() => handleTabClick(1)}
            >
              Euro €
            </div>
            <div
              className={`tabs-header ${
                activeTab === 2 ? 'tabs-header__active' : ''
              }`}
              onClick={() => handleTabClick(2)}
            >
              USD $
            </div>
          </div> */}
              <div className="flex items-center">
                <p className="text-16 font-semibold">SMS Bundles</p>
              </div>
              <div className="flex items-center py-2 px-2 bg-white border-2 border-grey-500 rounded-lg">
                <img src={UKFlag} alt="UK-flag" />
                <span className="text-sm font-medium ml-3">
                  United Kingdom (+44)
                </span>
              </div>
            </div>
          </div>
          <div className="mt-6 mb-5">
            <SmsPlans />
            <FAQ />
          </div>
          <GuideLink url="https://help.reckonsys.io/en/articles/7228359-setup-and-connect-an-sms-number" />
        </div>
      </div>
    </div>
  );
};

export default SMSContent;
